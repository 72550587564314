
import { useEffect, useState } from 'react';
import { HexColorPicker } from "react-colorful";

import {
    ListItem,
    TextField,
    FormControlLabel,
    Checkbox,
    FormControl,
    InputLabel,
    Select,
    Box,
    Chip,
    MenuItem,
    ListItemText,
    Input,
    ButtonGroup,
    Button,
    ListSubheader,
    Divider
} from '@mui/material'

import {
    FormatPaint as FormatPaintIcon,
    FormatColorText as FormatColorTextIcon,
    LocalOffer as LocalOfferIcon,
    ShowChart as ShowChartIcon
} from '@mui/icons-material'
import { LINE_CONFIG } from 'components/widgets/line/config';
import axios from 'axios';
import { ApiEndpoint } from 'config';

const WaterfallConfiguration = ({ id, title,
    isConfigDrawerOpen, toggleConfigDrawer, widgetAdvanceSettings,
    setWidgetAdvanceSettings,
    showNotification,
    ...props }) => {

        const [dashboardId, setDashboardId] = useState(widgetAdvanceSettings?.kusto?.dashboardId)

        const [isTransparent, setIsTransparent] = useState(true)
        
        const [layoutColor, setLayoutColor] = useState('#005591')
        const [isLayoutColorPickerOpen, toggleLayoutColorPicker] = useState(false);
        
        const [backgroundColor, setBackgroundColor] = useState('#FFFFFF')
        const [isBackgroundColorPickerOpen, toggleBackgroundColorPicker] = useState(false);

        const [selectedTags, setSelectedTags] = useState(widgetAdvanceSettings?.waterfall?.selectedTags ? widgetAdvanceSettings?.waterfall?.selectedTags : [])
        const [tagList, setTagList] = useState([])

        useEffect(() => {
            if (widgetAdvanceSettings?.waterfall) {
                setLayoutColor(widgetAdvanceSettings?.waterfall?.layoutColor)
                setBackgroundColor(widgetAdvanceSettings?.waterfall?.backgroundColor)
                
                if (widgetAdvanceSettings?.waterfall?.isTransparent !== undefined) {
                    setIsTransparent(widgetAdvanceSettings?.waterfall?.isTransparent)
                }
            }
            getAvailableTags()
        }, [])
    
        useEffect(() => {
            setWidgetAdvanceSettings({
                ...widgetAdvanceSettings,
                waterfall: {
                    layoutColor: layoutColor,
                    backgroundColor: backgroundColor,
                    isTransparent: isTransparent,
                    selectedTags: selectedTags,
                    tagList: tagList
                }
            })
        }, [layoutColor, backgroundColor, isTransparent, selectedTags, tagList])

        const getAvailableTags = async () => {
            await axios.get(`${ApiEndpoint.Tag.GetAllTags}`, {
                headers: {
                    'authorization': `bearer ${props.authentication?.token?.accessToken}`
                }
            }).then(resp => {
                let _tagList = resp.data.map(obj => {
                    if (selectedTags.find(x => x === obj.id) !== undefined) {
                        return {...obj, isSelected: true}
                    }
                    else {
                        return {...obj, isSelected: false}
                    }
                })

                setTagList(_tagList)
                console.log('[Waterfall] Tag List > ', _tagList)
            }).catch(err => {
                console.log(err)
                showNotification(err.response?.data?.error ?? err.response?.data?.title, 'danger')
            })
        }

        const onChangeTransparent = () => {
            setIsTransparent(!isTransparent)
        }

        const handleChange = (event) => {
            const { target: { value } } = event;
            setSelectedTags(value);
            
            let _tagList = [...tagList]
            let isSelectedCounter = 0
    
            for (let i=0; i<_tagList.length; i++) {
                if (value.includes(_tagList[i].id)) {
                    _tagList[i].isSelected = true
                    isSelectedCounter++
                }
                else {
                    _tagList[i].isSelected = false
                }
            }

            console.log('New selecrtedTags > ', value)
    
            setTagList(_tagList)
        };
    
        return <div>
            {/* <ListItem id={'config-list-item'} className={'config-list-item'}>
                <ReportIcon />
                <TextField className='config-menu-item-text'
                    fullWidth
                    label="Dashboard ID"
                    variant='standard'
                    defaultValue={dashboardId}
                    onChange={event => setDashboardId(event.target.value)} />
            </ListItem> */}
            
            <br />
            <Divider variant='middle' />

            <ListSubheader style={{ color: '#00A0E1' }} disableSticky={true}>
                Data
            </ListSubheader>

            <ListItem id={'config-list-item'} className={'config-list-item'}>
                <LocalOfferIcon />
                <FormControl sx={{ m: 0 }} style={{ width: '410px' }}>
                    <InputLabel id="demo-multiple-chip-label">Tags</InputLabel>
                    <Select
                        multiple
                        value={selectedTags}
                        onChange={handleChange}
                        input={<Input label="Tag" />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', border: '0px' }}>
                            {selected.map((value) => (
                                <Chip key={value} label={tagList.find(x => x.id === value)?.description} />
                            ))}
                            </Box>
                        )}
                        // MenuProps={MenuProps}
                        className='config-menu-item-text'
                    >
                        {/* {names.map((name) => (
                            <MenuItem key={name} value={name}>
                            <Checkbox checked={personName.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                            </MenuItem>
                        ))} */}
                        {
                            tagList.map((tag) => (
                                <MenuItem key={tag.id} value={tag.id} label={tag.description}>
                                    <Checkbox checked={tag.isSelected} />
                                    <ListItemText primary={tag.description} />
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </ListItem>

            <br />
            <Divider variant='middle' />

            <ListSubheader style={{ color: '#00A0E1' }} disableSticky={true}>
                Layout
            </ListSubheader>

            <ListItem id={'config-list-item'} className={'config-list-item'}>
                <FormatColorTextIcon titleAccess='Layout Color' />
                <TextField id="standard-basic" className='config-menu-item-text' variant="standard"
                    size="small"
                    value={layoutColor}
                    onClick={() => toggleLayoutColorPicker(true)}
                    onChange={event => setLayoutColor(event.target.value)}
                />

                <div style={{
                    width: '25px', height: '25px',
                    backgroundColor: layoutColor
                }}
                    onClick={() => toggleLayoutColorPicker(!isLayoutColorPickerOpen)} />
                <br />
            </ListItem>

            <ListItem id={'config-list-item'} className={'config-list-item'}>
                {isLayoutColorPickerOpen && <HexColorPicker
                    style={{ width: 'inherit !important' }}

                    height={300}
                    color={layoutColor}
                    onChange={color => {
                        setLayoutColor(color)
                    }}
                />
                }
            </ListItem>

            <FormControlLabel sx={{ color: '#005591', fontFamily: 'LindeDax, Arial, sans-serif', marginLeft: 1 }}
                control={
                    <Checkbox checked={isTransparent} onChange={onChangeTransparent} />
                }
                label="Transparent Background"
            />

            {
                (isTransparent) ? null :
                    <>
                        <ListItem id={'config-list-item'} className={'config-list-item'}>
                            <FormatPaintIcon titleAccess='Background Color' />
                            <TextField id="standard-basic" className='config-menu-item-text' variant="standard"
                                size="small"
                                value={backgroundColor}
                                onClick={() => toggleBackgroundColorPicker(true)}
                                onChange={event => setBackgroundColor(event.target.value)}
                            />

                            <div style={{
                                width: '25px', height: '25px',
                                backgroundColor: backgroundColor
                            }}
                                onClick={() => toggleBackgroundColorPicker(!isBackgroundColorPickerOpen)} />
                            <br />
                        </ListItem>

                        <ListItem id={'config-list-item'} className={'config-list-item'}>
                            {isBackgroundColorPickerOpen && <HexColorPicker
                                style={{ width: 'inherit !important' }}

                                height={300}
                                color={backgroundColor}
                                onChange={color => {
                                    setBackgroundColor(color)
                                }}
                            />
                            }
                        </ListItem>
                    </>
            }
            
        </div>
}


export default WaterfallConfiguration