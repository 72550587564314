


import React, { useEffect, useState } from 'react';
import axios from 'axios';




import {
    ListItem,
    ListItemText,
    TextField,
    Autocomplete
} from '@mui/material'

import {
    Assessment as ReportIcon,
    Business as BusinessIcon
} from '@mui/icons-material'

import { ApiEndpoint } from 'config/api_endpoint'


const PowerBiConfiguration = ({ id, title, 
    isConfigDrawerOpen, toggleConfigDrawer, 
    setTitleBackgroundColor, widgetAdvanceSettings,
    setWidgetAdvanceSettings, 
    showNotification,
    ...props }) => {

    const [workspaces, setWorkspaces] = useState([])
    const [reports, setReports] = useState([])


    const [selectedWorkSpaces, setSelectedWorkSpaces] = useState(widgetAdvanceSettings?.powerBi?.workspaceId)
    const [selectedReports, setSelectedReports] = useState(widgetAdvanceSettings?.powerBi?.reportId)


    useEffect(() => {
        getWorkspace()
    }, [])

    useEffect(() => {
        if (workspaces.length > 0) {

            if (selectedWorkSpaces !== widgetAdvanceSettings?.powerBi?.workspaceId) {
                // Need to clear out the existing report first 
                setReports([])    
                setSelectedReports('')        
                getReports(selectedWorkSpaces)    
            }else if (selectedWorkSpaces){
                getReports(selectedWorkSpaces)

            }

        }
    }, [selectedWorkSpaces, workspaces])


    useEffect(() => {
        if (selectedReports) {
            setWidgetAdvanceSettings({
                ...widgetAdvanceSettings,
                powerBi: {
                    workspaceId: selectedWorkSpaces,
                    reportId: selectedReports
                }
            })
        }
    }, [selectedReports])

    const getWorkspace = async () => {
        try {
            
            await axios
                .get(ApiEndpoint.PowerBi.GetWorkspaces,
                    {
                        headers: { 'Authorization': 'Bearer ' + props.authentication.token.accessToken }
                    }
                )
                .then(res => {
                    setWorkspaces(res.data.value)
                })
                .catch(error => {
                    showNotification(`Get Workspace Error : ${error.response.data.error ?? error.response.data ?? error.message}` , 'danger')
                });
        } catch (err) {
            console.error(err)
        }
    }

    const getReports = async (workspaceId) => {
        try {
            await axios
                .get(ApiEndpoint.PowerBi.GetPowerBiEndpoint + '/' + workspaceId + '/reports',
                    {
                        headers: { 'Authorization': 'Bearer ' + props.authentication.token.accessToken }
                    }
                )
                .then(res => {
                    setReports(res.data.value)
                })
                .catch(function (error) {
                    showNotification(`Get Reports Error : ${error.response.data.error ?? error.response.data ?? error.message}` , 'danger')
                
                });
        } catch (err) {
            console.error(err)
        }
    }


    return <div>
        <ListItem id={'config-list-item'} className={'config-list-item'}>


            <BusinessIcon />

            {workspaces.length > 0 ?

                <Autocomplete
                    className='config-menu-item-text'
                    fullWidth
                    value={workspaces.find(x => x.id == selectedWorkSpaces)?.name}
                    onChange={(event, newValue) => setSelectedWorkSpaces(workspaces.find(x => x.name == newValue).id)}
                    options={workspaces.map((option) => option.name)}
                    renderInput={(params) => <TextField {...params} variant="standard" />}
                /> :

                <ListItemText className='config-menu-item-text'>Loading ... </ListItemText>


            }

        </ListItem>


        <ListItem id={'config-list-item'} className={'config-list-item'}>

            <ReportIcon />
            {selectedWorkSpaces && reports.length > 0 ?
                <Autocomplete
                    className='config-menu-item-text'
                    fullWidth
                    value={reports.find(x => x.id == selectedReports)?.name}
                    onChange={(event, newValue) => setSelectedReports(reports.find(x => x.name == newValue).id)}
                    options={reports.map((option) => option.name)}
                    renderInput={(params) => <TextField {...params} variant="standard" />}
                /> :
                <ListItemText className='config-menu-item-text'>Loading ... </ListItemText>
            }



        </ListItem>


        <ListItem>
            {selectedWorkSpaces}
        </ListItem>
        <ListItem>
            {selectedReports}
        </ListItem>
    </div>
}


export default PowerBiConfiguration