import { useEffect, useState } from 'react';
import axios from 'axios'
import Plotly from 'plotly.js-dist-min';
import createPlotlyComponent from 'react-plotly.js/factory';
import { ApiEndpoint } from 'config';
import { KUSTO_CLUSTER, KUSTO_DATABASE, KUSTO_TABLE } from 'config';
import Loader from 'components/loader';

const Plot = createPlotlyComponent(Plotly);

const Pie = ({ id, pageName, widgetRefreshData, showNotification, authentication, width, height, 
    config, chartTitle, isDonut, textInfo, textPosition, categoryText, donutSize, layoutColor, backgroundColor, kustoToken, isTransparent, 
    legendOrientation, tagList, selectedTags, categoryTextSize, titleSize, ...props }) => {
    
    const [revision, setRevision] = useState(0)
    const [isLoading, setIsLoading] = useState(false)

    const [chartData, setChartData] = useState({ values: [], labels: [] })

    useEffect(() => {
        if (selectedTags === undefined || selectedTags === null) {
            selectedTags = 1
        }
    }, []);

    useEffect(() => {
        getData()
    }, [selectedTags])

    useEffect(() => {
        setRevision(revision+1)
    }, [chartData])

    const getData = async () => {
        setIsLoading(true)

        if (selectedTags !== undefined) {
            for (let i=0; i<selectedTags.length; i++) {
                let idx = tagList.findIndex(x => x.id === selectedTags[i])
    
                if (idx !== undefined) {
                    tagList[idx].isSelected = true
                }
            }
        }
        
        let selectedTagList = tagList?.filter(x => x.isSelected === true)

        if (tagList?.filter(x => x.isSelected === true).length > 0) {
            let chartValues = []
            let chartTags = []

            for (let i=0; i<selectedTagList.length; i++) {
                await axios.get(`${ApiEndpoint.Kusto.GetLatestValue}`, {
                    params: {
                        TagName: selectedTagList[i].name,
                        Cluster: KUSTO_CLUSTER,
                        Database: KUSTO_DATABASE,
                        Table: KUSTO_TABLE,
                        Count: 1,
                        Order: 1 // 0 - ASC, 1 - DESC
                    },
                    headers: {
                        'authorization': `bearer ${kustoToken}`
                    }
                }).then(resp => {
                    let data = resp.data

                    chartTags.push(data.TagName)
                    chartValues.push(data.Value)
                    
                }).catch(err => {
                    console.log(err)
                    showNotification(err.response?.data?.error ?? err.response?.data?.title, 'danger')
                })
            }
            
            setChartData({
                values: chartValues,
                labels: chartTags
            })
        }
        else {
            setChartData({
                values: [],
                labels: []
            })
        }
        setIsLoading(false)
    }

    useEffect(() => {
        setRevision(revision+1)
    }, [config])

    useEffect(() => {
        console.debug(`Refreshing page ${id}`)
        if (true) {
            getData()
            showNotification('Refresh success!', 'success')
        }
    }, [widgetRefreshData])


    return <div style={{ backgroundColor: (isTransparent) ? 'transparent' : backgroundColor }}>
        {
            (isLoading) ?
                <Loader />
            :
                <Plot useResizeHandler
                    data={[
                        {
                            values: chartData.values,
                            labels: chartData.labels,
                            type: 'pie',
                            textinfo: textInfo,
                            insidetextorientation: "radial",
                            textposition: textPosition,
                            hole: (isDonut) ? donutSize : '0',
                            automargin: true,
                            hoverinfo: "label+value+percent",
                            direction: "clockwise",
                            rotation: 0 // Between -360 ~ 360
                            // textfont: {
                            //     color: layoutColor
                            // },
                        }
                    ]}
                    layout={{
                        annotations: [
                            {
                                font: {
                                    color: layoutColor,
                                    size: categoryTextSize
                                },
                                showarrow: false,
                                text: (isDonut) ? categoryText : '',
                                // x: 0.5,
                                // y: -0.2
                            },
                        ],
                        autosize: true,
                        title: {
                            text: chartTitle,
                            font: {
                                color: layoutColor,
                                size: titleSize
                            }
                        },
                        legend: {
                            font: {
                                color: layoutColor
                            },
                            orientation: legendOrientation,
                        },
                        margin: {
                            t: 100,
                            l: 80,
                            b: 80,
                            r: 80
                        },
                        showlegend: (legendOrientation === null) ? false : true,
                        width: width,
                        height: (height-10),
                        paper_bgcolor: 'transparent',
                        plot_bgcolor: 'transparent',
                        datarevision: revision,
                        uirevision: revision
                    }}
                />
        }
    </div>
};

export default Pie;


