import Button from '@mui/material/Button';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const ExportExcelFile = (props) => {
    const { data, fileName} = props;
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToExcelFile = (data, fileName) => {
        const ws = XLSX.utils.json_to_sheet(data());
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const dataExcel = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(dataExcel, fileName + fileExtension);
    }

    return (
        <Button variant="contained" style={{ margin: '0.5rem' }} onClick={(e) => exportToExcelFile(data,fileName)}>Donwload template file</Button>
    )
}

export default ExportExcelFile;