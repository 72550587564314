import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from '@mui/material';

const ResetWidgetDialog = (
    {
        id,
        resetSettings,
        open,
        setOpen,
        ...props }) => {

    const handleClose = () => {
        setOpen();
    };

    const confirmResetWidget = () => {
        resetSettings()
        setOpen();
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                {`Reset the widget ?`}
            </DialogTitle>

            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure that you which to reset the widget ? <br />
                    Caution: All the changes that you made will be discarded.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={confirmResetWidget} autoFocus>
                    Reset
                </Button>
            </DialogActions>
        </Dialog>
    )
}


export default ResetWidgetDialog;