
import { useEffect, useState } from 'react';
import { HexColorPicker } from "react-colorful";
import axios from 'axios';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";


import {
    Slider, Switch,
    Divider, Checkbox,
    TextField, IconButton,
    Autocomplete, FormControlLabel,
    ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, TableContainer, Table, ListItemButton,
} from '@mui/material'

import {
    InvertColors as InvertColorsIcon,
    FormatPaint as FormatPaintIcon,
    HdrStrong as HdrStrongIcon,
    Opacity as OpacityIcon,
    PanoramaPhotosphere,
    Title as TitleIcon,
    Settings as SettingsIcon,
    Palette as PaletteIcon,
    DataArray as DataArrayIcon,
} from '@mui/icons-material'

import { ApiEndpoint } from 'config';
import TagSelectionDialog from './tagSelectionDialog';


const SurfaceChartConfiguration = ({
    id, title,
    isConfigDrawerOpen, toggleConfigDrawer,
    widgetAdvanceSettings, setWidgetAdvanceSettings,
    showNotification, ...props }) => {

    // Color Scale
    const [colorScale, setColorScale] = useState(widgetAdvanceSettings?.surfaceChart?.colorScale ?? 'Jet')
    const [colorScaleRange, setColorScaleRange] = useState(widgetAdvanceSettings?.surfaceChart?.colorScaleRange ?? [0, 4.5])
    const [chartOpacity, setChartOpacity] = useState(widgetAdvanceSettings?.surfaceChart?.chartOpacity ?? 1)

    // Tags 
    const [isTagSelectionOpen, setTagSelectionOpen] = useState(false)
    const [selectedTags, setSelectedTags] = useState(widgetAdvanceSettings?.surfaceChart?.selectedTags ?? [])

    // Contours
    const [contoursEnabled, toggleContours] = useState(widgetAdvanceSettings?.surfaceChart?.contoursEnabled ?? true)   // PARENT CONTOURS
    const [showContoursAlways, toggleShowContoursAlways] = useState(widgetAdvanceSettings?.surfaceChart?.showContoursAlways ?? true)
    const [showColorMapOnContours, toggleShowColorMapOnContours] = useState(widgetAdvanceSettings?.surfaceChart?.showColorMapOnContours ?? true)
    const [projectContours, toggleProjectContours] = useState(widgetAdvanceSettings?.surfaceChart?.projectContours ?? true)
    const [contourRingColor, setContourRingColor] = useState(widgetAdvanceSettings?.surfaceChart?.contourRingColor ?? '#42f462')
    const [iscontourRingColorPickerOpen, togglecontourRingColorPicker] = useState(false);

    // xAxis
    const [xAxisTitle, setXAxisTitle] = useState(widgetAdvanceSettings?.surfaceChart?.xAxisTitle ?? 'X Axis')
    const [xAxisRange, setXAxisRange] = useState(widgetAdvanceSettings?.surfaceChart?.xAxisRange ?? [0, 20])
    const [xAxisNTicks, setXAxisNTicks] = useState(widgetAdvanceSettings?.surfaceChart?.xAxisNTicks ?? 10)
    const [xAxisColor, setXAxisColor] = useState(widgetAdvanceSettings?.surfaceChart?.xAxisColor ?? '#005591')
    const [isxAxisColorPickerOpen, togglexAxisColorPicker] = useState(false);

    // yAxis
    const [yAxisTitle, setYAxisTitle] = useState(widgetAdvanceSettings?.surfaceChart?.yAxisTitle ?? ' Y Axis')
    const [yAxisColor, setYAxisColor] = useState(widgetAdvanceSettings?.surfaceChart?.yAxisColor ?? '#005591')
    const [isyAxisColorPickerOpen, toggleyAxisColorPicker] = useState(false);
    const [yAxisNTicks, setYAxisNTicks] = useState(widgetAdvanceSettings?.surfaceChart?.yAxisNTicks ?? 10)

    // zAxis
    const [zAxisTitle, setZAxisTitle] = useState(widgetAdvanceSettings?.surfaceChart?.zAxisTitle ?? ' Z Axis')
    const [zAxisNTicks, setZAxisNTicks] = useState(widgetAdvanceSettings?.surfaceChart?.zAxisNTiks ?? 10)
    const [zAxisColor, setZAxisColor] = useState(widgetAdvanceSettings?.surfaceChart?.zAxisColor ?? '#005591')
    const [iszAxisColorPickerOpen, togglezAxisColorPicker] = useState(false);

    const [isTransparent, setIsTransparent] = useState(widgetAdvanceSettings?.surfaceChart?.isTransparent ?? true)
    const [backgroundColor, setBackgroundColor] = useState(widgetAdvanceSettings?.surfaceChart?.backgroundColor ?? '#FFFFFF')
    const [isBackgroundColorPickerOpen, toggleBackgroundColorPicker] = useState(false);

    useEffect(() => {
        if (widgetAdvanceSettings?.surfaceChart) {
            setColorScale(widgetAdvanceSettings?.surfaceChart?.colorScale)
            setColorScaleRange(widgetAdvanceSettings?.surfaceChart?.colorScaleRange)
            setChartOpacity(widgetAdvanceSettings?.surfaceChart?.chartOpacity)

            toggleContours(widgetAdvanceSettings?.surfaceChart?.contoursEnabled)
            toggleShowContoursAlways(widgetAdvanceSettings?.surfaceChart?.showContoursAlways)
            toggleShowColorMapOnContours(widgetAdvanceSettings?.surfaceChart?.showColorMapOnContours)

            toggleProjectContours(widgetAdvanceSettings?.surfaceChart?.projectContours)
            setContourRingColor(widgetAdvanceSettings?.surfaceChart?.contourRingColor)

            setXAxisTitle(widgetAdvanceSettings?.surfaceChart?.xAxisTitle)
            setXAxisRange(widgetAdvanceSettings?.surfaceChart?.xAxisRange)
            setXAxisNTicks(widgetAdvanceSettings?.surfaceChart?.xAxisNTicks)
            setXAxisColor(widgetAdvanceSettings?.surfaceChart?.xAxisColor)


            setYAxisTitle(widgetAdvanceSettings?.surfaceChart?.yAxisTitle)
            setYAxisColor(widgetAdvanceSettings?.surfaceChart?.yAxisColor)
			setYAxisNTicks(widgetAdvanceSettings?.surfaceChart?.yAxisNTicks)


            setZAxisTitle(widgetAdvanceSettings?.surfaceChart?.zAxisTitle)
            setZAxisNTicks(widgetAdvanceSettings?.surfaceChart?.zAxisNTicks)
            setZAxisColor(widgetAdvanceSettings?.surfaceChart?.zAxisColor)


            if (widgetAdvanceSettings?.surfaceChart?.isTransparent !== undefined) {
                setIsTransparent(widgetAdvanceSettings?.surfaceChart?.isTransparent)
            }
        }
    }, [])

    useEffect(() => {
        setWidgetAdvanceSettings({
            ...widgetAdvanceSettings,
            surfaceChart: {
                selectedTags: selectedTags,
                colorScale: colorScale,
                colorScaleRange: colorScaleRange,
                chartOpacity: chartOpacity,

                contoursEnabled: contoursEnabled,
                showContoursAlways: showContoursAlways,
                showColorMapOnContours: showColorMapOnContours,
                projectContours: projectContours,
                contourRingColor: contourRingColor,

                xAxisTitle: xAxisTitle,
                xAxisColor: xAxisColor,
                xAxisRange: xAxisRange,
                xAxisNTicks: xAxisNTicks,

                yAxisTitle: yAxisTitle,
                yAxisColor: yAxisColor,
                yAxisNTicks: yAxisNTicks,

                zAxisTitle: zAxisTitle,
                zAxisNTicks: zAxisNTicks,
                zAxisColor: zAxisColor,

                isTransparent: isTransparent,
                backgroundColor: backgroundColor
            }
        })
    }, [
        selectedTags,
        colorScale, colorScaleRange,
        chartOpacity,
        contoursEnabled, showContoursAlways, showColorMapOnContours, projectContours, contourRingColor,
        xAxisTitle, xAxisColor, xAxisRange, xAxisNTicks,
        yAxisTitle, yAxisColor, yAxisNTicks,
        zAxisTitle, zAxisNTicks, zAxisColor,
        isTransparent, backgroundColor])



    useEffect(() => {

        // console.log(selectedTags)

    }, [selectedTags])




    const handleSliderRangeChange = (func, newValue) => {
        func(newValue)
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            selectedTags,
            result.source.index,
            result.destination.index
        );

        setSelectedTags(items)
    }
    const grid = 8;
    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: 4,
        margin: `0 0 3px 0`,
        height: '30px',

        // change background colour if dragging
        background: isDragging ? "lightgreen" : "grey",

        // styles we need to apply on draggables
        ...draggableStyle
    });

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "lightblue" : "lightgrey",
        padding: grid,
        width: '100%'
    });




    return <>
        <TagSelectionDialog
            open={isTagSelectionOpen}
            toggleOpen={setTagSelectionOpen}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
            {...props} />

        <div className={'config-list-item'} >

            {/* Colorscales */}
            <ListItem id='config-list-item'>

                <ListItemIcon id='config-list-item'>
                    <InvertColorsIcon titleAccess='Color Scale' />
                </ListItemIcon>


                <ListItemText>
                    <Autocomplete className='config-menu-item-text'
                        fullWidth
                        onChange={(event, newValue) => setColorScale(newValue)}
                        options={[
                            'YlOrRd',
                            'YlGnBu',
                            'RdBu',
                            'Portland',
                            'Picnic',
                            'Jet',
                            'Hot',
                            'Greys',
                            'Greens',
                            'Electric',
                            'Earth',
                            'Bluered',
                            'Blackbody']}
                        defaultValue={colorScale}
                        renderInput={(params) => <TextField {...params} variant="standard" />} />
                </ListItemText>

            </ListItem>

            {/* Color Scale Range  */}
            <ListItem id='config-list-item'>
                <ListItemIcon id={'config-list-item'}>
                    <HdrStrongIcon titleAccess='Color Scale Min Max Range' />
                </ListItemIcon>

                <ListItemText>
                    <Slider size='small'
                        className='config-menu-item-text'
                        value={colorScaleRange}
                        onChange={(event, newVal) => handleSliderRangeChange(setColorScaleRange, newVal)}
                        valueLabelDisplay="auto" />
                </ListItemText>
            </ListItem>

            {/* Chart Opacity  */}
            <ListItem id='config-list-item'>

                <ListItemIcon id='config-list-item'>
                    <OpacityIcon titleAccess='Chart Opacity' />
                </ListItemIcon>

                <ListItemText>
                    <Slider size='small'
                        className='config-menu-item-text'
                        defaultValue={chartOpacity}
                        onChange={(event, newVal) => handleSliderRangeChange(setChartOpacity, newVal)}
                        step={0.05}
                        marks
                        min={0}
                        max={1} />
                </ListItemText>
            </ListItem>
            <br />


            <Divider variant="middle" />

            {/* Tag Sections */}
            <ListItemText style={{ color: '#00A0E1', paddingTop: '0.5rem' }} className='config-menu-item-text'>
                <IconButton id={'drawer-button'}>
                    <DataArrayIcon />
                </IconButton>
                Data Management
            </ListItemText>

            {/* Tag Selection Sections  */}
            <ListItem>
                <ListItemButton onClick={() => setTagSelectionOpen(true)}>
                    Add Tags
                </ListItemButton>
            </ListItem>
            <ListItem>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}>

                                {selectedTags.map((item, index) => (
                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}>
                                                {item.name}
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </ListItem>



            <Divider variant="middle" />

            {/* Countour Sections */}
            <ListItemText style={{ color: '#00A0E1', paddingTop: '0.5rem' }} className='config-menu-item-text'>
                <IconButton id={'drawer-button'}>
                    <PanoramaPhotosphere />
                </IconButton>
                Contour Section
            </ListItemText>


            {/* Enable Contours & Countour Sections */}
            <ListItem id='config-list-item'>

                {/* Enable Contours */}
                <ListItemIcon>
                    <SettingsIcon />
                </ListItemIcon>
                <ListItemText>
                    Enable Contours
                </ListItemText>
                <ListItemSecondaryAction>
                    <Switch size='small' className='config-menu-item-text' onChange={(event) => { toggleContours(event.target.checked) }} defaultChecked={contoursEnabled} />
                </ListItemSecondaryAction>
            </ListItem>

            {
                contoursEnabled &&
                <>

                    {/* Always show contours  */}
                    <ListItem id='config-list-item'>
                        <ListItemIcon>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText>
                            Always Show Contours
                        </ListItemText>
                        <ListItemSecondaryAction>
                            <Switch size='small' className='config-menu-item-text'
                                onChange={(event) => { toggleShowContoursAlways(event.target.checked) }}
                                defaultChecked={showContoursAlways} />
                        </ListItemSecondaryAction>
                    </ListItem>


                    {/* Always show colormap on contours */}
                    <ListItem id='config-list-item'>
                        <ListItemIcon>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText>
                            Always Show Colormap on Contours
                        </ListItemText>
                        <ListItemSecondaryAction>
                            <Switch size='small' className='config-menu-item-text'
                                onChange={(event) => { toggleShowColorMapOnContours(event.target.checked) }}
                                defaultChecked={showColorMapOnContours} />
                        </ListItemSecondaryAction>
                    </ListItem>

                    {/* Project the contours  */}
                    <ListItem id='config-list-item'>
                        <ListItemIcon>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText>
                            Project Contours
                        </ListItemText>
                        <ListItemSecondaryAction>
                            <Switch size='small' className='config-menu-item-text'
                                onChange={(event) => { toggleProjectContours(event.target.checked) }}
                                defaultChecked={projectContours} />
                        </ListItemSecondaryAction>
                    </ListItem>

                    {/* Contour Ring Color */}
                    <ListItem id='config-list-item'>
                        <ListItemIcon onClick={() => togglecontourRingColorPicker(!iscontourRingColorPickerOpen)}>
                            <PaletteIcon sx={{ color: contourRingColor }} />
                        </ListItemIcon>
                        <ListItemText>
                            Contour Ring Color
                        </ListItemText>
                        <ListItemSecondaryAction>
                            {contourRingColor}
                        </ListItemSecondaryAction>
                        <br />
                    </ListItem>
                    <ListItem id={'config-list-item'} className={'config-list-item'}>
                        <ListItemText>
                            {iscontourRingColorPickerOpen && <HexColorPicker
                                style={{ width: 'inherit !important' }}
                                height={300}
                                color={contourRingColor}
                                onChange={color => {
                                    setContourRingColor(color)
                                }} />
                            }
                        </ListItemText>
                    </ListItem>
                </>
            }
            <Divider variant="middle" />


            {/* X Axis */}
            {/* X Axis Config */}
            <ListItemText style={{ color: '#00A0E1', paddingTop: '0.5rem' }} className='config-menu-item-text'>
                <IconButton id={'drawer-button'}>
                    <SettingsIcon />
                </IconButton>
                X Axis Configuration
            </ListItemText>

            {/* X Axis Title */}
            <ListItem id={'config-list-item'}>
                <ListItemIcon>
                    <TitleIcon />
                </ListItemIcon>
                <ListItemText>
                    <TextField id="standard-basic"
                        className='config-menu-item-text'
                        variant="standard"
                        size="small"
                        defaultValue={xAxisTitle}
                        fullWidth={true}
                        onChange={(val) => setXAxisTitle(val.target.value)} />
                </ListItemText>
            </ListItem>

            {/* X Axis Range  */}
            <ListItem id='config-list-item'>
                <ListItemIcon>
                    <HdrStrongIcon titleAccess='X Axis Min Max Range' />
                </ListItemIcon>
                <ListItemText>
                    <Slider
                        size='small'
                        className='config-menu-item-text'
                        value={xAxisRange}
                        onChange={(event, newVal) => handleSliderRangeChange(setXAxisRange, newVal)}
                        valueLabelDisplay="auto" />
                </ListItemText>
            </ListItem>

            {/* Set the color for X Axis */}
            <ListItem id='config-list-item'>
                <ListItemIcon onClick={() => togglexAxisColorPicker(!isxAxisColorPickerOpen)}>
                    <PaletteIcon sx={{ color: xAxisColor }} />
                </ListItemIcon>
                <ListItemText>
                    Color
                </ListItemText>
                <ListItemText>
                    <ListItemSecondaryAction>
                        {xAxisColor}
                    </ListItemSecondaryAction>
                </ListItemText>
            </ListItem>
            <ListItem id={'config-list-item'} className={'config-list-item'}>
                {isxAxisColorPickerOpen && <HexColorPicker
                    style={{ width: 'inherit !important' }}
                    height={300}
                    color={xAxisColor}
                    onChange={color => {
                        setXAxisColor(color)
                    }} />
                }
            </ListItem>

            {/* X Axis N Ticks  */}
            <ListItem id='config-list-item'>
                <ListItemIcon>
                    <SettingsIcon />
                </ListItemIcon>
                <ListItemText>
                    <Slider
                        size='small'
                        className='config-menu-item-text'
                        defaultValue={xAxisNTicks}
                        onChange={(event, newVal) => handleSliderRangeChange(setXAxisNTicks, newVal)}
                        marks
                        valueLabelDisplay="auto"
                        min={0}
                        max={20} />
                </ListItemText>
            </ListItem>

            <Divider variant="middle" />



            {/* Y Axis */}
            {/* Y Axis Config */}
            <ListItemText style={{ color: '#00A0E1', paddingTop: '0.5rem' }} className='config-menu-item-text'>
                <IconButton id={'drawer-button'}>
                    <SettingsIcon />
                </IconButton>
                Y Axis Configuration
            </ListItemText>

            {/* Y Axis Title */}
            <ListItem id={'config-list-item'}>
                <ListItemIcon>
                    <TitleIcon />
                </ListItemIcon>
                <ListItemText>
                    <TextField id="standard-basic"
                        className='config-menu-item-text'
                        variant="standard"
                        size="small"
                        defaultValue={yAxisTitle}
                        fullWidth={true}
                        onChange={(val) => setYAxisTitle(val.target.value)} />
                </ListItemText>
            </ListItem>

            {/* Set the color for Y Axis */}
            <ListItem id='config-list-item'>
                <ListItemIcon onClick={() => toggleyAxisColorPicker(!isyAxisColorPickerOpen)}>
                    <PaletteIcon sx={{ color: yAxisColor }} />
                </ListItemIcon>
                <ListItemText>
                    Color
                </ListItemText>
                <ListItemText>
                    <ListItemSecondaryAction>
                        {yAxisColor}
                    </ListItemSecondaryAction>
                </ListItemText>
            </ListItem>
            <ListItem id={'config-list-item'} className={'config-list-item'}>
                {isyAxisColorPickerOpen && <HexColorPicker
                    style={{ width: 'inherit !important' }}
                    height={300}
                    color={yAxisColor}
                    onChange={color => {
                        setYAxisColor(color)
                    }} />
                }
            </ListItem>

            {/* Y Axis N Ticks  */}
            <ListItem id='config-list-item'>
                <ListItemIcon>
                    <SettingsIcon />
                </ListItemIcon>
                <ListItemText>
                    <TextField id="standard-basic"
                        type='number'
                        className='config-menu-item-text'
                        variant="standard"
                        size="small"
                        defaultValue={yAxisNTicks}
                        fullWidth={true}
                        onBlur={(val) => setYAxisNTicks(val.target.value)} />
                </ListItemText>
            </ListItem>

            <Divider variant="middle" />




            {/* Z Axis */}
            {/* Z Axis Config */}
            <ListItemText style={{ color: '#00A0E1', paddingTop: '0.5rem' }} className='config-menu-item-text'>
                <IconButton id={'drawer-button'}>
                    <SettingsIcon />
                </IconButton>
                Z Axis Configuration
            </ListItemText>

            {/* Z Axis Title */}
            <ListItem id={'config-list-item'}>
                <ListItemIcon>
                    <TitleIcon />
                </ListItemIcon>
                <ListItemText>
                    <TextField id="standard-basic"
                        className='config-menu-item-text'
                        variant="standard"
                        size="small"
                        defaultValue={zAxisTitle}
                        fullWidth={true}
                        onChange={(val) => setZAxisTitle(val.target.value)} />
                </ListItemText>
            </ListItem>

            {/* Set the color for Z Axis */}
            <ListItem id='config-list-item'>
                <ListItemIcon onClick={() => togglezAxisColorPicker(!iszAxisColorPickerOpen)}>
                    <PaletteIcon sx={{ color: zAxisColor }} />
                </ListItemIcon>
                <ListItemText>
                    Color
                </ListItemText>
                <ListItemText>
                    <ListItemSecondaryAction>
                        {zAxisColor}
                    </ListItemSecondaryAction>
                </ListItemText>
            </ListItem>
            <ListItem id={'config-list-item'} className={'config-list-item'}>
                {iszAxisColorPickerOpen && <HexColorPicker
                    style={{ width: 'inherit !important' }}
                    height={300}
                    color={zAxisColor}
                    onChange={color => {
                        setZAxisColor(color)
                    }} />
                }
            </ListItem>

            {/* Z Axis N Ticks  */}
            <ListItem id='config-list-item'>
                <ListItemIcon>
                    <SettingsIcon />
                </ListItemIcon>
                <ListItemText>
                    <Slider
                        size='small'
                        className='config-menu-item-text'
                        defaultValue={zAxisNTicks}
                        onChange={(event, newVal) => handleSliderRangeChange(setZAxisNTicks, newVal)} marks
                        valueLabelDisplay="auto"
                        min={0} max={20} />
                </ListItemText>
            </ListItem>


            {/* Background Region */}
            <FormControlLabel sx={{ color: '#005591', fontFamily: 'LindeDax, Arial, sans-serif', marginLeft: 1 }}
                control={
                    <Checkbox checked={isTransparent} onChange={() => setIsTransparent(!isTransparent)} />
                }
                label="Transparent Background"
            />
            {(isTransparent) ? null :
                <>
                    <ListItem id={'config-list-item'} className={'config-list-item'}>
                        <FormatPaintIcon titleAccess='Background Color' />
                        <TextField id="standard-basic" className='config-menu-item-text' variant="standard"
                            size="small"
                            value={backgroundColor}
                            onClick={() => toggleBackgroundColorPicker(true)}
                            onChange={event => setBackgroundColor(event.target.value)}
                        />

                        <div style={{
                            width: '25px', height: '25px',
                            backgroundColor: backgroundColor
                        }}
                            onClick={() => toggleBackgroundColorPicker(!isBackgroundColorPickerOpen)} />
                        <br />
                    </ListItem>

                    <ListItem id={'config-list-item'} className={'config-list-item'}>
                        {isBackgroundColorPickerOpen && <HexColorPicker
                            style={{ width: 'inherit !important' }}

                            height={300}
                            color={backgroundColor}
                            onChange={color => {
                                setBackgroundColor(color)
                            }}
                        />
                        }
                    </ListItem>
                </>
            }

        </div>

    </>
}


export default SurfaceChartConfiguration