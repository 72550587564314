
import { useEffect, useState } from 'react';
import { HexColorPicker } from "react-colorful";

import {
    ListItem,
    TextField,
    FormControlLabel,
    Checkbox,
    Autocomplete,
    Divider,
    ListSubheader,
    FormControl,
    InputLabel,
    Select,
    Input,
    Box,
    Chip,
    MenuItem,
    ListItemText,
    Button,
    ButtonGroup
} from '@mui/material'

import {
    FormatColorText as FormatColorTextIcon,
    Title as TitleIcon,
    FormatPaint as FormatPaintIcon,
    DonutLarge as DonutLargeIcon,
    Percent as PercentIcon,
    FormatAlignCenter as FormatAlignCenterIcon,
    List as ListIcon,
    LocalOffer as LocalOfferIcon,
} from '@mui/icons-material'
import { ApiEndpoint } from 'config';
import axios from 'axios';
import { PIE_CONFIG } from 'components/widgets/pie/config';

const AreaConfiguration = ({ id, title, isConfigDrawerOpen, toggleConfigDrawer, widgetAdvanceSettings,
    setWidgetAdvanceSettings,
    showNotification,
    ...props }) => {

    const [dashboardId, setDashboardId] = useState(widgetAdvanceSettings?.pie?.dashboardId)

    const [isTransparent, setIsTransparent] = useState(true)
    
    const [layoutColor, setLayoutColor] = useState('#005591')
    const [isLayoutColorPickerOpen, toggleLayoutColorPicker] = useState(false);
    
    const [backgroundColor, setBackgroundColor] = useState('#FFFFFF')
    const [isBackgroundColorPickerOpen, toggleBackgroundColorPicker] = useState(false);

    const [selectedTags, setSelectedTags] = useState([])
    const [tagList, setTagList] = useState([])


    useEffect(() => {
        if (widgetAdvanceSettings?.area) {
            setLayoutColor(widgetAdvanceSettings?.area?.layoutColor)
            setBackgroundColor(widgetAdvanceSettings?.area?.backgroundColor)
            setSelectedTags(widgetAdvanceSettings?.area?.selectedTags)

            if (widgetAdvanceSettings?.area?.isTransparent !== undefined) {
                setIsTransparent(widgetAdvanceSettings?.area?.isTransparent)
            }
        }

        getAvailableTags()
    }, [])

    useEffect(() => {
        setWidgetAdvanceSettings({
            ...widgetAdvanceSettings,
            area: {
                layoutColor,
                backgroundColor,
                isTransparent,
                selectedTags
            }
        })
    }, [layoutColor, backgroundColor, isTransparent, selectedTags])

    const getAvailableTags = () => {
        axios.get(`${ApiEndpoint.Tag.GetAllTags}`, {
            headers: {
                'authorization': `bearer ${props.authentication?.token?.accessToken}`
            }
        }).then(resp => {
            let _tagList = resp.data
            for (let i = 0; i < _tagList.length; i++) {
                _tagList[i] = {
                    ..._tagList[i],
                    isSelected: (selectedTags.filter(x => x === _tagList[i].id).length > 0) ? true : false,
                    value: []
                }
            }
            setTagList(_tagList)
        }).catch(err => {
            console.log(err)
            showNotification(err.response?.data?.error ?? err.response?.data?.title, 'danger')
        })
    }

    const handleChange = (event) => {
        const { target: { value } } = event;
        setSelectedTags(value);
        
        let _tagList = [...tagList]
        let isSelectedCounter = 0

        for (let i=0; i<_tagList.length; i++) {
            if (value.includes(_tagList[i].id)) {
                _tagList[i].isSelected = true
                isSelectedCounter++
            }
            else {
                _tagList[i].isSelected = false
            }
        }

        setTagList(_tagList)
    };

    const onChangeTransparent = () => {
        setIsTransparent(!isTransparent)
    }

    return <div>        
        <br />
        <Divider variant='middle' />

        <ListSubheader style={{ color: '#00A0E1' }} disableSticky={true}>
            Data
        </ListSubheader>

        <ListItem id={'config-list-item'} className={'config-list-item'}>
            <LocalOfferIcon />
            <FormControl sx={{ m: 0 }} style={{ width: '410px' }}>
                <InputLabel id="demo-multiple-chip-label">Tags</InputLabel>
                <Select
                    multiple
                    value={selectedTags}
                    onChange={handleChange}
                    input={<Input label="Tag" />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', border: '0px' }}>
                        {selected.map((value) => (
                            <Chip key={value} label={tagList?.find(x => x.id === value)?.description} />
                        ))}
                        </Box>
                    )}
                    // MenuProps={MenuProps}
                    className='config-menu-item-text'
                >
                    {/* {names.map((name) => (
                        <MenuItem key={name} value={name}>
                        <Checkbox checked={personName.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                        </MenuItem>
                    ))} */}
                    {
                        tagList?.map((tag) => (
                            <MenuItem key={tag.id} value={tag.id} label={tag.description}>
                                <Checkbox checked={tag.isSelected} />
                                <ListItemText primary={tag.description} />
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        </ListItem>

        

        <br />
        <Divider variant='middle' />

        <ListSubheader style={{ color: '#00A0E1' }} disableSticky={true}>
            Layout
        </ListSubheader>


        <FormControlLabel sx={{ color: '#005591', fontFamily: 'LindeDax, Arial, sans-serif', marginLeft: 1 }}
            control={
                <Checkbox checked={isTransparent} onChange={onChangeTransparent} />
            }
            label="Transparent Background"
        />

        {
            (isTransparent) ? null :
                <>
                    <ListItem id={'config-list-item'} className={'config-list-item'}>
                        <FormatPaintIcon titleAccess='Background Color' />
                        <TextField id="standard-basic" className='config-menu-item-text' variant="standard"
                            size="small"
                            value={backgroundColor}
                            onClick={() => toggleBackgroundColorPicker(true)}
                            onChange={event => setBackgroundColor(event.target.value)}
                        />

                        <div style={{
                            width: '25px', height: '25px',
                            backgroundColor: backgroundColor
                        }}
                            onClick={() => toggleBackgroundColorPicker(!isBackgroundColorPickerOpen)} />
                        <br />
                    </ListItem>

                    <ListItem id={'config-list-item'} className={'config-list-item'}>
                        {isBackgroundColorPickerOpen && <HexColorPicker
                            style={{ width: 'inherit !important' }}
                            height={300}
                            color={backgroundColor}
                            onChange={color => {
                                setBackgroundColor(color)
                            }}
                        />
                        }
                    </ListItem>
                </>
        }
        
    </div>
}


export default AreaConfiguration