import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route, Routes } from 'react-router-dom'



import LayoutWrapper from 'pages/_layout/LayoutWrapper'
import HomePage from 'pages/HomePage'
import Dashboard from 'pages/dashboard'
import Report from 'pages/reports'
import Login from 'pages/Login';

import LegalNotice from 'pages/Policy/LegalNotice';
import TermsCondition from 'pages/Policy/TermsCondition';
import PrivacyPolicy from 'pages/Policy/PrivacyPolicy';
import Manual from 'pages/Manual';
import TagManagement from 'pages/TagManagement';

import Notification from 'components/notification'
import { updateWorkspaces, updateReports, updatePages, updateNavigation, toggleDateTimePicker, updateDateTime } from 'reducers/global/actions'
import { showNotification, closeNotification } from 'reducers/notification/actions'
import { setBreakPoint } from 'reducers/customization/actions'



const ApplicationRoute = props => {
    return (
        <>
            <LayoutWrapper {...props}>

                <Notification {...props} />
                <Routes>
                    <Route path='/login' element={<Login {...props} />} />
                    <Route path='/' element={<HomePage {...props} />} />
                    <Route path="/custom/:pageId" element={<Dashboard {...props} />} />
                    <Route path="/report/:workspaceId/:reportId" element={<Report {...props} />} />
                    <Route path="/report/:workspaceId/:reportId/:pageId" element={<Report {...props} />} />


                    <Route path="/policy/Legal-Notice" element={<LegalNotice />} />
                    <Route path="/policy/Terms-Conditions" element={<TermsCondition />} />
                    <Route path="/policy/Privacy-Policy" element={<PrivacyPolicy />} />
                    <Route path="/manual" element={<Manual />} />
                    <Route path='/tagManagement' element={<TagManagement />} />
                </Routes>
            </LayoutWrapper>
        </>
    )
}


let mapStateToProps = (state) => state;

let mapDispatchToProps = dispatch => {
    return bindActionCreators({
        toggleDateTimePicker,
        updateDateTime,
        updateNavigation,
        updateWorkspaces,
        updateReports,
        updatePages,
        showNotification,
        closeNotification,
        setBreakPoint
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationRoute);
