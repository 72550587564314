
import { useEffect, useState } from 'react';
import { HexColorPicker } from "react-colorful";


import {
    ListItem,
    ListItemText,
    TextField,
    Autocomplete,
    Checkbox,
    FormControlLabel,
    Button,
    Input
} from '@mui/material'

import {
    FormatColorText as FormatColorTextIcon,
    CloudUpload as CloudUploadIcon
} from '@mui/icons-material'

const ImageConfiguration = ({ id, title,
    isConfigDrawerOpen, toggleConfigDrawer, widgetAdvanceSettings,
    setWidgetAdvanceSettings,
    showNotification,
    ...props }) => {

        const [backgroundColor, setBackgroundColor] = useState('#FFFFFF')
        const [isColorPickerOpen, toggleColorPicker] = useState(false);

        const [fileName, setFileName] = useState(null)
        const [isTransparent, setIsTransparent] = useState(true)

        const [isImage, setIsImage] = useState(true)
        const [errorFileName, setErrorFileName] = useState('')

        const [data, setData] = useState(null)

        useEffect(() => {
            if (widgetAdvanceSettings?.image?.fileName) {
                setFileName(widgetAdvanceSettings?.image?.fileName)
            }
            if (widgetAdvanceSettings?.image?.backgroundColor) {
                setBackgroundColor(widgetAdvanceSettings?.backgroundColor)
            }
            if (widgetAdvanceSettings?.image?.isTransparent !== undefined) {
                setIsTransparent(widgetAdvanceSettings?.image?.isTransparent)
            }
            else {
                setIsTransparent(true)
            }
            if (widgetAdvanceSettings?.image?.data) {
                setData(widgetAdvanceSettings?.image?.data)
            }
        }, [])

        const onChangeTransparent = () => {
            setWidgetAdvanceSettings({
                ...widgetAdvanceSettings,
                image: {
                    fileName: fileName,
                    backgroundColor: backgroundColor,
                    isTransparent: !isTransparent,
                    data: data
                },
            })
            setIsTransparent(!isTransparent)
        }

        const updateColor = (color) => {
            setWidgetAdvanceSettings({
                ...widgetAdvanceSettings,
                image: {
                    fileName: fileName,
                    backgroundColor: color,
                    isTransparent: isTransparent,
                    data: data
                },
            })
            setBackgroundColor(color)
        }

        const onChangeBackgroundColor = (event) => {
            setWidgetAdvanceSettings({
                ...widgetAdvanceSettings,
                image: {
                    fileName: fileName,
                    backgroundColor: event.target.value,
                    isTransparent: isTransparent,
                    data: data
                },
            })
            setBackgroundColor(event.target.value)
        }

        const onFileChange = (event) => {
            if (event?.target?.files[0]?.type.includes('image')) {
                setIsImage(true)
                if (event?.target?.files[0]) {
                    convertBase64(event.target.files[0])
                }
                else {
                    console.log('NO FILE !!')
                    setFileName(null)
                }
            }
            else {
                setIsImage(false)
                setErrorFileName(event?.target?.files[0]?.name)
                showNotification('This is not an image', 'danger')
            }
        }

        const convertBase64 = file => {    
            let result = new Promise(resolve => {  
                let baseURL = "";
    
                // Make new FileReader
                let reader = new FileReader();
            
                // Convert the file to base64 text
                reader.readAsDataURL(file);
            
                // on reader load somthing...
                reader.onload = () => {
                    // Make a fileInfo Object
                    baseURL = reader.result;

                    setWidgetAdvanceSettings({
                        ...widgetAdvanceSettings,
                        image: {
                            fileName: fileName,
                            backgroundColor: backgroundColor,
                            isTransparent: isTransparent,
                            data: baseURL
                        },
                    })
                    setData(baseURL)
                    setFileName(file.name)

                    resolve(baseURL);
                };
            });
        }
    
    
        return <div>

            <FormControlLabel sx={{ color: '#005591', fontFamily: 'LindeDax, Arial, sans-serif', marginLeft: 1 }}
                control={
                    <Checkbox checked={isTransparent} onChange={onChangeTransparent} />
                }
                label="Transparent Background"
            />

            {
                (!isTransparent) ?

                    <>
                        <ListItem id={'config-list-item'} className={'config-list-item'}>
                            <FormatColorTextIcon titleAccess='Background Color' />
                            <TextField id="standard-basic" className='config-menu-item-text' variant="standard"
                                size="small"
                                value={backgroundColor}
                                onClick={() => toggleColorPicker(true)}
                                onChange={event => onChangeBackgroundColor(event.target.value)}
                            />
            
                            <div style={{
                                width: '25px', height: '25px',
                                backgroundColor: backgroundColor
                            }}
                                onClick={() => toggleColorPicker(!isColorPickerOpen)} />
                            <br />
                        </ListItem>
                        <ListItem id={'config-list-item'} className={'config-list-item'}>
                            {isColorPickerOpen && <HexColorPicker
                                style={{ width: 'inherit !important' }}

                                height={300}
                                color={backgroundColor}
                                onChange={color => {
                                    updateColor(color)
                                }}
                            />
                            }
                        </ListItem> 
                    </>: <br />
            }

            <br />
            
            <ListItem id={'config-list-item'} className={'config-list-item'}>
                <label htmlFor="btn-upload">
                    <input
                        id="btn-upload"
                        name="btn-upload"
                        style={{ display: 'none' }}
                        type="file"
                        onChange={onFileChange} 
                    />
                        <Button
                            className="btn-choose"
                            variant="contained"
                            size={'small'}
                            component="span"
                            startIcon={<CloudUploadIcon />}
                        >
                            Upload
                        </Button>
                </label>
                <ListItemText primary={(isImage ? fileName : errorFileName )} className='config-menu-item-text' style={{ color: (isImage ? 'inherit' : '#dc004e') }} />
            </ListItem>

            <br />

        </div>
}


export default ImageConfiguration