import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from '@mui/material';

const SaveAllWidgetDialog = (
    { 
        pageId,
        saveAll,
        open, setOpen, ...props }) => {

    const handleClose = () => {
        setOpen();
    };

    const confirmSaveWidget = () => {
        saveAll()
        setOpen();
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {`Do you want to save all the changes you made to the page ? - ${pageId}`}
            </DialogTitle>

            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Caution : Everything will be save into the database which including all widgets configuration and layout.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={confirmSaveWidget} autoFocus>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}


export default SaveAllWidgetDialog;