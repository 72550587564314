import PropTypes from "prop-types";
import { Line, Image, PowerBi, Kusto, Spectrogram, FFT, Waterfall, Pie, SurfaceChart, Scorecard, Label, AreaGraph, ButtonWidget, Bar, Grafana } from 'components/widgets'
import Scatter from "components/widgets/scatter";

const WidgetItemContent = ({ id, type, width, height, widgetAdvanceSettings, widgetRefreshData, ...props }) => {


    var returnContent = null
    switch (type) {

        case "label":
            return <div className="widget-item__content" style={{ height: 'inherit' }}>
                <Label
                    id={id}
                    widgetRefreshData={widgetRefreshData}
                    config={widgetAdvanceSettings?.label}
                    width={width}
                    height={height}
                    title={widgetAdvanceSettings?.label?.title}
                    fontSize={widgetAdvanceSettings?.label?.fontSize}
                    layoutColor={widgetAdvanceSettings?.label?.layoutColor}
                    backgroundColor={widgetAdvanceSettings?.label?.backgroundColor}
                    isTransparent={widgetAdvanceSettings?.label?.isTransparent}
                    {...props}
                />
            </div>

        case "bar":
            return <div className="widget-item__content" style={{ height: 'inherit' }}>
                <Bar
                    id={id}
                    widgetRefreshData={widgetRefreshData}
                    lineId={widgetAdvanceSettings?.bar?.lineId}
                    config={widgetAdvanceSettings?.bar}
                    width={width}
                    height={height}
                    titleX={widgetAdvanceSettings?.bar?.titleX}
                    titleY={widgetAdvanceSettings?.bar?.titleY}
                    layoutColor={widgetAdvanceSettings?.bar?.layoutColor}
                    backgroundColor={widgetAdvanceSettings?.bar?.backgroundColor}
                    isTransparent={widgetAdvanceSettings?.bar?.isTransparent}
                    tagList={widgetAdvanceSettings?.bar?.tagList}
                    isFill={widgetAdvanceSettings?.bar?.isFill}
                    selectedTags={widgetAdvanceSettings?.bar?.selectedTags}
                    kustoToken={props?.authentication?.token?.kustoToken}
                    legendOrientation={widgetAdvanceSettings?.bar?.legendOrientation}
                    gridLines={widgetAdvanceSettings?.bar?.gridLines}
                    chartOpacity={widgetAdvanceSettings?.bar?.chartOpacity}
                    orientation={widgetAdvanceSettings?.bar?.orientation}
                    {...props}
                />
            </div>

        case "button":
            return <div className="widget-item__content" style={{ height: 'inherit' }}>
                <ButtonWidget
                    id={id}
                    widgetRefreshData={widgetRefreshData}
                    config={widgetAdvanceSettings?.button}
                    width={width}
                    height={height}
                    buttonText={widgetAdvanceSettings?.button?.buttonText}
                    fontSize={widgetAdvanceSettings?.button?.fontSize}
                    layoutColor={widgetAdvanceSettings?.button?.layoutColor}
                    backgroundColor={widgetAdvanceSettings?.button?.backgroundColor}
                    isTransparent={widgetAdvanceSettings?.button?.isTransparent}
                    buttonVariant={widgetAdvanceSettings?.button?.buttonVariant}
                    targetLocation={widgetAdvanceSettings?.button?.targetLocation}
                    href={widgetAdvanceSettings?.button?.href}
                    isDisable={widgetAdvanceSettings?.button?.isDisable}
                    {...props}
                />
            </div>

        case "line":
            return <div className="widget-item__content" style={{ height: 'inherit' }}>
                <Line
                    id={id}
                    widgetRefreshData={widgetRefreshData}
                    lineId={widgetAdvanceSettings?.line?.lineId}
                    config={widgetAdvanceSettings?.line}
                    width={width}
                    height={height}
                    titleX={widgetAdvanceSettings?.line?.titleX}
                    titleY={widgetAdvanceSettings?.line?.titleY}
                    layoutColor={widgetAdvanceSettings?.line?.layoutColor}
                    backgroundColor={widgetAdvanceSettings?.line?.backgroundColor}
                    isTransparent={widgetAdvanceSettings?.line?.isTransparent}
                    isStackGroup={widgetAdvanceSettings?.line?.isStackGroup}
                    tagList={widgetAdvanceSettings?.line?.tagList}
                    isFill={widgetAdvanceSettings?.line?.isFill}
                    selectedTags={widgetAdvanceSettings?.line?.selectedTags}
                    kustoToken={props?.authentication?.token?.kustoToken}
                    legendOrientation={widgetAdvanceSettings?.line?.legendOrientation}
                    gridLines={widgetAdvanceSettings?.line?.gridLines}
                    alarmValue={widgetAdvanceSettings?.line?.alarmValue}
                    warningValue={widgetAdvanceSettings?.line?.warningValue}
                    {...props}
                />
            </div>

        case "scatter":
            return <div className="widget-item__content" style={{ height: 'inherit' }}>
                <Scatter
                    id={id}
                    widgetRefreshData={widgetRefreshData}
                    lineId={widgetAdvanceSettings?.scatter?.lineId}
                    config={widgetAdvanceSettings?.line}
                    width={width}
                    height={height}
                    titleX={widgetAdvanceSettings?.scatter?.titleX}
                    titleY={widgetAdvanceSettings?.scatter?.titleY}
                    layoutColor={widgetAdvanceSettings?.scatter?.layoutColor}
                    backgroundColor={widgetAdvanceSettings?.scatter?.backgroundColor}
                    isTransparent={widgetAdvanceSettings?.scatter?.isTransparent}
                    isStackGroup={widgetAdvanceSettings?.scatter?.isStackGroup}
                    tagList={widgetAdvanceSettings?.scatter?.tagList}
                    isFill={widgetAdvanceSettings?.scatter?.isFill}
                    selectedTags={widgetAdvanceSettings?.scatter?.selectedTags}
                    kustoToken={props?.authentication?.token?.kustoToken}
                    legendOrientation={widgetAdvanceSettings?.scatter?.legendOrientation}
                    gridLines={widgetAdvanceSettings?.scatter?.gridLines}
                    alarmValue={widgetAdvanceSettings?.scatter?.alarmValue}
                    warningValue={widgetAdvanceSettings?.scatter?.warningValue}
                    {...props}
                />
            </div>
        
        case "image":
            return <div className="widget-item__content" style={{ height: 'inherit' }}>
                <Image
                    id={id}
                    widgetRefreshData={widgetRefreshData}
                    dashboardId={widgetAdvanceSettings?.image?.dashboardId}
                    config={widgetAdvanceSettings?.image}
                    width={width}
                    height={height}
                    imgData={widgetAdvanceSettings?.image?.data}
                    imageBackgroundColor={widgetAdvanceSettings?.image?.backgroundColor}
                    imageTransparent={widgetAdvanceSettings?.image?.isTransparent}
                    {...props}
                />
            </div>

        case "pie":
            return <div className="widget-item__content" style={{ height: 'inherit' }}>
                <Pie
                    id={id}
                    widgetRefreshData={widgetRefreshData}
                    config={widgetAdvanceSettings?.pie}
                    kustoToken={props?.authentication?.token?.kustoToken}
                    width={width}
                    height={height}
                    chartTitle={widgetAdvanceSettings?.pie?.chartTitle}
                    isDonut={widgetAdvanceSettings?.pie?.isDonut}
                    textInfo={widgetAdvanceSettings?.pie?.textInfo}
                    donutSize={widgetAdvanceSettings?.pie?.donutSize}
                    textPosition={widgetAdvanceSettings?.pie?.textPosition}
                    categoryText={widgetAdvanceSettings?.pie?.categoryText}
                    lineColor={widgetAdvanceSettings?.pie?.lineColor}
                    layoutColor={widgetAdvanceSettings?.pie?.layoutColor}
                    backgroundColor={widgetAdvanceSettings?.pie?.backgroundColor}
                    isTransparent={widgetAdvanceSettings?.pie?.isTransparent}
                    tagList={widgetAdvanceSettings?.pie?.tagList}
                    selectedTags={widgetAdvanceSettings?.pie?.selectedTags}
                    legendOrientation={widgetAdvanceSettings?.pie?.legendOrientation}
                    categoryTextSize={widgetAdvanceSettings?.pie?.categoryTextSize}
                    titleSize={widgetAdvanceSettings?.pie?.titleSize}
                    {...props}
                />
            </div>

        case "area":
            return <div className="widget-item__content" style={{ height: 'inherit' }}>
                <AreaGraph
                    id={id}
                    widgetRefreshData={widgetRefreshData}
                    config={widgetAdvanceSettings?.area}
                    kustoToken={props?.authentication?.token?.kustoToken}
                    width={width}
                    height={height}
                    layoutColor={widgetAdvanceSettings?.area?.layoutColor}
                    backgroundColor={widgetAdvanceSettings?.area?.backgroundColor}
                    isTransparent={widgetAdvanceSettings?.area?.isTransparent}
                    selectedTags={widgetAdvanceSettings?.area?.selectedTags}
                    {...props}
                />
            </div>
        
        case "fft":
            return <div className="widget-item__content" style={{ height: 'inherit' }}>
                <FFT
                    id={id}
                    widgetRefreshData={widgetRefreshData}
                    dashboardId={widgetAdvanceSettings?.kusto?.dashboardId}
                    lineColor={widgetAdvanceSettings?.fft?.lineColor}
                    layoutColor={widgetAdvanceSettings?.fft?.layoutColor}
                    backgroundColor={widgetAdvanceSettings?.fft?.backgroundColor}
                    isTransparent={widgetAdvanceSettings?.fft?.isTransparent}
                    legendOrientation={widgetAdvanceSettings?.fft?.legendOrientation}
                    gridLines={widgetAdvanceSettings?.fft?.gridLines}
                    selectedTags={widgetAdvanceSettings?.fft?.selectedTags}
                    tagList={widgetAdvanceSettings?.fft?.tagList}
                    width={width}
                    height={height}
                    {...props}
                />
            </div>
        
        case "spectrogram":
            return <div className="widget-item__content" style={{ height: 'inherit' }}>
                <Spectrogram
                    id={id}
                    widgetRefreshData={widgetRefreshData}
                    dashboardId={widgetAdvanceSettings?.spectrogram?.dashboardId}
                    config={widgetAdvanceSettings?.spectrogram}
                    lineColor={widgetAdvanceSettings?.spectrogram?.lineColor}
                    layoutColor={widgetAdvanceSettings?.spectrogram?.layoutColor}
                    backgroundColor={widgetAdvanceSettings?.spectrogram?.backgroundColor}
                    isTransparent={widgetAdvanceSettings?.spectrogram?.isTransparent}
                    selectedTags={widgetAdvanceSettings?.spectrogram?.selectedTags}
                    tagList={widgetAdvanceSettings?.spectrogram?.tagList}
                    width={width}
                    height={height}
                    {...props}
                />
            </div>
        
        case "waterfall":
            return <div className="widget-item__content" style={{ height: 'inherit' }}>
                <Waterfall
                    id={id}
                    widgetRefreshData={widgetRefreshData}
                    dashboardId={widgetAdvanceSettings?.waterfall?.dashboardId}
                    config={widgetAdvanceSettings?.waterfall}
                    layoutColor={widgetAdvanceSettings?.waterfall?.layoutColor}
                    backgroundColor={widgetAdvanceSettings?.waterfall?.backgroundColor}
                    isTransparent={widgetAdvanceSettings?.waterfall?.isTransparent}
                    selectedTags={widgetAdvanceSettings?.waterfall?.selectedTags}
                    tagList={widgetAdvanceSettings?.waterfall?.tagList}
                    width={width}
                    height={height}
                    {...props}
                />
            </div>

        case "power-bi":
            return <div className="widget-item__content" style={{ height: 'inherit' }}>
                <PowerBi
                    id={id}
                    widgetRefreshData={widgetRefreshData}
                    workspaceId={widgetAdvanceSettings?.powerBi?.workspaceId}
                    reportId={widgetAdvanceSettings?.powerBi?.reportId}
                    width={width}
                    height={height}
                    {...props}
                />
            </div>

            
        case "kusto":
            return <div className="widget-item__content" style={{ height: 'inherit' }}>
                <Kusto
                    id={id}
                    widgetRefreshData={widgetRefreshData}
                    dashboardId={widgetAdvanceSettings?.kusto?.dashboardId}
                    config={widgetAdvanceSettings?.kusto}
                    width={width}
                    height={height}
                    {...props}
                />
            </div>

        case "surfaceChart":
            return <div className="widget-item__content" style={{ height: 'inherit' }}>
                <SurfaceChart
                    id={id}
                    widgetRefreshData={widgetRefreshData}
                    config={widgetAdvanceSettings?.surfaceChart}
                    width={width}
                    height={height}
                    {...props} />
            </div>

        case "scorecard":
            return <div className="widget-item__content" style={{ height: 'inherit' }}>
                <Scorecard
                    id={id}
                    widgetRefreshData={widgetRefreshData}
                    width={width}
                    height={height}
                    selectedTagValue={widgetAdvanceSettings?.scorecard?.selectedTagValue}
                    selectedReducerFunctionIndex={widgetAdvanceSettings?.scorecard?.selectedReducerFunctionIndex}
                    textSize={widgetAdvanceSettings?.scorecard?.textSize}
                    kustoToken={props?.authentication?.token?.kustoToken}
                    {...props} />
            </div>

        case "grafana":
            return <div className="widget-item__content" style={{ height: 'inherit' }}>
                <Grafana
                    id={id}
                    widgetRefreshData={widgetRefreshData}
                    width={width}
                    height={height}
                    snapshotKey={widgetAdvanceSettings?.grafana?.snapshotKey}
                    {...props} />
            </div> 

        default:
            returnContent = null;
    }

    return returnContent


};

WidgetItemContent.propTypes = {
    type: PropTypes.string,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired
};

export default WidgetItemContent;
