import React, { useEffect, useState } from 'react';
import { Grid, Box, Hidden } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import logo from 'assets/images/linde-logo.svg';
import DrawerMenu from 'pages/_layout/DrawerMenu';
import { applicationTitle, clientName  } from 'config'
import TimeSelector from './TimeSelector';



const Header = props => {


    // **************************************************************************
    // Bread Crumb
    // **************************************************************************
    const location = useLocation();
    const [loc1, setLoc1] = useState('');
    const [loc2, setLoc2] = useState('');

    useEffect(() => {
        
        // setShowDateTimePicker(false);
        const address = location.pathname.split('/');

        if (location.pathname.length <= 1) { // Home Page
            setLoc1('');
            setLoc2('');
        }
        else if (address.length <= 2) { // :page
            setLoc1(address[1]);
            setLoc2('');
        }
        else if (address.length <= 3) { // /:Policy/:page
            if (address[1].toUpperCase() === 'POLICY') {
                const txt = address[2].replace(/\b(\w)/g, k => k.toUpperCase()); // Make Title Case
                setLoc1(txt.replace(/[^a-zA-Z]/g, ' ')); // Remove Special Characters
                setLoc2('');
            }
        }
        else if (address.length <= 4) { // /:Overview/:site/:device
            setLoc1(address[3]);
            setLoc2('');

            // if (address[1] === 'report') {
            //     setShowDateTimePicker(false);
            // }
        }
        else if (address.length <= 5) { // /Overview/:site/:device/:sensor
            setLoc1(address[3]);
            setLoc2(address[4]);

            // if (address[1] === 'report') {
            //     setShowDateTimePicker(true);
            // }
        }
        else {
            setLoc1('');
            setLoc2('');
        }

        // Only path with custom are allowed to have datetime picker
        if(address[1] !== 'custom'){
            props.toggleDateTimePicker(false)
        }

    }, [location]);

    const onHomeClicked = () => {
        window.location = `#/`
    }

    // const onclicked = (siteName: String) =>{
    //     if (location.pathname.split('/')[1] === 'report') {
    //         var site = siteName.split('_')[0]
    //         window.location = `#/report/${site}/${siteName}`
    //     }
        
    // };

    return (
        <header>
            <Grid container justifyContent='space-between' alignItems='center' className='header-content'>
                
                <Grid item>
                    <Box display='flex' alignItems='center'>
                        <Box>
                            <DrawerMenu {...props} />
                        </Box>
                        <Hidden smDown>
                            <Box ml={2}>
                                <div className='header-link LindeDaxMed'>
                                    <h1 className='header-link-hover' style={{ textTransform: 'none' }} onClick={onHomeClicked}>{applicationTitle} - {clientName}</h1>
                                </div>
                            </Box>
                            {/* <Box ml={6}>
                                <div className='header-link'>
                                    <h2 style={{ textTransform: 'none' }}>
                                        <span className='header-link-hover' onClick={() => onclicked(loc1)}>{loc1} </span>
                                        {(loc2.length > 0) ? <span style={{ padding: '0px 12px' }}>{'>'}</span> : <span></span>}
                                        {loc2}
                                    </h2>
                                </div>
                            </Box> */}
                        </Hidden>
                    </Box>
                </Grid>

                <Grid item style={{ marginRight: '1rem' }}>
                    <Grid container justifyContent='space-between' alignItems='center' className='header-content'>
                        
                        {
                            (props.global.showDateTimePicker) && <Grid item style={{marginRight: '30px'}}>
                                    <TimeSelector {...props} />
                                </Grid>
                        }

                        <Hidden mdDown>
                            <Grid item>
                                <NavLink className='logo-link' to='/'>
                                    <img src={logo} className='logo' alt='Linde, Making our world more productive' />
                                </NavLink>
                            </Grid>
                        </Hidden>
                    </Grid>
                </Grid>
            </Grid>
        </header>
    );
};

export default Header;