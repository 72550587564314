export const rawdata = {
    "Block_A": {
      "Data": {
        "0": {
          "TagName": "CHEVRON.2E-1408-LN:T.Block_A_Sensor_top",
          "data_value": [
            -31.874290466308594,
            -31.874290466308594,
            -31.874290466308594,
            -31.874290466308594,
            -31.874290466308594,
            -31.874290466308594,
            -31.874290466308594,
            -31.874290466308594,
            -31.874290466308594,
            -31.874290466308594,
            -31.874290466308594,
            -31.874290466308594,
            -31.874290466308594,
            -31.874290466308594,
            -31.874290466308594,
            -31.874290466308594,
            -31.874290466308594,
            -31.874290466308594,
            -31.874290466308594,
            -31.874290466308594,
            -31.874290466308594,
            -31.874290466308594,
            -31.874290466308594,
            -31.874290466308594,
            -31.874290466308594
          ],
          "SensorLocation": 0,
          "limits": {
            "tnl": -33.57,
            "tnh": -32.18,
            "tal": -43.57,
            "tah": -22.18,
            "tall": -53.57,
            "tahh": -12.18
          }
        },
        "1": {
          "TagName": "CHEVRON.2E-1408-LN:T.Block_A_Sensor_1",
          "data_value": [
            -37.350103256550426,
            -37.564886220296444,
            -37.45771592458112,
            -37.63257706960064,
            -37.59975897471131,
            -37.54669958750429,
            -37.56145401000998,
            -37.572202491760464,
            -37.5256202697756,
            -37.55534693400088,
            -37.55939076741558,
            -37.57771390279157,
            -37.55073261261009,
            -37.7462771733604,
            -37.64112326304141,
            -37.55391133626324,
            -37.54026756286643,
            -37.52353032430034,
            -37.607721265157295,
            -37.54749844868988,
            -37.531463813781954,
            -37.42466100056987,
            -37.40729459126811,
            -37.52110671997093,
            -37.49112818791312
          ],
          "SensorLocation": 0.345,
          "limits": {
            "tnl": -35.51,
            "tnh": -32.73,
            "tal": -45.51,
            "tah": -22.73,
            "tall": -55.51,
            "tahh": -12.73
          }
        },
        "2": {
          "TagName": "CHEVRON.2E-1408-LN:T.Block_A_Sensor_2",
          "data_value": [
            -40.08289093666906,
            -40.56294237772644,
            -40.3195837656659,
            -40.52336343129497,
            -40.45464954376243,
            -40.39951311747254,
            -40.372292073567934,
            -40.36603679657006,
            -40.32941449483258,
            -40.3968937555951,
            -40.30637963612895,
            -40.30181369781516,
            -40.25056355794294,
            -40.52630004882835,
            -40.37156798044862,
            -40.332910855611374,
            -40.35033175150576,
            -40.33655637105329,
            -40.35591157277446,
            -40.32604433695485,
            -40.32153479258241,
            -40.23488566080752,
            -40.2066926956179,
            -40.395541063944705,
            -40.39603453416086
          ],
          "SensorLocation": 1.275,
          "limits": {
            "tnl": -44.69,
            "tnh": -41.51,
            "tal": -54.69,
            "tah": -31.51,
            "tall": -69.69,
            "tahh": -21.51
          }
        },
        "3": {
          "TagName": "CHEVRON.2E-1408-LN:T.Block_A_Sensor_3",
          "data_value": [
            -46.44527768074212,
            -47.622275161743346,
            -47.42228787740092,
            -47.958780543009624,
            -47.614833259582696,
            -47.005871327718296,
            -46.595757293701354,
            -46.35307102203387,
            -46.39449679056821,
            -46.63275674184183,
            -46.12097307841003,
            -45.79165509541849,
            -45.770805486043514,
            -46.183417320251664,
            -45.8659012476605,
            -46.037196731567576,
            -46.31390635172545,
            -46.37408917744975,
            -46.16407216389993,
            -46.18001435597742,
            -46.50095920562762,
            -46.64041372934997,
            -46.71918614705422,
            -47.30067749023456,
            -47.32985452505255
          ],
          "SensorLocation": 1.73,
          "limits": {
            "tnl": -51.44,
            "tnh": -48.07,
            "tal": -71.44,
            "tah": -38.07,
            "tall": -86.44,
            "tahh": -28.07
          }
        },
        "4": {
          "TagName": "CHEVRON.2E-1408-LN:T.Block_A_Sensor_4",
          "data_value": [
            -58.45570933565196,
            -61.130570348104044,
            -60.878118960062864,
            -62.045458793640336,
            -61.21672465006529,
            -59.57136681874613,
            -58.44796574910501,
            -57.75982799530047,
            -57.93907578786232,
            -58.466058095296404,
            -57.20436496734638,
            -56.27429485321065,
            -56.348710823059264,
            -56.98983923594176,
            -56.349429766337266,
            -56.90709247589129,
            -57.64997539520283,
            -57.86666571299253,
            -57.21254011789978,
            -57.280985196431544,
            -58.16457265218119,
            -58.75868701934834,
            -59.096433130900266,
            -60.39396457672137,
            -60.47308349609369
          ],
          "SensorLocation": 2.085,
          "limits": {
            "tnl": -63.59,
            "tnh": -58.85,
            "tal": -108.59,
            "tah": -48.85,
            "tall": -138.59,
            "tahh": -38.85
          }
        },
        "5": {
          "TagName": "CHEVRON.2E-1408-LN:T.Block_A_Sensor_5",
          "data_value": [
            -143.69318958038986,
            -146.8626050313315,
            -146.92320709228528,
            -148.06883341471365,
            -147.1844207763673,
            -145.06084416707367,
            -143.3150868733725,
            -142.10370229085297,
            -142.51998926798515,
            -143.29134750366222,
            -140.90920715332044,
            -138.97154744466158,
            -139.44503250122082,
            -140.588424173991,
            -139.09934565226249,
            -140.50842005411798,
            -141.97823359171562,
            -142.38747685750337,
            -140.91484985351576,
            -141.09027048746742,
            -142.48662618001313,
            -143.84445953369152,
            -144.47782567342134,
            -146.19420471191418,
            -146.272462111253
          ],
          "SensorLocation": 3.525,
          "limits": {
            "tnl": -151.06,
            "tnh": -149.57,
            "tal": -160.06,
            "tah": -119.57,
            "tall": -171.06,
            "tahh": -94.57
          }
        },
        "6": {
          "TagName": "CHEVRON.2E-1408-LN:T.Block_A_Sensor_6",
          "data_value": [
            -148.76651974941825,
            -151.1936429341635,
            -151.19131291707367,
            -152.07226791381845,
            -151.42066268920908,
            -149.85001983642587,
            -148.5597595214845,
            -147.65703582763683,
            -147.9700393676759,
            -148.53750915527354,
            -146.74852727254245,
            -145.32032725016288,
            -145.70087458292653,
            -146.55604095458995,
            -145.40354512532565,
            -146.4729705810548,
            -147.57274703979505,
            -147.87068125406913,
            -146.76392313639334,
            -146.89559885660802,
            -147.91823094685884,
            -148.93273569742848,
            -149.40981038411468,
            -150.68917465209972,
            -150.74421339768628
          ],
          "SensorLocation": 3.8,
          "limits": {
            "tnl": -157.8,
            "tnh": -157.22,
            "tal": -167.8,
            "tah": -132.22,
            "tall": -177.8,
            "tahh": -122.22
          }
        },
        "7": {
          "TagName": "CHEVRON.2E-1408-LN:T.Block_A_Sensor_bottom",
          "data_value": [
            -161.22753524780273,
            -161.22753524780273,
            -161.22753524780273,
            -161.22753524780273,
            -161.22753524780273,
            -161.22753524780273,
            -161.22753524780273,
            -161.22753524780273,
            -161.22753524780273,
            -161.22753524780273,
            -161.22753524780273,
            -161.22753524780273,
            -161.22753524780273,
            -161.22753524780273,
            -161.22753524780273,
            -161.22753524780273,
            -161.22753524780273,
            -161.22753524780273,
            -161.22753524780273,
            -161.22753524780273,
            -161.22753524780273,
            -161.22753524780273,
            -161.22753524780273,
            -161.22753524780273,
            -161.22753524780273
          ],
          "SensorLocation": 4.375,
          "limits": {
            "tnl": -161.78,
            "tnh": -161.31,
            "tal": -171.78,
            "tah": -151.31,
            "tall": -181.78,
            "tahh": -141.31
          }
        }
      },
      "Xunit": "m",
      "Yunit": "°C"
    }
  }