import { useEffect, useState } from 'react';
import axios from 'axios'
import Plotly from 'plotly.js-dist-min';
import createPlotlyComponent from 'react-plotly.js/factory';
import moment from 'moment';
import { ApiEndpoint } from 'config';
import { LINE_CONFIG } from './config';
import Loader from 'components/loader';

const Plot = createPlotlyComponent(Plotly);

const Line = ({ id, pageName, widgetRefreshData, showNotification, authentication, width, height, 
    alarmValue, warningValue, config, titleX, titleY, isTransparent, layoutColor, backgroundColor, tagList, isStackGroup, isFill, selectedTags, kustoToken, legendOrientation, gridLines, ...props }) => {
    
    const [isLoading, setIsLoading] = useState(true)
    const [revision, setRevision] = useState(0)
    const [chartData, setChartData] = useState([])

    const [currentSelectedTags, setCurrentSelectedTags] = useState(selectedTags)

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        if (currentSelectedTags?.toString() !== selectedTags?.toString() && tagList !== undefined && tagList.length > 0) {
            setCurrentSelectedTags(selectedTags)
            getData()
        }
    }, [tagList])

    useEffect(() => {
        let _chartData = chartData.slice()

        for (let i=0; i<_chartData.length; i++) {
            _chartData[i].fill = (isFill) ? 'tozeroy' : null
            _chartData[i].stackgroup = (isStackGroup) ? 'one' : null
        }
        setChartData(_chartData)
        setRevision(revision+1)
    }, [isStackGroup, isFill, alarmValue, warningValue])

    useEffect(() => {
        getData()
    }, [props?.global?.startDateTime, props?.global?.endDateTime])

    const getData = async () => {
        setIsLoading(true)

        if (selectedTags !== undefined) {
            for (let i=0; i<selectedTags.length; i++) {
                let idx = tagList.findIndex(x => x.id === selectedTags[i])
    
                if (idx !== undefined) {
                    tagList[idx].isSelected = true
                }
            }
        }
        
        let _chartData = []
        let selectedTagList = tagList?.filter(x => x.isSelected === true)

        if (selectedTagList?.length > 0) {

            for (let x=0; x<selectedTagList.length; x++) {
                await axios.get(`${ApiEndpoint.Kusto.GetValuesBetweenTimestamps}`, {
                    params: {
                        TagName: selectedTagList[x].name,
                        StartUTCTimestamp: moment(props?.global?.startDateTime).format('YYYY-MM-DDTHH:mm:ss'),
                        EndUTCTimestamp: moment(props?.global?.endDateTime).format('YYYY-MM-DDTHH:mm:ss'),
                        Count: 1000
                    },
                    headers: {
                        'authorization': `bearer ${kustoToken}`
                    }
                }).then(resp => {
                    let data = resp.data
                    let timestamps = []
                    let values = []

                    for (let i=0; i<data.length; i++) {
                        timestamps.push(data[i].UTCTimeStamp)
                        values.push(data[i].Value)
                    }
                
                    _chartData.push({
                        x: timestamps,
                        y: values,
                        type: 'scatter',
                        name: selectedTagList[x].description,
                        mode: 'lines+points', //Options: "lines", "markers", "lines+markers", "lines+markers+text", "none"
                        fill: (isFill) ? 'tozeroy' : null,
                        stackgroup: (isStackGroup) ? 'one' : null
                    })
                }).catch(err => {
                    console.log(err)
                    showNotification(err.response?.data?.error ?? err.response?.data?.title, 'danger')
                })
            }

            setChartData(_chartData)
            setRevision(revision+1)
        }
        else {
            setChartData([])
            setRevision(revision+1)
        }

        setIsLoading(false)
    }

    useEffect(() => {
        console.log('Page Name change to ', pageName)
    }, [pageName])

    useEffect(() => {
        console.debug(`Refreshing page ${id}`)
        if (true) {
            getData()
            showNotification('Refresh success!', 'success')
        }
    }, [widgetRefreshData])

    const hexToRgba = (hex) => {
        var c;
        if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
            c= hex.substring(1).split('');
            if(c.length== 3){
                c= [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c= '0x'+c.join('');
            return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',0.8)';
        }
        //throw new Error('Bad Hex');
        return '#005591'
    }

    return <div style={{ backgroundColor: (isTransparent) ? 'transparent' : backgroundColor }}>
        {
            (isLoading) ? 
                <Loader /> :
                <>
                    <Plot useResizeHandler
                        data={chartData}
                        layout={{
                            paper_bgcolor: 'transparent',
                            plot_bgcolor: 'transparent',
                            datarevision: revision,
                            uirevision: revision,
                            width: width,
                            height: (height-10),
                            showlegend: (legendOrientation===null) ? false : true,
                            legend: {
                                orientation: legendOrientation,
                                font: {
                                    color: hexToRgba(layoutColor)
                                }
                            },
                            xaxis: {
                                autotick: true,
                                type: 'string',
                                title: {
                                    text: (selectedTags?.length > 1) ? ((legendOrientation !== LINE_CONFIG.Legend[1]) ? titleX : '') : titleX
                                },
                                color: layoutColor,
                                showspikes: true,
                                spikemode: 'toaxis+across+marker',
                                spikesnap: 'hovered data',
                                showline: false,
                                showgrid: (gridLines===LINE_CONFIG.GridLines[0] || gridLines===LINE_CONFIG.GridLines[1]) ? true : false,
                                spikedash: 'solid',
                                spikethickness: 1,
                            },
                            yaxis: {
                                autotick: true,
                                type: 'string',
                                title: titleY,
                                color: layoutColor,
                                showspikes: true,
                                spikemode: 'toaxis+across+marker',
                                spikesnap: 'cursor',
                                showline: true,
                                showgrid: (gridLines===LINE_CONFIG.GridLines[0] || gridLines===LINE_CONFIG.GridLines[2]) ? true : false,
                                spikedash: 'solid',
                                spikethickness: 1
                            },
                            hovermode: 'x unified',
                            hoverlabel: {
                                bgcolor: 'white'
                            },
                            // shapes: (chartData.length > 0) ? [
                            //     {
                            //         x0: moment(chartData[0].x[0]).utc().format('YYYY-MM-DDTHH:mm:ss'),
                            //         y0: warningValue,
                            //         x1: moment(chartData[0].x[chartData[0].x.length-1]).utc().format('YYYY-MM-DDTHH:mm:ss'),
                            //         y1: warningValue,
                            //         line: {
                            //             color: '#dc7800',
                            //             dash: 'dot'
                            //         }
                            //     },
                            //     {
                            //         x0: moment(chartData[0].x[0]).utc().format('YYYY-MM-DDTHH:mm:ss'),
                            //         y0: alarmValue,
                            //         x1: moment(chartData[0].x[chartData[0].x.length-1]).utc().format('YYYY-MM-DDTHH:mm:ss'),
                            //         y1: alarmValue,
                            //         line: {
                            //             color: 'red',
                            //             dash: 'dot'
                            //         }
                            //     }
                            // ] : null
                        }}
                    />
                </>
        }
    </div>
};

export default Line;


