import React, { useEffect, useState } from "react";
import axios from 'axios'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from '@mui/material';
import { LoadingButton } from "@mui/lab";

const SaveWidgetDialog = (
    { id,
        saveAllWidget,
        widgetGeneralSettings,
        widgetAdvanceSettings,
        saveWidgetConfiguration,
        open, setOpen,
        ...props }) => {


    useEffect(() => {

        if(saveAllWidget){
            confirmSaveWidget()
        }
    }, [saveAllWidget])

    const [isSavingWidget, setSavingWidget] = useState(false)

    const handleClose = () => {
        setOpen();
    };

    const confirmSaveWidget = async () => {
        setSavingWidget(true)
        await saveWidgetConfiguration().finally(setSavingWidget(false))

            
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {`Do you want to save the changes you made to the widget ?`}
            </DialogTitle>

            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    The old configuration will be overwritten with new configuration . <br />
                    Please confirm that you wish to save the existing configuration.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <LoadingButton loading={isSavingWidget} onClick={confirmSaveWidget}>
                    Save
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}


export default SaveWidgetDialog;