
import { useEffect, useState } from 'react';
import { HexColorPicker } from "react-colorful";

import {
    ListItem,
    TextField,
    FormControlLabel,
    Checkbox,
    FormControl,
    InputLabel,
    Select,
    Box,
    Chip,
    MenuItem,
    ListItemText,
    Input,
    ButtonGroup,
    Button,
    ListSubheader,
    Divider
} from '@mui/material'

import {
    FormatPaint as FormatPaintIcon,
    FormatColorText as FormatColorTextIcon,
    LocalOffer as LocalOfferIcon,
    ShowChart as ShowChartIcon
} from '@mui/icons-material'
import axios from 'axios';
import { ApiEndpoint } from 'config';
import { LINE_CONFIG } from 'components/widgets/line/config';

const tagList = [
    {
        "id": "dc387ccd-e41c-43f0-b064-3296972492a8",
        "name": "TagCSV3",
        "description": "Tagdesc3",
        "unit": "FT",
        "site": "site3",
        "region": "region3",
        "plant": "plant3",
        "maxLimit": 1000,
        "minLimit": 100,
        "isSelected": false
    },
    {
        "id": "de023532-3773-43b7-9d6d-030dd74504c0",
        "name": "TagCSV2",
        "description": "Tagdesc2",
        "unit": "FT",
        "site": "site2",
        "region": "region2",
        "plant": "plant2",
        "maxLimit": 1000,
        "minLimit": 100,
        "isSelected": false
    },
    {
        "id": "d2ec01aa-ce5c-4ac4-ae84-8f06dc410963",
        "name": "TagCSV4",
        "description": "Tagdesc4",
        "unit": "FT",
        "site": "site4",
        "region": "region4",
        "plant": "plant4",
        "maxLimit": 1000,
        "minLimit": 100,
        "isSelected": false
    },
    {
        "id": "647b9f49-d133-41e6-a32b-43c512f3f542",
        "name": "TagCSV",
        "description": "Tagdesc",
        "unit": "FT",
        "site": "site",
        "region": "region",
        "plant": "plant",
        "maxLimit": 1000,
        "minLimit": 100,
        "isSelected": false
    },
    {
        "id": "9e8030bb-932f-4324-945f-89c1ba65573e",
        "name": "TagCSV2",
        "description": "Tagdesc2",
        "unit": "FT",
        "site": "site2",
        "region": "region2",
        "plant": "plant2",
        "maxLimit": 1000,
        "minLimit": 100,
        "isSelected": false
    },
    {
        "id": "59927fcb-b28c-4fce-b295-c4cd031bce78",
        "name": "TagCSV3",
        "description": "Tagdesc3",
        "unit": "FT",
        "site": "site3",
        "region": "region3",
        "plant": "plant3",
        "maxLimit": 1000,
        "minLimit": 100,
        "isSelected": false
    },
    {
        "id": "5acef788-b36a-4a08-bf52-58079dc7ed0d",
        "name": "TagCSV4",
        "description": "Tagdesc4",
        "unit": "FT",
        "site": "site4",
        "region": "region4",
        "plant": "plant4",
        "maxLimit": 1000,
        "minLimit": 100,
        "isSelected": false
    },
    {
        "id": "5b4ef5fe-013e-45f1-ae2d-c94f413d6ea8",
        "name": "TagCSV4",
        "description": "Tagdesc4",
        "unit": "FT",
        "site": "site4",
        "region": "region4",
        "plant": "plant4",
        "maxLimit": 1000,
        "minLimit": 100,
        "isSelected": false
    },
    {
        "id": "c57ce176-ca5b-4728-85fe-3a1019a39b59",
        "name": "asd",
        "description": "asd",
        "unit": "FT",
        "site": "ads",
        "region": "asd",
        "plant": "asd",
        "maxLimit": 100,
        "minLimit": 11,
        "isSelected": false
    },
    {
        "id": "41790049-f972-48ea-a181-e0cd98db60ae",
        "name": "TagCSV",
        "description": "Tagdesc",
        "unit": "FT",
        "site": "site",
        "region": "region",
        "plant": "plant",
        "maxLimit": 1000,
        "minLimit": 100,
        "isSelected": false
    },
    {
        "id": "720170e1-69ab-4b24-a766-1216edb3564a",
        "name": "TagCSV3 Demo",
        "description": "Tagdesc3",
        "unit": "FT",
        "site": "site3",
        "region": "region3",
        "plant": "plant3",
        "maxLimit": 1000,
        "minLimit": 100,
        "isSelected": false
    },
    {
        "id": "0d473607-0518-44c9-9467-891c68e89917",
        "name": "CCM23A1_Channel_1_DC",
        "description": "CCM23A1_Channel_1_DC",
        "unit": "FT",
        "site": "site2",
        "region": "region2",
        "plant": "plant2",
        "maxLimit": 1000,
        "minLimit": 100,
        "isSelected": false
    },
    {
        "id": "48aaf68e-b34d-44cd-952a-da0502ceca28",
        "name": "CCM23A1_Channel_2_DC",
        "description": "CCM23A1_Channel_2_DC",
        "unit": "FT",
        "site": "site",
        "region": "region",
        "plant": "plant",
        "maxLimit": 1000,
        "minLimit": 100,
        "isSelected": false
    }
]


const FFTConfiguration = ({ id, title,
    isConfigDrawerOpen, toggleConfigDrawer, widgetAdvanceSettings,
    setWidgetAdvanceSettings,
    showNotification,
    ...props }) => {

        const [dashboardId, setDashboardId] = useState(widgetAdvanceSettings?.kusto?.dashboardId)

        const [isTransparent, setIsTransparent] = useState(true)
        
        const [layoutColor, setLayoutColor] = useState('#005591')
        const [isLayoutColorPickerOpen, toggleLayoutColorPicker] = useState(false);
        
        const [backgroundColor, setBackgroundColor] = useState('#FFFFFF')
        const [isBackgroundColorPickerOpen, toggleBackgroundColorPicker] = useState(false);

        const [selectedTags, setSelectedTags] = useState(widgetAdvanceSettings?.fft?.selectedTags ? widgetAdvanceSettings?.fft?.selectedTags : [])
        const [tagList, setTagList] = useState([])

        const [legendOrientation, setLegendOrientation] = useState(widgetAdvanceSettings?.fft?.legendOrientation ? widgetAdvanceSettings?.fft?.legendOrientation : LINE_CONFIG.Legend[2])
        const [gridLines, setGridLines] = useState(widgetAdvanceSettings?.fft?.gridLines ? widgetAdvanceSettings?.fft?.gridLines : LINE_CONFIG.GridLines[3])

        useEffect(() => {
            if (widgetAdvanceSettings?.fft) {
                setLayoutColor(widgetAdvanceSettings?.fft?.layoutColor)
                setBackgroundColor(widgetAdvanceSettings?.fft?.backgroundColor)
        
                if (widgetAdvanceSettings?.fft?.isTransparent !== undefined) {
                    setIsTransparent(widgetAdvanceSettings?.fft?.isTransparent)
                }
            }

            getAvailableTags()
        }, [])
      
        useEffect(() => {
            setWidgetAdvanceSettings({
                ...widgetAdvanceSettings,
                fft: {
                    layoutColor: layoutColor,
                    backgroundColor: backgroundColor,
                    isTransparent: isTransparent,
                    legendOrientation: legendOrientation,
                    gridLines: gridLines,
                    selectedTags: selectedTags,
                    tagList: tagList
                }
            })
        }, [layoutColor, backgroundColor, isTransparent, legendOrientation, gridLines, selectedTags, tagList])

        const onChangeTransparent = () => {
            setIsTransparent(!isTransparent)
        }

        const getAvailableTags = () => {
            axios.get(`${ApiEndpoint.Tag.GetAllTags}`, {
                headers: {
                    'authorization': `bearer ${props.authentication?.token?.accessToken}`
                }
            }).then(resp => {
                let _tagList = resp.data.map(obj => {
                    if (selectedTags.find(x => x === obj.id) !== undefined) {
                        return {...obj, isSelected: true}
                    }
                    else {
                        return {...obj, isSelected: false}
                    }
                })

                setTagList(_tagList)
                console.log('FFT Tag List > ', _tagList)
            }).catch(err => {
                console.log(err)
                showNotification(err.response?.data?.error ?? err.response?.data?.title, 'danger')
            })
        }

        const handleChange = (event) => {
            const { target: { value } } = event;
            setSelectedTags(value);
            
            let _tagList = [...tagList]
            let isSelectedCounter = 0
    
            for (let i=0; i<_tagList.length; i++) {
                if (value.includes(_tagList[i].id)) {
                    _tagList[i].isSelected = true
                    isSelectedCounter++
                }
                else {
                    _tagList[i].isSelected = false
                }
            }

            console.log('New selecrtedTags > ', value)
    
            setTagList(_tagList)
        };
    
        return <div>
            
        <br />
        <Divider variant='middle' />

        <ListSubheader style={{ color: '#00A0E1' }} disableSticky={true}>
            Data
        </ListSubheader>

        <ListItem id={'config-list-item'} className={'config-list-item'}>
            <LocalOfferIcon />
            <FormControl sx={{ m: 0 }} style={{ width: '410px' }}>
                <InputLabel id="demo-multiple-chip-label">Tags</InputLabel>
                <Select
                    multiple
                    value={selectedTags}
                    onChange={handleChange}
                    input={<Input label="Tag" />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', border: '0px' }}>
                        {selected.map((value) => (
                            <Chip key={value} label={tagList.find(x => x.id === value)?.description} />
                        ))}
                        </Box>
                    )}
                    // MenuProps={MenuProps}
                    className='config-menu-item-text'
                >
                    {/* {names.map((name) => (
                        <MenuItem key={name} value={name}>
                        <Checkbox checked={personName.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                        </MenuItem>
                    ))} */}
                    {
                        tagList.map((tag) => (
                            <MenuItem key={tag.id} value={tag.id} label={tag.description}>
                                <Checkbox checked={tag.isSelected} />
                                <ListItemText primary={tag.description} />
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        </ListItem>

        <br />
        <Divider variant='middle' />

        <ListSubheader style={{ color: '#00A0E1' }} disableSticky={true}>
            Legend
        </ListSubheader>

        <ListItem id={'config-list-item'} className={'config-list-item'}>
            <ButtonGroup variant="outlined" aria-label="outlined button group">
                <Button className={(legendOrientation === LINE_CONFIG.Legend[0]) ? 'is-active' : ''} onClick={() => setLegendOrientation(LINE_CONFIG.Legend[0])}>Vertical</Button>
                <Button className={(legendOrientation === LINE_CONFIG.Legend[1]) ? 'is-active' : ''} onClick={() => setLegendOrientation(LINE_CONFIG.Legend[1])}>Horizontal</Button>
                <Button className={(legendOrientation === LINE_CONFIG.Legend[2]) ? 'is-active' : ''} onClick={() => setLegendOrientation(LINE_CONFIG.Legend[2])}>None</Button>
            </ButtonGroup>
        </ListItem>

        <br />
        <Divider variant='middle' />

        <ListSubheader style={{ color: '#00A0E1' }} disableSticky={true}>
            Gridlines
        </ListSubheader>

        <ListItem id={'config-list-item'} className={'config-list-item'}>
            <ButtonGroup variant="outlined" aria-label="outlined button group">
                <Button className={(gridLines === LINE_CONFIG.GridLines[0]) ? 'is-active' : ''} onClick={() => setGridLines(LINE_CONFIG.GridLines[0])}>Both</Button>
                <Button className={(gridLines === LINE_CONFIG.GridLines[1]) ? 'is-active' : ''} onClick={() => setGridLines(LINE_CONFIG.GridLines[1])}>X-Axis</Button>
                <Button className={(gridLines === LINE_CONFIG.GridLines[2]) ? 'is-active' : ''} onClick={() => setGridLines(LINE_CONFIG.GridLines[2])}>Y-Axis</Button>
                <Button className={(gridLines === LINE_CONFIG.GridLines[3]) ? 'is-active' : ''} onClick={() => setGridLines(LINE_CONFIG.GridLines[3])}>None</Button>
            </ButtonGroup>
        </ListItem>

            <br />
            <Divider variant='middle' />
    
            <ListSubheader style={{ color: '#00A0E1' }} disableSticky={true}>
                Layout
            </ListSubheader>
            
            <FormControlLabel sx={{ color: '#005591', fontFamily: 'LindeDax, Arial, sans-serif', marginLeft: 1 }}
                control={
                    <Checkbox checked={isTransparent} onChange={onChangeTransparent} />
                }
                label="Transparent Background"
            />

            {
                (isTransparent) ? null : 
                    <>
                        <ListItem id={'config-list-item'} className={'config-list-item'}>
                            <FormatPaintIcon titleAccess='Background Color' />
                            <TextField id="standard-basic" className='config-menu-item-text' variant="standard"
                                size="small"
                                value={backgroundColor}
                                onClick={() => toggleBackgroundColorPicker(true)}
                                onChange={event => setBackgroundColor(event.target.value)}
                            />

                            <div style={{
                                width: '25px', height: '25px',
                                backgroundColor: backgroundColor
                            }}
                                onClick={() => toggleBackgroundColorPicker(!isBackgroundColorPickerOpen)} />
                            <br />
                        </ListItem>

                        <ListItem id={'config-list-item'} className={'config-list-item'}>
                            {isBackgroundColorPickerOpen && <HexColorPicker
                                style={{ width: 'inherit !important' }}

                                height={300}
                                color={backgroundColor}
                                onChange={color => {
                                    setBackgroundColor(color)
                                }}
                            />
                            }
                        </ListItem>
                    </>
            }

            <ListItem id={'config-list-item'} className={'config-list-item'}>
                <FormatColorTextIcon titleAccess='Layout Color' />
                <TextField id="standard-basic" className='config-menu-item-text' variant="standard"
                    size="small"
                    value={layoutColor}
                    onClick={() => toggleLayoutColorPicker(true)}
                    onChange={event => setLayoutColor(event.target.value)}
                />

                <div style={{
                    width: '25px', height: '25px',
                    backgroundColor: layoutColor
                }}
                    onClick={() => toggleLayoutColorPicker(!isLayoutColorPickerOpen)} />
                <br />
            </ListItem>

            <ListItem id={'config-list-item'} className={'config-list-item'}>
                {isLayoutColorPickerOpen && <HexColorPicker
                    style={{ width: 'inherit !important' }}

                    height={300}
                    color={layoutColor}
                    onChange={color => {
                        setLayoutColor(color)
                    }}
                />
                }
            </ListItem>
            
        </div>
}


export default FFTConfiguration