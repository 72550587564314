import React, { useEffect } from 'react';
import { Grid } from '@mui/material'

const TermsCondition = props => {

    useEffect(() => {
        document.title = 'Terms & Condition';
    }, []);

    return (
        <>
            <Grid container justifyContent='center'>
                <Grid item style={{ fontSize: '16px', maxWidth: '1000px', textAlign: 'justify', lineHeight: '24px' }}>
                    <h1 style={{ fontSize: '64px', lineHeight: '77px', textTransform: 'none'}}>Terms & Conditions</h1>

                    <h2 style={{ fontSize: '24px', lineHeight: '31px', textTransform: 'none'}} className='LindeDaxMed'>Terms and Conditions of Use for the Linde Equipment Data Acquisition System (EDAS) Motor Monitoring (MM) Web Application </h2>

                    <h3 className='LindeDaxMed' style={{ fontSize: '18px', lineHeight: '27px', textTransform: 'none', paddingBottom: '10px' }}>
                        1. Parties
                    </h3>
                    <p>
                        The Parties to this agreement are Linde GmbH, Linde Engineering, Dr.-Carl-von-Linde-Str. 6-14, 82049 Pullach/Germany (“Linde Engineering”) and natural or legal person(s) (“Customer”) who have been granted an account with the Linde Equipment Data Acquisition System (EDAS) Motor Monitoring (MM) Web Application (in the following “MM Web App”).
                    </p>

                    <br />

                    <h3 className='LindeDaxMed' style={{ fontSize: '18px', lineHeight: '27px', textTransform: 'none', paddingBottom: '10px' }}>2. Access to MM Web App</h3>
                    <p>
                        <span className='LindeDaxMed'>2.1</span> To gain access to the MM Web App, the Customer must register each employee who shall use or have access to the application on behalf of the Customer. 
                    </p>
                    <br />
                    <p>
                        <span className='LindeDaxMed'>2.1.1</span> Should the Customer have their own Azure Active Directory (Azure AD) subscription, the Azure AD E-Mail addresses of  the employees seeking access should be provided to Linde Engineering, which will enable Linde Engineering to grant access to those employees within the Azure AD framework. The user credential E-Mail address valid for the Customer’s Azure AD subscription will also serve as user credential for the MM Web App. 
                    </p>
                    <br />
                    <p>
                        <span className='LindeDaxMed'>2.1.2</span> Should the Customer not have their own Azure AD subscription, it is required to provide Linde Engineering with user details (Name, Surname, E-Mail address) for registration purposes. Linde Engineering will then issue user credentials (E-Mail address and initial password) to the user seeking access. Upon first use of the Linde Engineering-issued account, the user will be required to change the password issued by Linde Engineering to ensure confidentiality.
                    </p>
                    <br />
                    <p>
                        <span className='LindeDaxMed'>2.1.3</span> In the case that the Customer is granted with multiple User access, the Customer must designate one individual User to manage other Users’ access to the MM Web App. User Details (i.e. first name, last name, email address and password) issued are personal and must not be used by anyone other than the individual User to whom the User Details have been issued. The User Details must be kept and handled securely. In the event of lost User Details, suspected unauthorized use or a User having left his working area or no longer needs access to the MM Web App, it is the responsibility of the Customer to immediately inform Linde Engineering.
                    </p>
                    <br />
                    <p>
                        <span className='LindeDaxMed'>2.2</span> The Customer is accountable for maintaining the confidentiality of the MM Web App Account and responsible for all activities that happen under the MM Web App Account.
                    </p>

                    <br />

                    <h3 className='LindeDaxMed' style={{ fontSize: '18px', lineHeight: '27px', textTransform: 'none', paddingBottom: '10px' }}>
                        3. Termination
                    </h3>
                    <p>
                        <span className='LindeDaxMed'>3.1</span> The Customer shall suspend access to the MM Web App Account and inform Linde immediately, if the employment relationship with an authorized User ends or if access to the MM Web App by a User is no longer required for other reasons. In the case that the Customer is not managing Users’ access on their own (see section 2.1.2), the Customer must inform Linde Engineering about any such event immediately so that Linde Engineering can take appropriate actions.
                    </p>
                    <br />
                    <p>
                        <span className='LindeDaxMed'>3.2</span> The Customer shall inform Linde Engineering about any changes regarding access rights to the MM Web App.
                    </p>
                    <br />
                    <p>
                        <span className='LindeDaxMed'>3.3</span> Linde Engineering is entitled to suspend access to a MM Web App Account after twelve months of inactivity of a User.
                    </p>
                    <br />
                    <p>
                        <span className='LindeDaxMed'>3.4</span> Linde Engineering-issued accounts will be automatically disabled if:
                    </p>
                    <ol style={{ marginLeft: '50px' }}>
                        <li>The expiration date is exceeded.</li>
                        <li>A last logon can not be found and the account is older than 90 days.</li>
                        <li>The account is inactive for 180 days.</li>
                    </ol>

                    <br />

                    <h3 className='LindeDaxMed' style={{ fontSize: '18px', lineHeight: '27px', textTransform: 'none', paddingBottom: '10px' }}>
                        4. Privacy
                    </h3>
                    <p>
                        <span className='LindeDaxMed'>4.1</span> All personal data is processed in accordance with applicable data protection laws.
                    </p>
                    <br />
                    <p>
                        <span className='LindeDaxMed'>4.2</span> The Customer shall inform its Users about the processing of personal Data in the context of the MM Web App. Linde Engineering as well as the Customer will be separately responsible for answering User requests due to the processing of personal data within the MM Web App. Where disclosure of requested information may affect another partner consultation will take place.
                    </p>
                    <br />
                    <p>
                        <span className='LindeDaxMed'>4.3</span> The legal basis for the processing of personal data in the context of the MM Web App is Art. 6 (1) lit. b EU-GDPR (The processing of personal data is necessary for the performance of a contract).
                    </p>
                    <br />
                    <p>
                        <span className='LindeDaxMed'>4.4</span> For more details, please see the separate MM Web App Data Privacy Notice, to which Linde hereby expressly refers and can be viewed via the corresponding link in the MM Web App.
                    </p>

                    <br />

                    <h3 className='LindeDaxMed' style={{ fontSize: '18px', lineHeight: '27px', textTransform: 'none', paddingBottom: '10px' }}>
                        5. Scope of right to use
                    </h3>
                    <p>
                        <span className='LindeDaxMed'>5.1</span> The Customer as well as the User are permitted to use the MM Web App only in accordance with these terms and conditions and any relevant law or regulation in their particular jurisdiction. In particular the User must not use the MM Web App for any fraudulent or unlawful purpose.
                    </p>
                    <br />
                    <p>
                        <span className='LindeDaxMed'>5.2</span> In the event that a Customer has reason to believe that a MM Web App Account has been used unauthorized, the Customer must suspend access and to inform Linde immediately.
                    </p>

                    <br />

                    <h3 className='LindeDaxMed' style={{ fontSize: '18px', lineHeight: '27px', textTransform: 'none', paddingBottom: '10px' }}>
                        6. No Warranties
                    </h3>
                    <p>
                        Linde Engineering makes the MM Web App available in its existing condition. In particular, Linde Engineering does not give express or implied warranties, or representations, that
                    </p>

                    <ul style={{ marginLeft: '50px' }}>
                        <li>the MM Web App is available without interruption</li>
                        <li>the use of the MM Web App is free from errors or is complete, accurate or up to date at all times.</li>
                    </ul>

                    <br />

                    <h3 className='LindeDaxMed' style={{ fontSize: '18px', lineHeight: '27px', textTransform: 'none', paddingBottom: '10px' }}>
                        7. Liability
                    </h3>
                    <p>
                        Linde Engineering develops and maintains the MM Web App carefully according to the current state of the art and in collaboration with renowned partners. Nevertheless, Linde is not in a position to be responsible for all imponderables in connection with an IT system. In particular, Linde cannot guarantee that the system will be always available or error-free at all times. Whereas Linde Engineering does not limit its liability under statutory law caused by willful intent or Gross Negligence of Linde Engineering`s directors or its senior management.
                    </p>
                    <br />
                    <p>
                        Notwithstanding anything contained in this agreement, in no event shall Linde Engineering be liable to Customer by way of indemnity or by reason of negligence or breach of contract or in tort or otherwise for loss of production or products, loss of profits, loss of use, loss of contracts, increased cost of operation, maintenance or staffing needs, any other financial or economic loss, or any indirect, incidental, special, punitive or consequential damages of any description and howsoever arising in connection with the agreement. 
                    </p>
                    <br />
                    <p>
                        Gross Negligence means any act or failure to act by Linde Engineering, which seriously and substantially deviates from a reasonable course of action without justification and which is in reckless disregard of or wanton indifference to foreseeable harmful consequences.
                    </p>

                    <br />

                    <h3 className='LindeDaxMed' style={{ fontSize: '18px', lineHeight: '27px', textTransform: 'none', paddingBottom: '10px' }}>
                        8. Final Provisions
                    </h3>
                    <p>
                        <span className='LindeDaxMed'>8.1</span> Any other provisions of the Agreement notwithstanding, Linde Engineering shall have the right to terminate the Customer’s access to the MM Web App at any time without having to observe an advance notice period if the Customer should violate any provision of this Terms and Conditions in a material manner.
                    </p>
                    <br />
                    <p>
                        <span className='LindeDaxMed'>8.2</span> In addition Linde Engineering expressly refers to the respective Sales and License Conditions which have been concluded between the specific Customer and Linde Engineering.
                    </p>
                </Grid>
                
            </Grid>
        </>
    );
};

export default TermsCondition;