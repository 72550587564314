
import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    ListItem,
    TextField,
    ListItemText
} from '@mui/material';
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { ApiEndpoint } from 'config'
import {
    Title as TitleIcon,
    Key as KeyIcon,
    Description as DescriptionIcon
} from "@mui/icons-material";
import { GetAllNavigation } from "actions";



const CreateConfirmationDialog = (
    {
        createConfig,
        open, setOpen,
        authentication,
        showNotification,
        ...props }) => {

    useEffect(() => {
        setPageTitle(createConfig?.title)
        setPageDescription(createConfig?.description)

    }, [createConfig])


    const [loading, setLoading] = useState(false)
    const [pageTitle, setPageTitle] = useState(createConfig?.title)
    const [pageDescription, setPageDescription] = useState(createConfig?.description)

    const handleClose = () => {
        setOpen();
    };


    const confirmAddPage = () => {

        setLoading(true)
        if (createConfig?.type === 'parent') {

            axios.post(`${ApiEndpoint.Page.CreateParentPage}`, {
                title: pageTitle,
                description: pageDescription,
                pageIcon: 'business'
            }, {
                headers: {
                    'Authorization': `bearer ${authentication.token.accessToken}`
                }
            }).then(resp => {

                showNotification('Page Created', 'success')


                createConfig.createPage({
                    id: resp.data?.id,
                    title: resp.data?.title,
                    description: resp.data?.description,
                    cards: []
                })
                setOpen();
            }).catch(err => {

                console.log(err.response?.data)
                showNotification(err.response?.data?.error ?? err.response?.data?.title, 'danger')
            }).finally(() => {
                setLoading(false)
            })
        } else if (createConfig?.type === 'children') {

            console.debug(createConfig)
            console.debug(pageTitle)
            console.debug(pageDescription)

            axios.post(`${ApiEndpoint.Page.CreateChildrenPage}`, {
                title: pageTitle,
                pageParentId: createConfig.parentId,
                description: pageDescription,
                pageIcon: 'assessment'
            }, {
                headers: {
                    'Authorization': `bearer ${authentication.token.accessToken}`
                }
            }).then(resp => {

                showNotification('Page Created', 'success')


                createConfig.createPage({
                    id: resp.data?.id,
                    title: resp.data?.title,
                    description: resp.data?.description,
                    cards: []
                })
                setOpen();
            }).catch(err => {

                console.log(err.response?.data)
                showNotification(err.response?.data?.error ?? err.response?.data?.title, 'danger')
            }).finally(() => {
                setLoading(false)
            })
        }
    }

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {`${createConfig.type} Page Creation`}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>

                    <ListItem>
                        <KeyIcon />
                        <ListItemText
                            style={{ paddingLeft: 10 }}>
                            {createConfig.id ? createConfig.id : `Page ID will be assigned automatically once it created`}
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <TitleIcon />
                        <TextField style={{ paddingLeft: 10 }} fullWidth
                            className='config-menu-item-text'
                            placeholder="Please provide the title for the page"
                            variant="standard"
                            value={pageTitle} onChange={e => setPageTitle(e.target.value)}
                            size="small" />
                    </ListItem>


                    <ListItem>
                        <DescriptionIcon />
                        <TextField style={{ paddingLeft: 10 }} fullWidth
                            className='config-menu-item-text'
                            placeholder="Please provide the description for the page"
                            variant="standard"
                            value={pageDescription} onChange={e => setPageDescription(e.target.value)}
                            size="small" />
                    </ListItem>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <LoadingButton loading={loading} color="info" onClick={confirmAddPage} autoFocus>
                    Confirm Add
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}


export default CreateConfirmationDialog;