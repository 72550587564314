
import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from '@mui/material';
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { ApiEndpoint } from 'config'
const DeleteConfirmationDialog = (
    {
        deleteConfig,
        open, setOpen,
        authentication,
        showNotification,
        ...props }) => {

    const [loadingDelete, setLoadingDelete] = useState(false)

    const handleClose = () => {
        setOpen();
    };

    const confirmRemovePage = () => {
        setLoadingDelete(true)
        axios.delete(`${ApiEndpoint.Page.DeletePage}/${deleteConfig.id}`, {
            headers: {
                "Authorization": "bearer " + authentication?.token?.accessToken
            }
        }).then(resp => {
            showNotification('Page Deleted Successfully', 'success')
            deleteConfig.removeColumn()
            setOpen()
        }).catch(err => {
            console.debug(err.response)
            showNotification(err.response?.data?.error ?? err.response?.data?.title, 'danger')
        }).finally(() => {
            setLoadingDelete(false)
        })
    }

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {`Delete the ${deleteConfig.type} Page ?`}
            </DialogTitle>
            <DialogContent>
                <DialogContentText >
                    {`${deleteConfig.title} - ${deleteConfig.id}.`}
                </DialogContentText>
                {
                    deleteConfig.type === 'parent' ? 
                    `Deleting the parent page will delete all its sub children and their own widgets as well. Please delete with caution`: 
                    `Deleting the child page will delete their respectively widgets as well. Please delete with caution `
                }
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose}>CANCEL</Button>
                <LoadingButton loading={loadingDelete} color="error" onClick={confirmRemovePage} autoFocus>
                    DELETE
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}


export default DeleteConfirmationDialog;