import React, { useEffect } from 'react';
import { Grid } from '@mui/material'

const PrivacyPolicy = props => {

    useEffect(() => {
        document.title = 'Privacy Policy';
    }, []);

    return (
        <>
            <Grid container justifyContent='center'>
                <Grid item style={{ fontSize: '16px', maxWidth: '1000px', lineHeight: '24px' }}>
                    <h1 style={{ fontSize: '64px', lineHeight: '77px', textTransform: 'none'}}>Privacy Policy</h1>

                    <h2 style={{ fontSize: '24px', lineHeight: '31px', textTransform: 'none'}} className='LindeDaxMed'>Data Privacy Notice for the Linde Equipment Data Acquisition System (EDAS) Motor Monitoring (MM) Web Application</h2>

                    <p>
                    With the following data privacy notice, we would like to inform you about the processing of your personal data by Linde GmbH | Linde Engineering, Dr.-Carl-von-Linde-Strasse 6-14, 82049 Pullach, Germany (in the following “Linde Engineering”) for the purpose of the Linde <span className='LindeDaxMed'>Equipment Data Acquisition System (EDAS) Motor Monitoring (MM) Web Application</span> (in the following “MM Web App”).
                    </p>

                    <br />

                    <p>
                    From time to time, it may be necessary to adapt this privacy policy as whole as well as specific parts of it to comply with the up to date legal requirements or to cover the introduction of new services.
                    </p>

                    <br />

                    <h2 style={{ fontSize: '24px', lineHeight: '31px', textTransform: 'none'}} className='LindeDaxMed'>General Information regarding Data Protection at Linde Engineering</h2>

                    <h3 className='LindeDaxMed' style={{ fontSize: '18px', lineHeight: '27px', textTransform: 'none', paddingBottom: '10px' }}>
                        Controller and Data Protection Officer
                    </h3>

                    <p>
                        The controller responsible for the processing of your personal data in the sense of data protection law is Linde GmbH | Linde Engineering 
                    </p>
                    <br />
                    <p>
                        The contact details of the EU Group Data Protection Officer (“EU GDPO”) of Linde GmbH are:
                    </p>
                    <br />
                    <p>
                        Linde GmbH
                    </p>
                    <p>
                        EU Group Data Protection Officer
                    </p>
                    <p>
                        Dr.-Carl-von-Linde-Strasse 6-14, 82049 Pullach, Germany
                    </p>
                    <p>
                        Email: dataprotection@linde.com
                    </p>
                    <br />
                    <p>
                        The Linde Engineering Team is also available to answer all your questions regarding data protection at Linde Engineering by E-mail. You can contact the Team under the following address: 
                    </p>
                    <br />
                    <p className='LindeDaxMed'>
                        LAOS.RDS@PROCORR.LINDE.COM
                    </p>

                </Grid>
                
            </Grid>
        </>
    );
};

export default PrivacyPolicy;