import { useEffect, useState } from 'react';
import axios from 'axios'
import Plotly from 'plotly.js-dist-min';
import createPlotlyComponent from 'react-plotly.js/factory';
import moment from 'moment';
import { ApiEndpoint, KUSTO_CLUSTER, KUSTO_DATABASE, KUSTO_TABLE } from 'config';
import { BAR_CONFIG } from './config';
import Loader from 'components/loader';

const Plot = createPlotlyComponent(Plotly);

const Bar = ({ id, pageName, widgetRefreshData, showNotification, authentication, width, height, 
    config, titleX, titleY, isTransparent, layoutColor, backgroundColor, tagList, isFill, selectedTags, 
    chartOpacity, kustoToken, legendOrientation, gridLines, orientation, ...props }) => {
    
    const [isLoading, setIsLoading] = useState(true)
    const [revision, setRevision] = useState(0)
    const [chartData, setChartData] = useState([])

    const [currentSelectedTags, setCurrentSelectedTags] = useState(selectedTags)

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        if (currentSelectedTags?.toString() !== selectedTags?.toString() && tagList !== undefined && tagList.length > 0) {
            setCurrentSelectedTags(selectedTags)
            getData()
        }
    }, [tagList])

    useEffect(() => {
        let _chartData = chartData.slice()

        for (let i=0; i<_chartData.length; i++) {
            _chartData[i].fill = (isFill) ? 'tozeroy' : null
        }
        setChartData(_chartData)
        setRevision(revision+1)
    }, [isFill])

    useEffect(() => {
        console.log('DateTime affected getData')
        getData()
    }, [props?.global?.startDateTime, props?.global?.endDateTime])

    useEffect(() => {
        let _chartData = chartData.slice()

        for (let i=0; i<_chartData.length; i++) {
            _chartData[i].opacity = chartOpacity
        }

        setChartData(_chartData)
    }, [chartOpacity])

    useEffect(() => {
        setRevision(revision+1)
    }, [chartData])

    useEffect(() => {
        let _chartData = chartData.slice()

        if (_chartData.length > 0) {
            console.log('CD more than 0')

            for (let i=0; i<_chartData.length; i++) {
                let newX = _chartData[i].y
                let newY = _chartData[i].x
                _chartData[i].x = newX
                _chartData[i].y = newY
                _chartData[i].orientation = orientation
            }

            setChartData(_chartData)
        }
        else {
            console.log('CD is 0, do nothing')
        }
    }, [orientation])

    const getData = async () => {
        setIsLoading(true)
        console.log('getData() Called')

        if (selectedTags !== undefined) {
            console.log('selectedTags is not empty')
            for (let i=0; i<selectedTags.length; i++) {
                let idx = tagList.findIndex(x => x.id === selectedTags[i])
    
                if (idx !== undefined) {
                    tagList[idx].isSelected = true
                }
            }
        }
        
        let _chartData = []
        let selectedTagList = tagList?.filter(x => x.isSelected === true)

        if (selectedTagList?.length > 0) {
            console.log('selectedTags is not empty')

            for (let x=0; x<selectedTagList.length; x++) {
                console.log('Calling api...')
                await axios.get(`${ApiEndpoint.Kusto.GetValuesBetweenTimestamps}`, {
                    params: {
                        TagName: selectedTagList[x].name,
                        StartUTCTimestamp: moment(props?.global?.startDateTime).format('YYYY-MM-DDTHH:mm:ss'),
                        EndUTCTimestamp: moment(props?.global?.endDateTime).format('YYYY-MM-DDTHH:mm:ss'),
                        Cluster: KUSTO_CLUSTER,
                        Database: KUSTO_DATABASE,
                        Table: KUSTO_TABLE,
                        Count: 1000
                    },
                    headers: {
                        'authorization': `bearer ${kustoToken}`
                    }
                }).then(resp => {
                    let data = resp.data
                    console.log(`Receive ${data.length} tags > `, data)
                    let timestamps = []
                    let values = []

                    for (let i=0; i<data.length; i++) {
                        timestamps.push(data[i].UTCTimeStamp)
                        values.push(data[i].Value)
                    }
                
                    _chartData.push({
                        x: (orientation===BAR_CONFIG.Orientation[0]) ? timestamps : values,
                        y: (orientation===BAR_CONFIG.Orientation[0]) ? values : timestamps,
                        type: 'bar',
                        name: selectedTagList[x].description,
                        mode: 'lines+points', //Options: "lines", "markers", "lines+markers", "lines+markers+text", "none"
                        // fill: (isFill) ? 'tozeroy' : null,
                        stackgroup: null,
                        opacity: chartOpacity,
                        orientation: orientation
                    })
                }).catch(err => {
                    console.log(err)
                    showNotification(err.response?.data?.error ?? err.response?.data?.title, 'danger')
                })
            }

            setChartData(_chartData)
        }
        else {
            setChartData([])
        }

        setIsLoading(false)
    }

    useEffect(() => {
        console.log('Page Name change to ', pageName)
    }, [pageName])

    useEffect(() => {
        console.debug(`Refreshing page ${id}`)
        if (true) {
            getData()
            showNotification('Refresh success!', 'success')
        }
    }, [widgetRefreshData])

    const hexToRgba = (hex) => {
        var c;
        if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
            c= hex.substring(1).split('');
            if(c.length== 3){
                c= [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c= '0x'+c.join('');
            return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',0.8)';
        }
        throw new Error('Bad Hex');
    }

    return <div style={{ backgroundColor: (isTransparent) ? 'transparent' : backgroundColor }}>
        {
            (isLoading) ? 
                <Loader /> :
                <>
                    <Plot useResizeHandler
                        data={chartData}
                        layout={{
                            barmode: null,
                            paper_bgcolor: 'transparent',
                            plot_bgcolor: 'transparent',
                            datarevision: revision,
                            uirevision: revision,
                            width: width,
                            height: (height-10),
                            showlegend: (legendOrientation===null) ? false : true,
                            legend: {
                                orientation: legendOrientation,
                                font: {
                                    color: hexToRgba('#005591')
                                }
                            },
                            xaxis: {
                                autotick: true,
                                type: 'string',
                                title: {
                                    text: (selectedTags?.length > 1) ? ((legendOrientation !== BAR_CONFIG.Legend[1]) ? titleX : '') : titleX
                                },
                                color: layoutColor,
                                showspikes: true,
                                spikemode: 'toaxis+across+marker',
                                spikesnap: 'hovered data',
                                showline: false,
                                showgrid: (gridLines===BAR_CONFIG.GridLines[0] || gridLines===BAR_CONFIG.GridLines[1]) ? true : false,
                                spikedash: 'solid',
                                spikethickness: 1,
                            },
                            yaxis: {
                                autotick: true,
                                type: 'string',
                                title: titleY,
                                color: layoutColor,
                                showspikes: true,
                                spikemode: 'toaxis+across+marker',
                                spikesnap: 'cursor',
                                showline: true,
                                showgrid: (gridLines===BAR_CONFIG.GridLines[0] || gridLines===BAR_CONFIG.GridLines[2]) ? true : false,
                                spikedash: 'solid',
                                spikethickness: 1
                            },
                            hovermode: 'x unified',
                            hoverlabel: {
                                bgcolor: 'white'
                            },
                        }}
                    />
                </>
        }
    </div>
};

export default Bar;


