import { useEffect, useState } from 'react';
import { HexColorPicker } from "react-colorful";

import {
    ListItem,
    TextField,
    FormControlLabel,
    Checkbox,
    FormControl,
    InputLabel,
    Select,
    Box,
    Chip,
    MenuItem,
    ListItemText,
    Input,
    ButtonGroup,
    Button,
    ListSubheader,
    Divider
} from '@mui/material'

import {
    FormatSize as FormatSizeIcon,
    Title as TitleIcon,
    FormatPaint as FormatPaintIcon,
    Link as LinkIcon,
    Palette as PaletteIcon
} from '@mui/icons-material'

const BUTTON_CONFIG = {
    variant: ['text', 'contained', 'outlined'],
    targetLocation: ['_blank', '_parent']
}

const ButtonConfiguration = ({ id, title,
    isConfigDrawerOpen, toggleConfigDrawer, widgetAdvanceSettings,
    setWidgetAdvanceSettings,
    showNotification,
    ...props }) => {

    const [dashboardId, setDashboardId] = useState(widgetAdvanceSettings?.button?.dashboardId)

    const [isTransparent, setIsTransparent] = useState((widgetAdvanceSettings?.button?.isTransparent !== undefined) ? widgetAdvanceSettings?.button?.isTransparent : true)
    
    const [layoutColor, setLayoutColor] = useState(widgetAdvanceSettings?.button?.layoutColor ? widgetAdvanceSettings?.button?.layoutColor : '#005591')
    const [isLayoutColorPickerOpen, toggleLayoutColorPicker] = useState(false);
    
    const [backgroundColor, setBackgroundColor] = useState(widgetAdvanceSettings?.button?.backgroundColor ? widgetAdvanceSettings?.button?.backgroundColor : '#FFFFFF')
    const [isBackgroundColorPickerOpen, toggleBackgroundColorPicker] = useState(false);

    const [buttonText, setButtonText] = useState(widgetAdvanceSettings?.button?.buttonText ? widgetAdvanceSettings?.button?.buttonText : 'Click Me')

    const [buttonVariant, setButtonVariant] = useState(widgetAdvanceSettings?.button?.buttonVariant ? widgetAdvanceSettings?.button?.buttonVariant : BUTTON_CONFIG.variant[1]);
    const [targetLocation, setTargetLocation] = useState(widgetAdvanceSettings?.button?.targetLocation ? widgetAdvanceSettings?.button?.targetLocation : BUTTON_CONFIG.targetLocation[0]);
    const [href, setHref] = useState(widgetAdvanceSettings?.button?.href ? widgetAdvanceSettings?.button?.href : '')
    const [isDisable, setIsDisable] = useState(widgetAdvanceSettings?.button?.isDisable ? widgetAdvanceSettings?.button?.isDisable : false)
    const [fontSize, setFontSize] = useState(widgetAdvanceSettings?.button?.fontSize ? widgetAdvanceSettings?.button?.fontSize : 12)
    
    useEffect(() => {
        // getAvailableTags()
    }, [])

    useEffect(() => {
        setWidgetAdvanceSettings({
            ...widgetAdvanceSettings,
            button: {
                buttonText: buttonText,
                layoutColor: layoutColor,
                backgroundColor: backgroundColor,
                isTransparent: isTransparent,
                buttonVariant: buttonVariant,
                targetLocation: targetLocation,
                href: href,
                isDisable: isDisable,
                fontSize: fontSize
            }
        })
    }, [buttonText, layoutColor, backgroundColor, isTransparent, buttonVariant, targetLocation, href, isDisable, fontSize])

    const onChangeTransparent = () => {
        setIsTransparent(!isTransparent)
    }

    return <div>
        <ListItem id={'config-list-item'} className={'config-list-item'}>
            <TitleIcon />
            <TextField className='config-menu-item-text'
                fullWidth
                label={'Text'}
                variant='standard'
                defaultValue={buttonText}
                onChange={event => setButtonText(event.target.value)} />
        </ListItem>

        <ListItem id={'config-list-item'} className={'config-list-item'}>
            <LinkIcon titleAccess='Target Location'/>
            <TextField className='config-menu-item-text'
                fullWidth
                label={'URL'}
                variant='standard'
                defaultValue={href}
                onChange={event => setHref(event.target.value)} />
        </ListItem>

        <FormControlLabel sx={{ color: '#005591', fontFamily: 'LindeDax, Arial, sans-serif', marginLeft: 1 }}
            control={
                <Checkbox checked={isDisable} onChange={() => setIsDisable(!isDisable)} />
            }
            label="Disable Button"
        />

        <br />

        <FormControlLabel sx={{ color: '#005591', fontFamily: 'LindeDax, Arial, sans-serif', marginLeft: 1 }}
            control={
                <Checkbox checked={isTransparent} onChange={onChangeTransparent} />
            }
            label="Set Transparent Background"
        />

        {
            (isTransparent) ? <br /> :
                <>
                    <ListItem id={'config-list-item'} className={'config-list-item'}>
                        <FormatPaintIcon titleAccess='Background Color' />
                        <TextField id="standard-basic" className='config-menu-item-text' variant="standard"
                            size="small"
                            value={backgroundColor}
                            onClick={() => toggleBackgroundColorPicker(true)}
                            onChange={event => setBackgroundColor(event.target.value)}
                        />

                        <div style={{
                            width: '25px', height: '25px',
                            backgroundColor: backgroundColor
                        }}
                            onClick={() => toggleBackgroundColorPicker(!isBackgroundColorPickerOpen)} />
                        <br />
                    </ListItem>

                    <ListItem id={'config-list-item'} className={'config-list-item'}>
                        {isBackgroundColorPickerOpen && <HexColorPicker
                            style={{ width: 'inherit !important' }}
                            height={300}
                            color={backgroundColor}
                            onChange={color => {
                                setBackgroundColor(color)
                            }}
                        />
                        }
                    </ListItem>
                </>
        }

        <ListItem id={'config-list-item'} className={'config-list-item'}>
            <FormatSizeIcon />
            <TextField className='config-menu-item-text'
                fullWidth
                label={'Font Size'}
                variant='standard'
                type={'number'}
                InputProps={{ inputProps: { min: 1, max: 999, step: '1' }}}
                defaultValue={fontSize}
                onChange={event => setFontSize(event.target.value)} />
        </ListItem>

        <ListItem id={'config-list-item'} className={'config-list-item'}>
            <PaletteIcon titleAccess='Button Color' />
            <TextField id="standard-basic" className='config-menu-item-text' variant="standard"
                size="small"
                value={layoutColor}
                onClick={() => toggleLayoutColorPicker(true)}
                onChange={event => setLayoutColor(event.target.value)}
            />

            <div style={{
                width: '25px', height: '25px',
                backgroundColor: layoutColor
            }}
                onClick={() => toggleLayoutColorPicker(!isLayoutColorPickerOpen)} />
            <br />
        </ListItem>

        <ListItem id={'config-list-item'} className={'config-list-item'}>
        {isLayoutColorPickerOpen && <HexColorPicker
            style={{ width: 'inherit !important' }}
            height={300}
            color={layoutColor}
            onChange={color => {
                setLayoutColor(color)
            }}
        />
        }
        </ListItem>

        <br />
        <Divider variant='middle' />

        <ListSubheader style={{ color: '#00A0E1' }} disableSticky={true}>
            Form Target
        </ListSubheader>

        <ListItem id={'config-list-item'} className={'config-list-item'}>
            <ButtonGroup variant="outlined" aria-label="outlined button group">
                <Button className={(targetLocation === BUTTON_CONFIG.targetLocation[0]) ? 'is-active' : ''} onClick={() => setTargetLocation(BUTTON_CONFIG.targetLocation[0])}>New Tab</Button>
                <Button className={(targetLocation === BUTTON_CONFIG.targetLocation[1]) ? 'is-active' : ''} onClick={() => setTargetLocation(BUTTON_CONFIG.targetLocation[1])}>Current Page</Button>
            </ButtonGroup>
        </ListItem>

        <br />
        <Divider variant='middle' />

        <ListSubheader style={{ color: '#00A0E1' }} disableSticky={true}>
            Layout
        </ListSubheader>

        <ListItem id={'config-list-item'} className={'config-list-item'}>
            <ButtonGroup variant="outlined" aria-label="outlined button group">
                <Button className={(buttonVariant === BUTTON_CONFIG.variant[0]) ? 'is-active' : ''} onClick={() => setButtonVariant(BUTTON_CONFIG.variant[0])}>Text</Button>
                <Button className={(buttonVariant === BUTTON_CONFIG.variant[1]) ? 'is-active' : ''} onClick={() => setButtonVariant(BUTTON_CONFIG.variant[1])}>Contained</Button>
                <Button className={(buttonVariant === BUTTON_CONFIG.variant[2]) ? 'is-active' : ''} onClick={() => setButtonVariant(BUTTON_CONFIG.variant[2])}>Outlined</Button>
            </ButtonGroup>
        </ListItem>

        <br />
        
    </div>
}


export default ButtonConfiguration