import { useState, useEffect } from 'react'

import {
    Select,
    MenuItem,
    InputLabel
} from '@mui/material'

import { GetSnapshots } from '../../../../../actions'

const GrafanaConfiguration = ({ id, title,
    isConfigDrawerOpen, 
    toggleConfigDrawer, 
    widgetAdvanceSettings,
    setWidgetAdvanceSettings,
    showNotification,
    ...props })=>{
    
    const [snapshots, setSnapshots] = useState([])
    const [snapshotKey, setSnapshotKey] = useState(widgetAdvanceSettings?.grafana?.snapshotKey)
    //const [snapshotName, setSnapshotName] = useState(widgetAdvanceSettings?.grafana?.snapshotName)

    useEffect(() => {
        GetSnapshots(setSnapshots)
    }, [])

    useEffect(() => {
        setWidgetAdvanceSettings({
            ...widgetAdvanceSettings,
            grafana: {
                snapshotKey: snapshotKey,
            }
        })
    }, [snapshotKey])

    const setSelectedSnapshotFunc = async (dashboardKey) => {
        setSnapshotKey(dashboardKey)
    }

    return <div>
        <InputLabel id="selectSnapshot">Select Snapshoot</InputLabel>
        <Select
            value={widgetAdvanceSettings?.grafana?.snapshotKey == null ? '' : widgetAdvanceSettings?.grafana?.snapshotKey}
            variant='standard'
            size='small'
            fullWidth={true}
             onChange={(e) => setSelectedSnapshotFunc(e.target.value)
            }
            >
                {snapshots.map(snapshot => {
                    return <MenuItem 
                    key={snapshot.id} 
                    value={snapshot.key}
                    >
                        {snapshot.name}
                    </MenuItem>    
                })}
            </Select>
           
    </div>
}

export default GrafanaConfiguration