import { useParams } from "react-router-dom";
import PowerBi from 'components/widgets/powerbi'




const PBIReport = ({...props}) => {


    let params = useParams();
    
    let workspaceId = params?.workspaceId;
    let reportId = params?.reportId;
    let pageName = params?.pageId;


    return <div style={{ height: '82vh' }}>
        <PowerBi workspaceId={workspaceId} reportId={reportId} pageName={pageName} width={'inherit'} height={'inherit'} {...props} />

    </div>

}



export default PBIReport;
