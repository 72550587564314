import { Snackbar, Alert } from '@mui/material'



const AlertNotification = ({
    notification, 
    ...props}) => {
    var Severity = "info"


    if (notification.intent === 'danger') {
        Severity = "error"
    }
    else if (notification.intent === 'warning') {
        Severity = "warning"
    }
    else if (notification.intent === 'success') {
        Severity = "success"
    }
    else if (notification.intent === 'primary') {
        Severity = "info"
    }
    else if (notification.intent === 'none') {
        Severity = "info"
    } else {
        Severity = "info"
    }

    return (
        <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={notification.showNotification}
            autoHideDuration={3000}
            onClose={props.closeNotification}>
            <Alert
                onClose={notification.closeNotification}
                severity={Severity}
                sx={{ width: '100%' }}>
                {notification.notificationMessage}
            </Alert>
        </Snackbar>


    );
}

export default AlertNotification;