
import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    ListItem,
    TextField,
    ListItemText
} from '@mui/material';
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { ApiEndpoint } from 'config'
import {
    Title as TitleIcon,
    Key as KeyIcon,
    Description as DescriptionIcon
} from "@mui/icons-material";
import { GetAllNavigation } from "actions";



const EditConfirmationDialog = (
    {
        editConfig,
        open, setOpen,
        authentication,
        showNotification,
        ...props }) => {

    useEffect(() => {
        setPageTitle(editConfig?.title)
        setPageDescription(editConfig?.description)

    }, [editConfig])


    const [loading, setLoading] = useState(false)
    const [pageTitle, setPageTitle] = useState(editConfig?.title)
    const [pageDescription, setPageDescription] = useState(editConfig?.description)

    const handleClose = () => {
        setOpen();
    };

    const confirmEditPage = () => {
        
        setLoading(true)
        axios.patch(`${ApiEndpoint.Page.UpdatePageBasic}/${editConfig.id}`, [
            {
                "op":"replace",
                "path": "/Title",
                "value": pageTitle
            },
            {
                "op":"replace",
                "path": "/Description",
                "value": pageDescription
            },
        ]
        , {
            headers: {
                "Authorization": "bearer " + authentication?.token?.accessToken
            }
        }).then(resp => {
            editConfig.renameColumn(pageTitle, pageDescription)

            showNotification('Page Updated', 'success')

            GetAllNavigation()

            setOpen()
        }).catch(err => {
            console.debug(err.response)
            showNotification(err.response?.data?.error ?? err.response?.data?.title, 'danger')
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {`Page Configuration`}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>

                    <ListItem>
                        <KeyIcon />
                        <ListItemText style={{ paddingLeft: 10 }}> {editConfig.id} </ListItemText>
                    </ListItem>
                    <ListItem>
                        <TitleIcon />
                        <TextField style={{ paddingLeft: 10 }} fullWidth
                            className='config-menu-item-text'
                            variant="standard"
                            value={pageTitle} onChange={e => setPageTitle(e.target.value)}
                            size="small" />
                    </ListItem>


                    <ListItem>
                        <DescriptionIcon />
                        <TextField style={{ paddingLeft: 10 }} fullWidth
                            className='config-menu-item-text'
                            variant="standard"
                            value={pageDescription} onChange={e => setPageDescription(e.target.value)}
                            size="small" />
                    </ListItem>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <LoadingButton loading={loading} color="info" onClick={confirmEditPage} autoFocus>
                    Update Settings
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}


export default EditConfirmationDialog;