
import { useEffect, useState } from 'react';
import { HexColorPicker } from "react-colorful";




import {
    ListItem,
    ListItemText,
    TextField,
    Select,
    MenuItem,
    Collapse,
    Autocomplete,
    FormControlLabel,
    Checkbox
} from '@mui/material'

import { 
    Widgets as WidgetsIcon, 
    Key as KeyIcon,
    Title as TitleIcon, 
    FormatColorText as FormatColorTextIcon, 
    FormatSize as FormatSizeIcon,
    FormatPaint as FormatPaintIcon,
    SubtitlesOff as SubtitlesOffIcon 
} from '@mui/icons-material'


import WidgetTypes from 'pages/dashboard/graph-types'



const WidgetGeneralConfig = ({ id, title, isConfigDrawerOpen, toggleConfigDrawer, isGeneralOpen,
    widgetGeneralSettings, setWidgetGeneralSettings, ...props }) => {


    
    const [showTitle, toggleTitle] = useState(widgetGeneralSettings?.showTitle)
    const [titleName, setTitleName] = useState(widgetGeneralSettings.title)
    const [titleFontSize, setTitleFontSize] = useState(widgetGeneralSettings.fontSize)
    const [titleFontSizeUnit, setTitleFontSizeUnit] = useState(widgetGeneralSettings.fontUnit)
    const [titleFontColor, setTitleFontColor] = useState(widgetGeneralSettings.fontColor);
    const [isTitleFontColorPickerOpen, toggleTitleFontColorPicker] = useState(false);

    const [titleBackgroundColor, setTitleBackgroundColor] = useState(widgetGeneralSettings.titleBackground);
    const [isTitleBgColorPickerOpen, toggleTitleBgColorPicker] = useState(false);

    const [widgetType, setWidgetType] = useState(widgetGeneralSettings.type);

    useEffect(() => {

        setWidgetGeneralSettings({
            ...widgetGeneralSettings,
            showTitle: showTitle,
            type: widgetType,
            title: titleName,
            fontSize: titleFontSize,
            fontUnit: titleFontSizeUnit,
            fontColor: titleFontColor,
            titleBackground: titleBackgroundColor,
        })

    }, [titleName, titleFontSize, titleFontSizeUnit, titleFontColor, titleBackgroundColor, widgetType, showTitle])



    return <Collapse in={isGeneralOpen} timeout="auto" unmountOnExit >

        <ListItem id={'config-list-item'} className={'config-list-item'}>
            <KeyIcon />
            <ListItemText primary={id} className='config-menu-item-text' />
        </ListItem>

        <ListItem id={'config-list-item'} className={'config-list-item'}>
            <TitleIcon />
            <TextField id="standard-basic" className='config-menu-item-text' variant="standard" size="small"
                defaultValue={titleName}
                fullWidth={true}
                onChange={(val) => setTitleName(val.target.value)} />

            <FormatSizeIcon />
            <TextField id="standard-basic"
                className='config-menu-item-text'
                variant="standard"
                size={'small'}

                fullWidth={false}
                defaultValue={titleFontSize}
                onChange={(val) => setTitleFontSize(val.target.value)} />
            <Select
                value={titleFontSizeUnit}
                variant="standard"
                size='small'
                fullWidth={true}
                onChange={(val) => setTitleFontSizeUnit(val.target.value)} >
                <MenuItem value={'px'}>px</MenuItem>
                <MenuItem value={'%'}>%</MenuItem>
                <MenuItem value={'em'}>em</MenuItem>
                <MenuItem value={'rem'}>rem</MenuItem>
            </Select>
        </ListItem>

        <ListItem id={'config-list-item'} className={'config-list-item'}>

            <FormatColorTextIcon titleAccess='Font Color' />
            <TextField id="standard-basic" className='config-menu-item-text' variant="standard"
                size="small"
                value={titleFontColor}
                onClick={() => toggleTitleFontColorPicker(true)}
                onChange={event => setTitleFontColor(event.target.value)}
            />

            <div style={{
                width: '25px', height: '25px',
                backgroundColor: titleFontColor
            }}
                onClick={() => toggleTitleFontColorPicker(!isTitleFontColorPickerOpen)} />
            <br />
        </ListItem>

        <ListItem id={'config-list-item'} className={'config-list-item'}>
            <SubtitlesOffIcon />
            <FormControlLabel sx={{ color: 'black', fontFamily: 'LindeDax, Arial, sans-serif', marginLeft: 1, flexDirection: 'row-reverse' }}
                control={<Checkbox checked={showTitle} onChange={() => toggleTitle(!showTitle)} />}
                label="Enable Title" />
        </ListItem>

        <ListItem id={'config-list-item'} 
        className={'config-list-item'}>
            {isTitleFontColorPickerOpen && <HexColorPicker
                style={{ width: 'inherit !important' }}

                height={300}
                color={titleFontColor}
                onChange={color => {
                    setTitleFontColor(color)
                }}
            />
            }
        </ListItem>

        <ListItem id={'config-list-item'} className={'config-list-item'}>

            <FormatPaintIcon titleAccess='Title Background Color' />
            <TextField id="standard-basic" className='config-menu-item-text' variant="standard"
                size="small"
                onClick={() => toggleTitleBgColorPicker(true)}
                value={titleBackgroundColor}
                onChange={event => setTitleBackgroundColor(event.target.value)}
            />

            <div style={{
                width: '25px', height: '25px',
                backgroundColor: titleBackgroundColor
            }}
                onClick={() => toggleTitleBgColorPicker(!isTitleBgColorPickerOpen)} />
            <br />
        </ListItem>

        <ListItem id={'config-list-item'} className={'config-list-item'}>
            {isTitleBgColorPickerOpen && <HexColorPicker
                style={{ width: 'inherit !important' }}

                height={300}
                color={titleBackgroundColor}
                onChange={color => {
                    setTitleBackgroundColor(color)
                }}
            />
            }
        </ListItem>

        <ListItem id={'config-list-item'} className={'config-list-item'}>


            <WidgetsIcon />
            <Autocomplete
                className='config-menu-item-text'
                fullWidth
                onChange={(event, newValue) => setWidgetType(WidgetTypes.find(x => x.label == newValue).value)}
                options={WidgetTypes.map((option) => option.label)}
                defaultValue={widgetType}
                renderInput={(params) => <TextField {...params} variant="standard" />}
            />


        </ListItem>

    </Collapse>

}


export default WidgetGeneralConfig;
