import React, { useEffect, useState } from "react";
import axios from 'axios'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from '@mui/material';
import { ApiEndpoint } from "config";
import { LoadingButton } from "@mui/lab";

const RemoveWidgetDialog = (
    { id,
        type,
        setRemoveConfirmation,
        open, setOpen, ...props }) => {

    const [ isDeleting, setIsDeleting] = useState(false)

    const handleClose = () => {
        setOpen();
    };

    const confirmRemoveWidget = async () => {
        setIsDeleting(true)
        await axios.delete(`${ApiEndpoint.Widget.DeleteWidget}/${id}`, {
            headers: {
                authorization: `bearer ${props.authentication.token.accessToken}`
            }
        }).then(resp => {

            props.showNotification(`Successfully removed from database`, 'success')
            setRemoveConfirmation(id)

        }).catch(err => {
            props.showNotification(err.message ?? err.response.data, 'danger')
        }).finally(() => {
            setIsDeleting(false)
        })
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">
            {`Delete the Widget ?`}
        </DialogTitle>
            <DialogTitle id="alert-dialog-title">
                {`${type} - ${id}`}
            </DialogTitle>
          
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Make sure that you know what are you trying to do. 
                    Clicking Yes will directly removing the widget from the database Immediately. 
                    All the configuration associated with the widget will get removed as well. 
                    
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <LoadingButton color="error" loading={isDeleting}  onClick={confirmRemoveWidget} autoFocus>
                    Remove
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}


export default RemoveWidgetDialog;