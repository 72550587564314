
export const ApiEndpoint = {
    PowerBi: {
        GetPowerBiEndpoint: '/api/PowerBi',
        GetWorkspaces: '/api/PowerBi/workspace',
        // GetReports: '/api/PowerBi/' + workspaceId + '/reports'
    },
    Page: {
        GetAllNavigation: '/api/Page',
        UpdatePageBasic: '/api/Page',// /api/page/{pageId}
        CreateParentPage: '/api/Page/CreateParentPage',// /api/page/createparentpage/{title}
        CreateChildrenPage: '/api/Page/createChildPage',
        DeletePage: '/api/Page',  // /api/page/{pageId}
        GetPageDetails: '/api/Page/childrenDetails',   ///api/page/childrenDetails/{pageID}
        ChangeOrder: '/api/Page', // /api/page/{pageid}/{ordernumber}   ---> Shared with switch parent and switch children
        ChangeAmongDifferentParents: '/api/Page/switchchildrenamongdiffparents', // /api/page/switchchildrenamongdiffparents/{pageId}/{newParentId}/{newOrderNumber}
    },
    Widget: {
        GetAllWidgets: '/api/widget', // /api/widget/{pageid}
        GetWidgetDetails: '/api/widget/widgetdetails',   // /api/widget/widgetdetails/{widgetId}
        CreateWidget: '/api/widget', // /api/widget
        UpdateWidget: '/api/widget', // /api/widget/{widgetId} 
        DeleteWidget: '/api/widget', // /api/widget/{widgetId} 
        GetAllWidgetLayouts: '/api/widgetlayout', // /api/widgetlayout/{pageid}
        SaveAllWidgetLayouts: '/api/widgetLayout',  // /api/widgetlayout/{pageid}
    },
    Tag: {
        GetAllTags: 'api/tag',
        DeleteTag: 'api/tag',
        UpdateTag: 'api/tag',
        AddTag: 'api/tag',
        ImportScvData: 'api/tag/importCsvData'
    },
    Kusto: {
        GetTimestamps: 'api/Kusto/getTimestamps',
        GetLatestValue: 'api/Kusto/getLatestValue',
        GetValuesBetweenTimestamps: 'api/Kusto/getValuesBetweenTimestamps',
        GetValue: 'api/Kusto/getValue',
        GetSurfacePlotValue: '/api/kusto/getSurfacePlotValue',
        GetSpectra: '/api/kusto/executeSpectra'
    },
    Grafana: {
        GetSnapshots: 'api/grafana/snapshots',
    }
}