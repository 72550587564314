

import axios from 'axios'

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    TextField,
    Icon,
    Autocomplete,
    createFilterOptions, Checkbox, FormControlLabel,
    Grid, Slider, Typography
} from '@mui/material';

import HeightIcon from '@mui/icons-material/Height';
import * as MuiIcon from '@mui/icons-material'
import { Fragment, useState, useEffect } from 'react';
import { IconTypes } from 'pages/dashboard/utils/IconTypes'
import { Box } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import {ApiEndpoint} from 'config'
import { GetAllNavigation } from "actions";

const PageConfigDialog = ({ pageId, open, setOpen,
    pageConfig, setPageConfig, authentication,
    showNotification,savePageConfig,
    ...props }) => {

    const [pageTitle, setPageTitle] = useState(pageConfig?.pageTitle)
    const [pageDesc, setPageDesc] = useState(pageConfig?.pageDesc)
    const [selectedIcon, setSelectedIcon] = useState(pageConfig?.pageIcon)
    const [compactType, setSelectedCompactType] = useState(pageConfig?.compactType)
    const [rowHeight, setRowHeight] = useState(pageConfig?.rowHeight)
    const [blDateTimePicker, toggleDatetimePicker] = useState(pageConfig?.blDateTimePicker)
    const [blPageScrolling, togglePageScrolling] = useState(pageConfig?.blPageScrolling)
    const [autoRefreshPeriod, setAutoRefreshPeriod] = useState(pageConfig?.autoRefreshPeriod ?? 0)
    const [loading, setLoading] = useState(false)

    const handleClose = () => {
        setOpen();
    };

    const confirmSavePageConfig = async () => {

        console.log('triggered')
        setLoading(true)
        setPageConfig({
            pageTitle: pageTitle,
            pageDesc: pageDesc,
            pageIcon: selectedIcon,
            compactType: compactType,
            rowHeight: rowHeight,
            blDateTimePicker: blDateTimePicker,
            blPageScrolling: blPageScrolling, 
            autoRefreshPeriod: autoRefreshPeriod
        })  
    };

    useEffect(async () => {

        if(loading){
            await savePageConfig()
            setLoading(false)
        }

    }, [pageConfig])

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        limit: 500,
    });



    var pages = [];
    for (var i = 0; i < props.global.customNavigation.length; i++) {
        for(var x=0; x < props.global.customNavigation[i].cards.length; x++){
            pages.push(props.global.customNavigation[i].cards[x])
        }
    }
    var item = {}
    for(var j = 0; j < pages.length; j++) {
        item[pageId] = {...pages[j]};
    }
    
    var titleName =  item[pageId]?.title
    var description = item[pageId]?.description
    var pageIcon = item[pageId]?.pageIcon
    
    

    return (
        <Dialog
            maxWidth="xl"
            fullWidth
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {`Page Configuration - ${titleName}`}
            </DialogTitle>

            <DialogContent>


                <DialogContentText marginBottom={3}>
                    <b> Page Title</b>

                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <MuiIcon.Title />
                        </Grid>

                        <Grid item xs>
                            <TextField
                                value={pageTitle}
                                onChange={e => setPageTitle(e.target.value)}
                                placeholder='Please fill up the page title'
                                fullWidth variant="standard" />
                        </Grid>
                    </Grid>
                </DialogContentText>


                <DialogContentText marginBottom={3}>
                    <b> Page Description</b>

                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <MuiIcon.Pages />
                        </Grid>

                        <Grid item xs>
                            <TextField 
                            value={pageDesc}
                            onChange={e => setPageDesc(e.target.value)}  
                            placeholder='Please fill up the page description (Optional)' 
                            fullWidth variant="standard" />
                        </Grid>
                    </Grid>
                </DialogContentText>


                <DialogContentText marginBottom={3}>
                    <b> Page Auto Refresh Duration (Mins)</b>

                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <MuiIcon.Refresh />
                        </Grid>

                        <Grid item xs>
                            <TextField 
                            type={'number'}  
                            InputProps={{
                                inputProps: { 
                                    max: 100, min: 0                                 
                            }}}
                            onChange={e => setAutoRefreshPeriod(e.target.value)}  
                            value={autoRefreshPeriod} 
                            placeholder='Please supply the widgets auto refresh duration (Minutes). PS : Set 0 to disable the auto refresh feature ' 
                            fullWidth variant="standard" />
                        </Grid>
                    </Grid>
                </DialogContentText>


                <DialogContentText marginBottom={3}>
                    <Box>
                        <Typography gutterBottom>
                            <b> Page Icon</b>

                        </Typography>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>
                                <Icon>{selectedIcon}</Icon>
                            </Grid>
                            <Grid item xs>

                                <Autocomplete
                                    fullWidth
                                    filterOptions={filterOptions}
                                    onChange={(event, newValue) => setSelectedIcon(newValue)}
                                    options={IconTypes}
                                    renderOption={(p, option) => {
                                        return (
                                            <Fragment>
                                                <span onClick={() => setSelectedIcon(option)}>
                                                    <Icon>{option}</Icon>
                                                </span>
                                            </Fragment>
                                        );
                                    }}
                                    value={selectedIcon}
                                    renderInput={(params) => <TextField placeholder='Click here to select Page Icon'  {...params} variant="standard">
                                        TEST
                                    </TextField>}
                                />

                            </Grid>
                        </Grid>
                    </Box>
                </DialogContentText>


                <DialogContentText marginBottom={3}>
                    <Typography gutterBottom>
                        <b> Compact Type</b>
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <MuiIcon.Pages />
                        </Grid>
                        <Grid item xs>
                            <Autocomplete
                                fullWidth
                                onChange={(event, newValue) => setSelectedCompactType(newValue)}
                                options={['horizontal', 'vertical', '-']}
                                value={compactType}
                                renderInput={(params) => <TextField {...params} variant="standard" />}
                            />

                        </Grid>
                    </Grid>
                </DialogContentText>


                <DialogContentText marginBottom={3}>
                    <Typography id="input-slider" gutterBottom>
                        <b>Row Height</b>
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <HeightIcon />
                        </Grid>
                        <Grid item>
                            {rowHeight}
                        </Grid>
                        <Grid item xs>
                            <Slider
                                value={typeof rowHeight === 'number' ? rowHeight : 0}
                                onChange={e => setRowHeight(e.target.value)}
                                aria-labelledby="input-slider"
                            />
                        </Grid>
                    </Grid>
                </DialogContentText>



                <DialogContentText>
                    <Typography id="input-slider" gutterBottom>
                        <b>Advance Settings</b>
                    </Typography>
                    <FormControlLabel
                        control={<Checkbox
                            defaultChecked={blDateTimePicker}
                            onChange={() => toggleDatetimePicker(!blDateTimePicker)} />}
                        sx={{ color: 'inherit' }}
                        label="Enable General Datatime Picker"
                    />
                </DialogContentText>

                <DialogContentText>
                    <FormControlLabel
                        control={<Checkbox
                            defaultChecked={blPageScrolling}
                            onChange={() => togglePageScrolling(!blPageScrolling)} />}
                        sx={{ color: 'inherit' }}
                        label="Enable Page Scrolling"
                    />
                </DialogContentText>


            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <LoadingButton onClick={confirmSavePageConfig} loading={loading}>
                    Save
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}


export default PageConfigDialog;