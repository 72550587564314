import { useEffect, useState, useRef, useCallback } from 'react';
import 'powerbi-report-authoring'

const usePrevious = val => {
    const ref = useRef();
    useEffect(() => {
        ref.current = val;
    }, [val]);
    return ref.current;
}


const Kusto = ({ id,
    width, height,
    dashboardId,
    config,
    widgetRefreshData,
    showNotification,
    ...props }) => {

        const [tokenRequested, setTokenRequested] = useState(false);
        const [tokenAssigned, setTokenAssigned] = useState(false);
    const [random, setRandom] = useState(0)
    const [srcUrl, setSrcUrl] = useState('https://dataexplorer.azure.com/clusters/kuclweuitomtsharedprd.westeurope?ibizaPortal=true')

    const prevConfig = usePrevious(config);

    
    useEffect(() => {
        // console.log(props.global.startDateTime)
        // console.log(props.global.endDateTime)
    }, [props])


    useEffect(() => {
        setRandom(random + 1)
    }, [widgetRefreshData])


    useEffect(() => {
          if(tokenAssigned) {
            setUrlToDashboard()
          }
    }, [config])

    useEffect(() => {
        if (tokenAssigned) {
              
            
            setTimeout(()=> {
                // console.log('set url to dashboard')
                setUrlToDashboard()
              }, 10000);
              
        }
    }, [tokenAssigned])

    // TODO : As currently the authentication is not support the dashboard (preview). 
    // Thus there is pointless for us to view the kusto dashboard as the authentication will be blocked. 
    // We need to wait Microsoft on the release of the kusto embed.  
    const setUrlToDashboard = () => {
        // if (config) {
        //     var param = config?.dashboardId ? `/${config?.dashboardId}?` : `?`
        //     param = `${param}ShowConnectionButton=${config?.showConnection}&`
        //     param = `${param}ShowShareMenu=${config?.showShareMenu}&`
        //     param = `${param}ShowToS=${config?.showToS}&`
        //     param = `${param}ShowPersona=${config?.showPersonaz}&`
        //     param = `${param}ShowFileMenu=${config?.showFileMenu}&`
        //     param = `${param}ShowPageHeader=${config?.showPageHeader}&`
        //     param = `${param}HideConnectionPane=${config?.hideConnectionPane}`
        //     setSrcUrl(`https://dataexplorer.azure.com/dashboards${param}`)
        // }

        if (config) {
            
            var param = `?`
            param = `${param}ibizaPortal=${config?.ibizaPortal}&`
            param = `${param}ShowConnectionButton=${config?.showConnection}&`
            param = `${param}ShowShareMenu=${config?.showShareMenu}&`
            param = `${param}ShowToS=${config?.showToS}&`
            param = `${param}ShowPersona=${config?.showPersonaz}&`
            param = `${param}ShowFileMenu=${config?.showFileMenu}&`
            param = `${param}ShowPageHeader=${config?.showPageHeader}&`
            param = `${param}HideConnectionPane=${config?.hideConnectionPane}`
            setSrcUrl(`https://dataexplorer.azure.com/clusters/kuclweuitomtsharedprd.westeurope${param}`)
        }
    }


    const handleEvent = useCallback(event => {
        if(event?.data?.signature === "queryExplorer" && event?.data?.type === "getToken"){
            console.debug('Get request to send ')
            if (props.authentication.token.kustoToken ) {
                const iframeWindow = document.getElementsByTagName("iframe")[0].contentWindow;
                iframeWindow.postMessage({ type: "postToken", message: props.authentication.token.kustoToken }, "*");  
                console.debug('Posting Kusto Token to Iframe ')
                setTokenAssigned(true)
            }

        }
    }, [])
    
    useEffect(() => {
        window.addEventListener("message", handleEvent)
        
        return () => {
            window.removeEventListener("message", handleEvent)
        }
    }, [handleEvent])



    useEffect(() => {
        console.debug(props.authentication.token.kustoToken, tokenRequested)

    }, [props.authentication.token.kustoToken, tokenRequested])

    

    console.debug(srcUrl)

    return <iframe key={random} width={width} height={height} src={srcUrl} />
};

export default Kusto;


