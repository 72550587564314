import { useEffect, useState } from 'react';
import axios from 'axios'
import moment from 'moment';
import Plotly from 'plotly.js-dist-min';
import createPlotlyComponent from 'react-plotly.js/factory';

import { styled } from '@mui/material/styles';

import {
    Box,
    Button,
    Grid,
    Slider
} from '@mui/material'

import Loader from 'components/loader';
import { LINE_CONFIG } from '../line/config';
import { ApiEndpoint, KUSTO_CLUSTER, KUSTO_DATABASE, KUSTO_TABLE } from 'config';

const Plot = createPlotlyComponent(Plotly);

const FFT = ({ id, workspaceId, widgetRefreshData, showNotification, authentication, 
    lineColor, backgroundColor, layoutColor, isTransparent, legendOrientation, gridLines, selectedTags, tagList, width, height, ...props }) => {

    const [isLoading, setIsLoading] = useState(false)

    // Slider
    const [value, setValue] = useState(0)

    const [valueX, setValueX] = useState([])
    const [valueY, setValueY] = useState([])
    const [lineC, setLineC] = useState(layoutColor)
    const [chartType, setChartType] = useState(true)
    const [chartTitle, setChartTitle] = useState('')

    // Slider
    const [timestampList, setTimestampList] = useState([])
    const [sliderValue, setSliderValue] = useState(0)

    // Chart
    const [revision, setRevision] = useState(0)
    const [chartData, setChartData] = useState([])

    const [tagName, setTagName] = useState('')

    useEffect(() => {
        getAvailableTimestamps()
        getData()
    }, [selectedTags]);

    const getData = async () => {
        
        setIsLoading(true)

        let _chartData = []
        for (let i=0; i<selectedTags.length; i++) {
            await axios.post(`${ApiEndpoint.Kusto.GetSpectra}`, null, {
                params: {
                    TagName: tagList.find(x => x.id === selectedTags[0]).name, //selectedTagList[x].name
                    SensorId: 0,
                    Count: 1
                },
                headers: {
                    'authorization': `bearer ${authentication?.token?.kustoToken}`
                }
            }).then(resp => {
                console.log('[FFT] Data > ', resp.data)
                let min = 0
                let max = resp.data.length
                let data = resp.data[Math.floor(Math.random() * (max - min))]
                
                let fftval = data.values
                let freqStart = data.freq_start
                let freqStep = data.freq_step
                let fftX = []
                let fftY = []
                
                for (let i=0; i<850; i++) {
                    fftX.push((i * freqStep) + freqStart)
                }
                
                for (let i=0; i<850; i++) {
                    // if (chartType) {
                    //     fftY.push(Math.sqrt(fftval[i]) * 707.106781186547524)
                    // }
                    // else {
                    //     fftY.push(Math.sqrt(fftval[i]) * 1000)
                    // }
                    fftY.push(Math.sqrt(fftval[i]))
                }
                

                let newData = {
                    x: fftX,
                    y: fftY,
                    type: 'scatter',
                    name: data.label,
                    mode: 'lines+points',
                    // marker: { color: lineC },
                }

                _chartData.push(newData)
            }).catch(err => {
                console.log(err)
                showNotification(err.response?.data?.error ?? err.response?.data?.title, 'danger')
            })
        }

        // setChartTitle(`Dataset: ${value} / ${timestampList.length} - ${moment(timestampList[value]).format('DD.MM.YYYY HH:mm:ss A')}`)

        setChartData(_chartData)
        setRevision(revision+1)        
        setIsLoading(false)
    }

    useEffect(() => {
        if (timestampList.length > 0) {
            getData()
        }
    }, [value])

    const getAvailableTimestamps = async () => {
        await axios.get(`${ApiEndpoint.Kusto.GetTimestamps}`, {
            params: {
                TagName: tagList.find(x => x.id === selectedTags[0]).name, //selectedTagList[x].name
                StartUTCTimestamp: moment(props?.global?.startDateTime).format('YYYY-MM-DDTHH:mm:ss')
            },
            headers: {
                'authorization': `bearer ${authentication?.token?.kustoToken}`
            }
        }).then(resp => {
            let data = resp.data
            setTimestampList(data)

            setChartTitle(`Dataset: ${value} / ${data.length} - ${moment(data[value]).format('DD.MM.YYYY HH:mm:ss A')}`)

        }).catch(err => {
            console.log(err)
            showNotification(err.response?.data?.error ?? err.response?.data?.title, 'danger')
        })
    }

    useEffect(() => {
        getAvailableTimestamps()
        getData()
    }, [props?.global?.startDateTime, props?.global?.endDateTime])

    useEffect(() => {
        setRevision(revision+1)
    }, [layoutColor])

    useEffect(() => {
        setChartTitle(`Dataset: ${value} / ${timestampList.length} - ${moment(timestampList[value]).format('DD.MM.YYYY HH:mm:ss A')}`)
        setLineC(lineColor)
        setRevision(revision+1)
    }, [lineColor])

    useEffect(() => {
        setRevision(revision+1)
    }, [backgroundColor])

    useEffect(() => {
        getData()
    }, [chartType, selectedTags])

    const handleSliderChange = (event, newValue) => {
        setValue(newValue)
        setChartTitle(`Dataset: ${newValue} / ${timestampList.length} - ${moment(timestampList[newValue]).format('DD.MM.YYYY HH:mm:ss A')}`)
        setRevision(revision+1)
    }

    const handleLeftButton = (event) => {
        if (value > 0) {
            setChartTitle(`Dataset: ${value-1} / ${timestampList.length} - ${moment(timestampList[value-1]).format('DD.MM.YYYY HH:mm:ss A')}`)
            setValue(value-1)
            setRevision(revision+1)
        }
    }

    const handleRightButton = (event) => {
        if (value < timestampList.length-1) {
            setChartTitle(`Dataset: ${value+1} / ${timestampList.length} - ${moment(timestampList[value+1]).format('DD.MM.YYYY HH:mm:ss A')}`)
            setValue(value+1)
            setRevision(revision+1)
        }
    }

    useEffect(() => {
        console.debug(`Refreshing page ${id}`)
        if (true) {
            getAvailableTimestamps()
            getData()
            showNotification('Refresh success!', 'success')
        }
    }, [widgetRefreshData])

    const PrettoSlider = styled(Slider)({
        color: layoutColor
    });

    const hexToRgba = (hex) => {
        var c;
        if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
            c= hex.substring(1).split('');
            if(c.length== 3){
                c= [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c= '0x'+c.join('');
            return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',0.8)';
        }
        console.log('Bad Hex > ', layoutColor)
        // throw new Error('Bad Hex');
        return '#005591'
    }

    return <>
        <div style={{ height: props.height, width: '100%', display: 'block' }}>

            <Box sx={{ flexGrow: 1 }} style={{ backgroundColor: (isTransparent) ? 'transparent' : backgroundColor }}>
                <br />
                <Grid container spacing={2}>

                    {
                        (width < 460 || height < 380) ?
                            <Grid item sx={12} style={{ width: width, height: (height-10), textAlign: 'center' }}>
                                <h2>Widget size is too small</h2>
                            </Grid>
                        :
                            <>
                                <Grid item xs={12} style={{ textAlign: 'left', marginLeft: '1%'}}>
                                    <table style={{ width: '100%' }}>
                                        <tr>
                                            <td style={{ width: '10%' }}>
                                                <div style={{ textAlign: 'left', whiteSpace: 'nowrap'}}>
                                                    <Button 
                                                        variant="contained"
                                                        onClick={() => setChartType(!chartType)}
                                                        style={{ backgroundColor: (chartType) ? 'lightgray' : layoutColor }}
                                                        disabled={(chartType) ? true : false}
                                                    >{'RMS'}</Button>
                                                    <Button 
                                                        variant="contained"
                                                        onClick={() => setChartType(!chartType)}
                                                        style={{ backgroundColor: (chartType) ? layoutColor : 'lightgray' }}
                                                        disabled={(chartType) ? false: true}
                                                    >{'Peak'}</Button>
                                                </div>
                                            </td>
                                            <td></td>
                                        </tr>
                                    </table>
                                </Grid>

                                <Grid item xs={12} style={{ margin: '0 1%' }}>
                                    <table style={{ width: '100%' }}>
                                        <tr>
                                            <td style={{ width: '5%', textAlign: 'center' }}>
                                                <Button 
                                                    variant="contained"
                                                    onClick={handleLeftButton}
                                                    size={'small'}
                                                    style={{ backgroundColor: layoutColor }}
                                                >{'<'}</Button>
                                            </td>
                                            <td style={{ width: '5%' }}></td>
                                            <td style={{ width: '80%', textAlign: 'center' }}>
                                                    <PrettoSlider
                                                        defaultValue={value}
                                                        // marks
                                                        max={timestampList.length}
                                                        onChangeCommitted={handleSliderChange}
                                                        // onChange={handleSliderChange}
                                                        aria-labelledby="input-slider"
                                                        valueLabelDisplay="on"
                                                        disabled={isLoading}
                                                    />
                                            </td>
                                            <td style={{ width: '5%' }}></td>
                                            <td style={{ width: '5%', textAlign: 'center' }}>
                                                <Button 
                                                    variant="contained"
                                                    onClick={handleRightButton}
                                                    size={'small'}
                                                    style={{ backgroundColor: layoutColor }}
                                                >{'>'}</Button>
                                            </td>
                                        </tr>
                                    </table>
                                </Grid>

                                <Grid item xs={12} style={{ paddingTop: '0' }}>
                                    {
                                        (isLoading) ? 
                                            <Loader />
                                        :
                                            <Plot useResizeHandler
                                                data={chartData}
                                                layout={{
                                                    paper_bgcolor: 'transparent',
                                                    plot_bgcolor: 'transparent',
                                                    title: {
                                                        text: chartTitle,
                                                        font: {
                                                            color: layoutColor,
                                                            family: 'LindeDax, Arial, sans-serif'
                                                        }                                    
                                                    },
                                                    uirevision: revision,
                                                    datarevision: revision,
                                                    width: width, 
                                                    height: (height-130),
                                                    xaxis: {
                                                        type: 'string',
                                                        title: 'f in Hz',
                                                        color: layoutColor,
                                                        showspikes: true,
                                                        spikemode: 'toaxis+across+marker',
                                                        spikesnap: 'hovered data',
                                                        showline: false,
                                                        showgrid: true,
                                                        spikedash: 'solid',
                                                        spikethickness: 1,
                                                        showgrid: (gridLines===LINE_CONFIG.GridLines[0] || gridLines===LINE_CONFIG.GridLines[1]) ? true : false,
                                                    },
                                                    yaxis: {
                                                        type: 'string',
                                                        title: `mm/s (${(chartType) ? 'RMS' : 'Peak'})`,
                                                        color: layoutColor,
                                                        showspikes: true,
                                                        spikemode: 'toaxis+across+marker',
                                                        spikesnap: 'cursor',
                                                        showline: true,
                                                        showgrid: true,
                                                        spikedash: 'solid',
                                                        spikethickness: 1,
                                                        showgrid: (gridLines===LINE_CONFIG.GridLines[0] || gridLines===LINE_CONFIG.GridLines[2]) ? true : false,
                                                    },
                                                    hovermode: 'x unified',
                                                    hoverlabel: {
                                                        bgcolor: 'white'
                                                    },
                                                    showlegend: (legendOrientation===null) ? false : true,
                                                    legend: {
                                                        orientation: legendOrientation,
                                                        font: {
                                                            color: hexToRgba(layoutColor)
                                                        }
                                                    },
                                                }}
                                            />
                                    }
                                </Grid>
                            </>
                    }

                </Grid>
            </Box>

            
        </div>
    </>
};

export default FFT;


