import { useEffect, useState } from 'react';
import { HexColorPicker } from "react-colorful";

import {
    ListItem,
    TextField,
    FormControlLabel,
    Checkbox,
    FormControl,
    InputLabel,
    Select,
    Box,
    Chip,
    MenuItem,
    ListItemText,
    Input,
    ButtonGroup,
    Button,
    ListSubheader,
    Divider,
    Slider,
    ListItemIcon
} from '@mui/material'

import {
    FormatColorText as FormatColorTextIcon,
    Title as TitleIcon,
    FormatPaint as FormatPaintIcon,
    LocalOffer as LocalOfferIcon,
    Opacity as OpacityIcon
} from '@mui/icons-material'
import { ApiEndpoint } from 'config';
import axios from 'axios';
import { LINE_CONFIG } from 'components/widgets/line/config';
import { BAR_CONFIG } from 'components/widgets/bar/config';

const CHART_CONFIG = {
    gridlines: ''
}

const BarConfiguration = ({ id, title,
    isConfigDrawerOpen, toggleConfigDrawer, widgetAdvanceSettings,
    setWidgetAdvanceSettings,
    showNotification,
    ...props }) => {

    const [dashboardId, setDashboardId] = useState(widgetAdvanceSettings?.bar?.dashboardId)

    const [isTransparent, setIsTransparent] = useState((widgetAdvanceSettings?.bar?.isTransparent !== undefined) ? widgetAdvanceSettings?.bar?.isTransparent : true)
    const [isFill, setIsFill] = useState(widgetAdvanceSettings?.bar?.isFill ? widgetAdvanceSettings?.bar?.isFill : false)
    
    const [layoutColor, setLayoutColor] = useState(widgetAdvanceSettings?.bar?.layoutColor ? widgetAdvanceSettings?.bar?.layoutColor : '#005591')
    const [isLayoutColorPickerOpen, toggleLayoutColorPicker] = useState(false);
    
    const [backgroundColor, setBackgroundColor] = useState(widgetAdvanceSettings?.bar?.backgroundColor ? widgetAdvanceSettings?.bar?.backgroundColor : '#FFFFFF')
    const [isBackgroundColorPickerOpen, toggleBackgroundColorPicker] = useState(false);

    const [titleX, setTitleX] = useState(widgetAdvanceSettings?.bar?.titleX ? widgetAdvanceSettings?.bar?.titleX : 'Title X')
    const [titleY, setTitleY] = useState(widgetAdvanceSettings?.bar?.titleY ? widgetAdvanceSettings?.bar?.titleY : 'Title Y')

    const [selectedTags, setSelectedTags] = useState(widgetAdvanceSettings?.bar?.selectedTags ? widgetAdvanceSettings?.bar?.selectedTags : [])
    const [tagList, setTagList] = useState(widgetAdvanceSettings?.bar?.tagList ? widgetAdvanceSettings?.bar?.tagList : [])

    const [legendOrientation, setLegendOrientation] = useState(widgetAdvanceSettings?.bar?.legendOrientation ? widgetAdvanceSettings?.bar?.legendOrientation : LINE_CONFIG.Legend[2])
    const [gridLines, setGridLines] = useState(widgetAdvanceSettings?.bar?.gridLines ? widgetAdvanceSettings?.bar?.gridLines : LINE_CONFIG.GridLines[3])

    const [chartOpacity, setChartOpacity] = useState(widgetAdvanceSettings?.bar?.chartOpacity ? widgetAdvanceSettings?.bar.chartOpacity : 1)
    const [orientation, setOrientation] = useState(widgetAdvanceSettings?.bar?.orientation ? widgetAdvanceSettings?.bar?.orientation : BAR_CONFIG.Orientation[0])

    useEffect(() => {
        getAvailableTags()
    }, [])

    useEffect(() => {
        setWidgetAdvanceSettings({
            ...widgetAdvanceSettings,
            bar: {
                titleX: titleX,
                titleY: titleY,
                layoutColor: layoutColor,
                backgroundColor: backgroundColor,
                isTransparent: isTransparent,
                tagList: tagList,
                isFill: isFill,
                selectedTags: selectedTags,
                legendOrientation: legendOrientation,
                gridLines: gridLines,
                chartOpacity: chartOpacity,
                orientation: orientation
            }
        })
    }, [orientation, chartOpacity, titleX, titleY, layoutColor, backgroundColor, isTransparent, isFill, selectedTags, tagList, legendOrientation, gridLines])

    //#region Sample Tag JSON
    // [
    //     {
    //         description: "description2asd"
    //         id: "955473ae-0001-4fe1-8ca8-7e575129b770"
    //         maxLimit: 3333
    //         minLimit: 22
    //         name: "Tag 66"
    //         plant: "plant 4"
    //         region: "region2"
    //         site: "site2"
    //         unit: 100
    //         -----------------------------
    //         value: [(100)]
    //         isSelected: false
    //     }
    // ]
    //#endregion

    const handleChange = (event) => {
        const { target: { value } } = event;
        setSelectedTags(value);
        
        let _tagList = [...tagList]
        let isSelectedCounter = 0

        for (let i=0; i<_tagList.length; i++) {
            if (value.includes(_tagList[i].id)) {
                _tagList[i].isSelected = true
                isSelectedCounter++
            }
            else {
                _tagList[i].isSelected = false
            }
        }

        setTagList(_tagList)
    };

    const getAvailableTags = () => {
        axios.get(`${ApiEndpoint.Tag.GetAllTags}`, {
            headers: {
                'authorization': `bearer ${props.authentication?.token?.accessToken}`
            }
        }).then(resp => {
            let _tagList = resp.data
            for (let i = 0; i < _tagList.length; i++) {
                _tagList[i] = {
                    ..._tagList[i],
                    isSelected: (selectedTags.filter(x => x === _tagList[i].id).length > 0) ? true : false,
                    value: []
                }
            }
            setTagList(_tagList)
        }).catch(err => {
            console.log(err)
            showNotification(err.response?.data?.error ?? err.response?.data?.title, 'danger')
        })
    }

    const onChangeTransparent = () => {
        setIsTransparent(!isTransparent)
    }

    const onChangeFillArea = () => {        
        setIsFill(!isFill)
    }


    return <div>
        <ListItem id={'config-list-item'} className={'config-list-item'}>
            <TitleIcon />
            <TextField className='config-menu-item-text'
                fullWidth
                label={'X Axis Title'}
                variant='standard'
                defaultValue={titleX}
                onChange={event => setTitleX(event.target.value)} />
        </ListItem>

        <ListItem id={'config-list-item'} className={'config-list-item'}>
            <TitleIcon />
            <TextField className='config-menu-item-text'
                fullWidth
                label={'Y Axis Title'}
                variant='standard'
                defaultValue={titleY}
                onChange={event => setTitleY(event.target.value)} />
        </ListItem>
        
        <br />
        <Divider variant='middle' />

        <ListSubheader style={{ color: '#00A0E1' }} disableSticky={true}>
            Data
        </ListSubheader>

        <ListItem id={'config-list-item'} className={'config-list-item'}>
            <LocalOfferIcon />
            <FormControl sx={{ m: 0 }} style={{ width: '410px' }}>
                <InputLabel id="demo-multiple-chip-label">Tags</InputLabel>
                <Select
                    multiple
                    value={selectedTags}
                    onChange={handleChange}
                    input={<Input label="Tag" />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', border: '0px' }}>
                        {selected.map((value) => (
                            <Chip key={value} label={tagList.find(x => x.id === value)?.description} />
                        ))}
                        </Box>
                    )}
                    // MenuProps={MenuProps}
                    className='config-menu-item-text'
                >
                    {/* {names.map((name) => (
                        <MenuItem key={name} value={name}>
                        <Checkbox checked={personName.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                        </MenuItem>
                    ))} */}
                    {
                        tagList.map((tag) => (
                            <MenuItem key={tag.id} value={tag.id} label={tag.description}>
                                <Checkbox checked={tag.isSelected} />
                                <ListItemText primary={tag.description} />
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        </ListItem>

        <br />
        <Divider variant='middle' />

        <ListSubheader style={{ color: '#00A0E1' }} disableSticky={true}>
            Layout
        </ListSubheader>

        <FormControlLabel sx={{ color: '#005591', fontFamily: 'LindeDax, Arial, sans-serif', marginLeft: 1 }}
            control={
                <Checkbox checked={isTransparent} onChange={onChangeTransparent} />
            }
            label="Set Transparent Background"
        />

        {
            (isTransparent) ? <br /> :
                <>
                    <ListItem id={'config-list-item'} className={'config-list-item'}>
                        <FormatPaintIcon titleAccess='Background Color' />
                        <TextField id="standard-basic" className='config-menu-item-text' variant="standard"
                            size="small"
                            value={backgroundColor}
                            onClick={() => toggleBackgroundColorPicker(true)}
                            onChange={event => setBackgroundColor(event.target.value)}
                        />

                        <div style={{
                            width: '25px', height: '25px',
                            backgroundColor: backgroundColor
                        }}
                            onClick={() => toggleBackgroundColorPicker(!isBackgroundColorPickerOpen)} />
                        <br />
                    </ListItem>

                    <ListItem id={'config-list-item'} className={'config-list-item'}>
                        {isBackgroundColorPickerOpen && <HexColorPicker
                            style={{ width: 'inherit !important' }}
                            height={300}
                            color={backgroundColor}
                            onChange={color => {
                                setBackgroundColor(color)
                            }}
                        />
                        }
                    </ListItem>
                </>
        }

        <ListItem id={'config-list-item'} className={'config-list-item'}>

        <FormatColorTextIcon titleAccess='Layout Color' />
        <TextField id="standard-basic" className='config-menu-item-text' variant="standard"
            size="small"
            value={layoutColor}
            onClick={() => toggleLayoutColorPicker(true)}
            onChange={event => setLayoutColor(event.target.value)}
        />

        <div style={{
            width: '25px', height: '25px',
            backgroundColor: layoutColor
        }}
            onClick={() => toggleLayoutColorPicker(!isLayoutColorPickerOpen)} />
        <br />
        </ListItem>

        <ListItem id={'config-list-item'} className={'config-list-item'}>
        {isLayoutColorPickerOpen && <HexColorPicker
            style={{ width: 'inherit !important' }}
            height={300}
            color={layoutColor}
            onChange={color => {
                setLayoutColor(color)
            }}
        />
        }
        </ListItem>

        <br />
        <Divider variant='middle' />

        <ListSubheader style={{ color: '#00A0E1' }} disableSticky={true}>
            Orientation
        </ListSubheader>

        <ListItem id={'config-list-item'} className={'config-list-item'}>
            <ButtonGroup variant="outlined" aria-label="outlined button group">
                <Button className={(orientation === BAR_CONFIG.Orientation[0]) ? 'is-active' : ''} onClick={() => setOrientation(BAR_CONFIG.Orientation[0])}>Vertical</Button>
                <Button className={(orientation === BAR_CONFIG.Orientation[1]) ? 'is-active' : ''} onClick={() => setOrientation(BAR_CONFIG.Orientation[1])}>Horizontal</Button>
            </ButtonGroup>
        </ListItem>

        <br />
        <Divider variant='middle' />

        <ListSubheader style={{ color: '#00A0E1' }} disableSticky={true}>
            Legend
        </ListSubheader>

        <ListItem id={'config-list-item'} className={'config-list-item'}>
            <ButtonGroup variant="outlined" aria-label="outlined button group">
                <Button className={(legendOrientation === LINE_CONFIG.Legend[0]) ? 'is-active' : ''} onClick={() => setLegendOrientation(LINE_CONFIG.Legend[0])}>Vertical</Button>
                <Button className={(legendOrientation === LINE_CONFIG.Legend[1]) ? 'is-active' : ''} onClick={() => setLegendOrientation(LINE_CONFIG.Legend[1])}>Horizontal</Button>
                <Button className={(legendOrientation === LINE_CONFIG.Legend[2]) ? 'is-active' : ''} onClick={() => setLegendOrientation(LINE_CONFIG.Legend[2])}>None</Button>
            </ButtonGroup>
        </ListItem>

        <br />
        <Divider variant='middle' />

        <ListSubheader style={{ color: '#00A0E1' }} disableSticky={true}>
            Chart Style
        </ListSubheader>

        {/* Chart Opacity  */}
        <ListItem id='config-list-item'>
            <OpacityIcon titleAccess='Chart Opacity' />
            <Slider size='small'
                className='config-menu-item-text'
                defaultValue={chartOpacity}
                onChange={(event, newVal) => setChartOpacity(newVal)}
                step={0.05}
                // marks
                min={0}
                max={1} 
            />
        </ListItem>

        <br />
        
    </div>
}


export default BarConfiguration