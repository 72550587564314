import React, { useEffect } from 'react';
import { Grid } from '@mui/material'

const LegalNotice = props => {

    useEffect(() => {
        document.title = 'Legal Notice';
    }, []);

    return (
        <>
            <Grid container justifyContent='center'>
                <Grid item style={{ fontSize: '16px', maxWidth: '1000px', lineHeight: '24px' }}>
                    <h1 style={{ fontSize: '64px', lineHeight: '77px', textTransform: 'none'}}>Legal Notice</h1>

                    <br />

                    <p>Linde GmbH</p> 
                    <p>Linde Engineering</p>
                    <p>Dr.-Carl-von-Linde-Str. 6-14</p>
                    <p>82049 Pullach</p>
                    <p>Germany</p>

                    <br />

                    <p>Phone: +49 89 7445-0</p>
                    <p>Fax: +49 89 7445-4908</p>
                    <p>Email: <a href='mailto:info@linde-le.com' style={{ color: '#4cbcea', textDecoration: 'underline' }}>info@linde-le.com</a></p>

                    <br />

                    <p>Registered Office: Pullach/Germany, Court of Registration: Munich, HRB</p>
                    <p>256407</p>
                    <p>Supervisory Board: Christoph Hammerl (Chairman)</p>
                    <p>Management Board: Sean Durbin, Jürgen Nowicki, Matthias von Plotho</p>

                    <br />

                    <p>Value-added tax identification number</p>
                    <p>DE 322012334</p>

                    <br />

                    <p>Linde is a company name used by Linde plc and its affiliates. The Linde logo, the Linde word and all mentioned trademarks (®/™) on this website are trademarks or registered trademarks of Linde plc or its affiliates. Copyright © 2021. Linde plc.</p>

                </Grid>
            </Grid>
        </>
    );
};

export default LegalNotice;