import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import SettingsIcon from '@mui/icons-material/Settings';
import SaveIcon from '@mui/icons-material/Save';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import { CircularProgress, Tooltip } from '@mui/material';
import { useEffect } from "react";

const WidgetItemTitle = ({ id, isEditMode, isConfigDrawerOpen, toggleConfigDrawer, toggleRemoveDialog,
    settingsChanged,
    toggleSaveDialog,
    toggleResetDialog,
    widgetGeneralSettings,
    widgetRefreshData,
    triggerWidgetRefreshData,
    savingWidget,
    ...rest }) => {



    return <div
        className="widget-item__title"
        style={{
            background: widgetGeneralSettings?.titleBackground,
            color: widgetGeneralSettings?.fontColor,
            fontSize: widgetGeneralSettings?.fontSize + widgetGeneralSettings?.fontUnit,
            cursor: (isEditMode ? 'move' : ''),
            placeContent: (isEditMode ? 'space-between' : 'center'),
            display: (!widgetGeneralSettings?.showTitle && !isEditMode && 'none')
        }}>

        <div>
            <Tooltip title='Options'>
                <SettingsIcon
                    className='widget-title-configuration'
                    style={{
                        marginLeft: '0.5rem',
                        display: (isEditMode ? 'inline-block' : 'none'),
                        float: 'left'
                    }}
                    onClick={() => {
                        toggleConfigDrawer();
                    }} />
            </Tooltip>

            <Tooltip title='Save Widget'>
                <>
                    {savingWidget && settingsChanged ? <HourglassBottomIcon
                        className='widget-title-configuration'
                        style={{
                            marginLeft: '0.5rem',
                            display: (isEditMode ? 'inline-block' : 'none'),
                            float: 'left',
                        }} /> : <SaveIcon
                        color={settingsChanged ? "primary" : "success"}
                        className='widget-title-configuration'
                        style={{
                            marginLeft: '0.5rem',
                            display: (isEditMode ? 'inline-block' : 'none'),
                            float: 'left',
                        }}
                        onClick={settingsChanged ? toggleSaveDialog : null} />}
                </>
            </Tooltip>

            <Tooltip title='Reset'>
                <RestartAltIcon
                    className='widget-title-configuration'
                    style={{
                        marginLeft: '0.5rem',
                        display: (isEditMode ? 'inline-block' : 'none'),
                        float: 'left'
                    }}
                    onClick={() => {
                        toggleResetDialog()
                    }} />
            </Tooltip>
        </div>

        <div style={{
            display: 'inline-block',
        }} title={id}>
            {widgetGeneralSettings.title}
        </div>

        <div style={{ float: "right" }}>
            <Tooltip title='Delete'>
                <CloseIcon
                    className='widget-title-configuration'
                    style={{
                        marginRight: '0.5rem',
                        display: (isEditMode ? 'inline-block' : 'none'),
                        float: 'right',
                    }} onClick={() => {
                        toggleRemoveDialog()
                    }} />

            </Tooltip>


            <Tooltip title='Refresh Widget'>
                <RefreshIcon
                    className='widget-title-configuration'
                    style={{
                        marginRight: (isEditMode ? '0' : '0.5rem'),
                        float: 'right',
                        display: 'inline-block'
                    }} onClick={() => {
                        triggerWidgetRefreshData(!widgetRefreshData)
                    }} />
            </Tooltip>
        </div>
    </div>
}


export default WidgetItemTitle;