import axios from 'axios'
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import {  HashRouter } from 'react-router-dom';




import store from 'reducers';
import { API_Endpoint, applicationTitle } from 'config';
import Routes from 'routes';


import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import 'index.scss';

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    axios.defaults.baseURL = API_Endpoint;
}
axios.defaults.timeout = 0;
axios.defaults.headers.post['Content-Type'] = 'application/json';
document.title = applicationTitle;
ReactDOM.render(
    <Provider store={store}>
        <HashRouter>
            <Routes />
        </HashRouter>
    </Provider>,
    document.getElementById('root')
);


