
import { useEffect, useState } from 'react';


import {
    ListItem,
    ListItemText,
    TextField,
    Autocomplete,
    Checkbox,
    FormControlLabel
} from '@mui/material'

import {
    Assessment as ReportIcon,
    Contactless as ContactlessIcon,
    Share as ShareIcon,
    Person as PersonIcon,
    Menu as MenuIcon,
    NetworkWifi as ConnectionIcon,
    Feed as FeedIcon,
    Security as SecurityIcon
} from '@mui/icons-material'



const KustoConfiguration = ({ id, title,
    isConfigDrawerOpen, toggleConfigDrawer, widgetAdvanceSettings,
    setWidgetAdvanceSettings,
    showNotification,
    ...props }) => {


    const [dashboardId, setDashboardId] = useState(widgetAdvanceSettings?.kusto?.dashboardId)
    const [ibizaPortal, setIbizaPortal] = useState(widgetAdvanceSettings?.kusto?.ibizaPortal ?? true)
    const [showConnection, setShowConnection] = useState(widgetAdvanceSettings?.kusto?.showConnection ?? false)
    const [showShareMenu, setShowShareMenu] = useState(widgetAdvanceSettings?.kusto?.showShareMenu ?? false)
    const [showToS, setShowToS] = useState(widgetAdvanceSettings?.kusto?.showToS ?? false)
    const [showPersonaz, setShowPersonaz] = useState(widgetAdvanceSettings?.kusto?.showPersonaz ?? false)
    const [showFileMenu, setShowFileMenu] = useState(widgetAdvanceSettings?.kusto?.showFileMenu ?? false)
    const [showPageHeader, setShowPageHeader] = useState(widgetAdvanceSettings?.kusto?.showPageHeader ?? false)
    const [hideConnectionPane, setHideConnectionPane] = useState(widgetAdvanceSettings?.kusto?.hideConnectionPane ?? false)




    useEffect(() => {

    }, [])



    useEffect(() => {

        setWidgetAdvanceSettings({
            ...widgetAdvanceSettings,
            kusto: {
                dashboardId: dashboardId,
                ibizaPortal,
                showConnection,
                showShareMenu,
                showToS,
                showPersonaz,
                showFileMenu,
                showPageHeader,
                hideConnectionPane
            }
        })


    }, [dashboardId, ibizaPortal, showConnection, showShareMenu,
        showToS, showPersonaz, showFileMenu,
        showPageHeader, hideConnectionPane])



    return <div>


        <ListItem id={'config-list-item'} className={'config-list-item'}>

            <ReportIcon />
            <TextField className='config-menu-item-text'
                fullWidth
                label="Dashboard ID"
                variant='standard'
                defaultValue={dashboardId}
                onChange={event => setDashboardId(event.target.value)} />


        </ListItem>
        
        <ListItem id={'config-list-item'} className={'config-list-item'}>
            {/* // THIS Color is linde blue  */}
            <SecurityIcon />
            <FormControlLabel
                sx={{ color: '#005591', fontFamily: 'LindeDax, Arial, sans-serif', marginLeft: 1 }}
                control={
                    <Checkbox checked={ibizaPortal} onChange={() => setIbizaPortal(!ibizaPortal)} />
                }
                label="Enable ibizaPortal" />
        </ListItem>

        <ListItem id={'config-list-item'} className={'config-list-item'}>
            {/* // THIS Color is linde blue  */}
            <ContactlessIcon />
            <FormControlLabel
                sx={{ color: '#005591', fontFamily: 'LindeDax, Arial, sans-serif', marginLeft: 1 }}
                control={
                    <Checkbox checked={showConnection} onChange={() => setShowConnection(!showConnection)} />
                }
                label="Show Connections"
            />

        </ListItem>
        <ListItem id={'config-list-item'} className={'config-list-item'}>
            {/* // THIS Color is linde blue  */}
            <ShareIcon />
            <FormControlLabel sx={{ color: '#005591', fontFamily: 'LindeDax, Arial, sans-serif', marginLeft: 1 }}

                control={
                    <Checkbox checked={showShareMenu} onChange={() => setShowShareMenu(!showShareMenu)} />
                }
                label="Show Share Menu"
            />

        </ListItem>

        <ListItem id={'config-list-item'} className={'config-list-item'}>
            {/* // THIS Color is linde blue  */}
            <PersonIcon />
            <FormControlLabel sx={{ color: '#005591', fontFamily: 'LindeDax, Arial, sans-serif', marginLeft: 1 }}

                control={
                    <Checkbox checked={showPersonaz} onChange={() => setShowPersonaz(!showPersonaz)} />
                }
                label="Show To Username"
            />

        </ListItem>

        <ListItem id={'config-list-item'} className={'config-list-item'}>
            {/* // THIS Color is linde blue  */}
            <MenuIcon />
            <FormControlLabel sx={{ color: '#005591', fontFamily: 'LindeDax, Arial, sans-serif', marginLeft: 1 }}

                control={
                    <Checkbox checked={showFileMenu} onChange={() => setShowFileMenu(!showFileMenu)} />
                }
                label="Show File Menu"
            />

        </ListItem>
        <ListItem id={'config-list-item'} className={'config-list-item'}>
            {/* // THIS Color is linde blue  */}
            <FeedIcon />
            <FormControlLabel sx={{ color: '#005591', fontFamily: 'LindeDax, Arial, sans-serif', marginLeft: 1 }}

                control={
                    <Checkbox checked={showPageHeader} onChange={() => setShowPageHeader(!showPageHeader)} />
                }
                label="Show Page Header"
            />

        </ListItem>
        <ListItem id={'config-list-item'} className={'config-list-item'}>
            {/* // THIS Color is linde blue  */}
            <ConnectionIcon />
            <FormControlLabel sx={{ color: '#005591', fontFamily: 'LindeDax, Arial, sans-serif', marginLeft: 1 }}

                control={
                    <Checkbox checked={hideConnectionPane} onChange={() => setHideConnectionPane(!hideConnectionPane)} />
                }
                label="Hide Connection Pane"
            />

        </ListItem>


    </div>
}


export default KustoConfiguration