import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from 'redux';

import { msalApp, loginRequest, tokenRequest, graphRequest, kustoRequest } from 'authentication/utils/authConfig';
import { isIE, requiresInteraction } from 'authentication/utils/authHelper';
import { updateAccount, updateError, updateToken, updateKusto, updateGraphToken, updateEdgeCentralToken } from 'reducers/authentication/actions';

const useRedirectFlow = isIE();

const AuthHOC = WrappedComponent => class AuthProvider extends Component {

    constructor(props) {
        super(props);

        this.state = {
            interval: null,
            firstLogin: true
        }

    }
    componentWillUnmount() {
        console.log('Disposing Token Auto Refresh')
        clearInterval(this.state.interval);
    }

    componentDidMount() {
        msalApp.handleRedirectCallback(error => {
            if (error) {
                const errorMessage = error.errorMessage ? error.errorMessage : "Unable to acquire access token.";
                // setState works as long as navigateToLoginRequestUrl: false
                this.props.updateError(errorMessage)
            }
        });

        const account = msalApp.getAccount();
        if (account === null) {
            console.log('No user found! Directing user to login page')
            window.location.hash = '/login'
        } else {
            console.log('Welcome User! ' + account?.name)
            this.updateAccount(account)
            this.refreshTokens();
            this.setState({
                'interval' : setInterval(() => {
                    console.log('Refresh Token every 50 minutes');
                    this.refreshTokens();
                }, 3000000)
            })
        }
    }

    componentDidUpdate(prevProps, prevStats) {

        if( this.props.authentication.error !== null) {
            console.log(this.props.authentication.error)
            window.location.hash = '/login'
        }
    }

    async refreshTokens() {

        this.acquireToken();
        // this.acquireGraph();
        this.acquireKusto();
    }

    async updateAccount(account) {
        this.props.updateAccount(account);
    }

    async acquireToken() {
        return msalApp.acquireTokenSilent(tokenRequest)
            .then((response) => {
                this.props.updateToken(response);
                
                return response
            })
            .catch(error => {
                console.debug('token error', error.errorCode)

                if (requiresInteraction(error.errorCode)) {
                    msalApp.acquireTokenPopup(tokenRequest).then((response) => {
                        this.props.updateToken(response)
                        return response
                    })
                } else {
                    this.props.updateError(error.errorCode)

                    if (error.errorCode === 'user_login_error') {
                        console.error('Non-interactive error: No user signed in')
                    } else {
                        console.error('Non-interactive error:', error.errorCode)
                    }
                }
            });
    }

    async acquireGraph() {
        return msalApp.acquireTokenSilent(graphRequest)
            .then((response) => {
                this.props.updateGraph(response)

                return response
            })
            .catch(error => {

                if (requiresInteraction(error.errorCode)) {
                    return useRedirectFlow
                        ? msalApp.acquireTokenRedirect(graphRequest)
                        : msalApp.acquireTokenPopup(graphRequest);
                } else {
                    console.error('Non-interactive error:', error.errorCode)
                }
            });
    }

    async acquireKusto() {

        return msalApp.acquireTokenSilent(kustoRequest)
            .then((response) => {
                this.props.updateKusto(response)
                return response
            })
            .catch(error => {

                if (requiresInteraction(error.errorCode)) {
                    return useRedirectFlow
                        ? msalApp.acquireTokenRedirect(kustoRequest)
                        : msalApp.acquireTokenPopup(kustoRequest);
                } else {
                    console.error('Non-interactive error:', error.errorCode)
                }
            });
    }

    async onSignIn() {
        return msalApp.loginRedirect(loginRequest);
    }

    async onSignOut() {
        msalApp.logout();
    }

    render() {
        return (
            <WrappedComponent
                {...this.props}
                onSignIn={() => this.onSignIn()}
                onSignOut={() => this.onSignOut()}
                acquireToken={() => this.acquireToken()}
                acquireKusto={() => this.acquireKusto()}
                acquireGraph={() => this.acquireGraph()}
            />
        );
    }
};

const mapStateToProps = (state) => state;

const mapDispatchToProps = dispatch => {
    return {
        updateAccount: (account) => {
            dispatch(updateAccount(account))
        },
        updateError: (error) => {
            dispatch(updateError(error))
        },
        updateToken: (token) => {
            dispatch(updateToken(token))
        },
        updateKusto: (token) => {
            dispatch(updateKusto(token))
        },
        updateGraph: (token) => {
            dispatch(updateGraphToken(token))
        },
        updateEdgeCentral: (token) => {
            dispatch(updateEdgeCentralToken(token))
        }
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps), AuthHOC)