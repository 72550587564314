import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { Box, Button } from '@mui/material';
import { updateAccount, updateError, updateToken } from 'reducers/authentication/actions'

import AuthProvider from 'authentication/utils/authProvier';

import './index.scss';
const MicrosoftIcon = () => {
    return (
        <svg
            style={{ width: '30px', height: '30px', marginRight: '1em' }}
            xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25'>
            <path fill='#f3f3f3' d='M0 0h23v23H0z' />
            <path fill='#f35325' d='M1 1h10v10H1z' />
            <path fill='#81bc06' d='M12 1h10v10H12z' />
            <path fill='#05a6f0' d='M1 12h10v10H1z' />
            <path fill='#ffba08' d='M12 12h10v10H12z' />
        </svg>
    );
};

const LoginPage = props => {

    const handleLogin = () => {
        props.onSignIn().then(() => {
            if (props.account) {
                console.log(props.account)
                props.updateAccount(props.account);
            } else {
                if (props.error) {
                    props.updateError(props.error);
                } else {
                    props.updateError({ errorMessage: 'Sign-in failed. Please try again.' });
                }
            }
        });
    };

    useEffect(() => {
        document.title = 'Login';
    }, []);


    if (props.account) {
        window.location.hash = '/'
    }



    return (
        <Box className='landing'>
            <Box className='landing__btn-bg'>
                <Button
                    className='landing__btn'
                    onClick={handleLogin}>
                    <MicrosoftIcon />
                    <span>Login with Microsoft Account</span>
                </Button>
            </Box>
        </Box >
    );
};

// const mapStateToProps = (state) => state;

// let mapDispatchToProps = dispatch => {
//     return {
//         updateAccount: (account) => {
//             dispatch(updateAccount(account))
//         },
//         updateError: (error) => {
//             dispatch(updateError(error))
//         },
//         updateToken: (token) => {
//             dispatch(updateToken(token))
//         }
//     };
// };


export default AuthProvider(LoginPage);
