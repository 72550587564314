
import { useEffect, useState } from 'react';
import { HexColorPicker } from "react-colorful";

import {
    ListItem,
    TextField,
    FormControlLabel,
    Checkbox,
    Autocomplete,
    Divider,
    ListSubheader,
    FormControl,
    InputLabel,
    Select,
    Input,
    Box,
    Chip,
    MenuItem,
    ListItemText,
    Button,
    ButtonGroup
} from '@mui/material'

import {
    FormatColorText as FormatColorTextIcon,
    Title as TitleIcon,
    FormatPaint as FormatPaintIcon,
    DonutLarge as DonutLargeIcon,
    Percent as PercentIcon,
    FormatAlignCenter as FormatAlignCenterIcon,
    List as ListIcon,
    LocalOffer as LocalOfferIcon,
    FormatSize as FormatSizeIcon,
    DonutSmall as DonutSmallIcon
} from '@mui/icons-material'

import { ApiEndpoint } from 'config';
import axios from 'axios';
import { PIE_CONFIG } from 'components/widgets/pie/config';

const TEXT_INFO = [
    {
        label: 'Label & Percentage',
        value: 'label+percent'
    },
    {
        label: 'Label',
        value: 'label'
    },
    {
        label: 'Percentage',
        value: 'percent'
    }
]

const TEXT_POSITION = [
    {
        label: 'Auto',
        value: 'auto'
    },
    {
        label: 'Inside',
        value: 'inside'
    },
    {
        label: 'Outside',
        value: 'outside'
    },
    {
        label: 'None',
        value: 'none'
    }
]

const PieConfiguration = ({ id, title, isConfigDrawerOpen, toggleConfigDrawer, widgetAdvanceSettings,
    setWidgetAdvanceSettings,
    showNotification,
    ...props }) => {

    const [dashboardId, setDashboardId] = useState(widgetAdvanceSettings?.pie?.dashboardId)

    const [isTransparent, setIsTransparent] = useState(true)
    
    const [layoutColor, setLayoutColor] = useState('#005591')
    const [isLayoutColorPickerOpen, toggleLayoutColorPicker] = useState(false);
    
    const [backgroundColor, setBackgroundColor] = useState('#FFFFFF')
    const [isBackgroundColorPickerOpen, toggleBackgroundColorPicker] = useState(false);

    const [chartTitle, setChartTitle] = useState('Pie Chart Title')
    const [titleSize, setTitleSize] = useState(18)
    const [selectedTags, setSelectedTags] = useState(widgetAdvanceSettings?.pie?.selectedTags ? widgetAdvanceSettings?.pie?.selectedTags : [])
    const [tagList, setTagList] = useState(widgetAdvanceSettings?.pie?.tagList ? widgetAdvanceSettings?.pie?.tagList : [])
    const [isDonut, setIsDonut] = useState(false)
    const [textInfo, setTextInfo] = useState(TEXT_INFO[0].value)
    const [textPosition, setTextPosition] = useState(TEXT_POSITION[0].value)
    const [value, setValue] = useState([100])
    const [donutSize, setDonutSize] = useState('0.7')
    const [categoryText, setCategoryText] = useState('Category')
    const [categoryTextSize, setCategoryTextSize] = useState(16)
    const [legendOrientation, setLegendOrientation] = useState(PIE_CONFIG.Legend[0])

    useEffect(() => {
        if (widgetAdvanceSettings?.pie) {
            setLayoutColor(widgetAdvanceSettings?.pie?.layoutColor)
            setBackgroundColor(widgetAdvanceSettings?.pie?.backgroundColor)

            setChartTitle(widgetAdvanceSettings?.pie?.chartTitle)
            setTitleSize(widgetAdvanceSettings?.pie?.titleSize)
            setIsDonut(widgetAdvanceSettings?.pie?.isDonut)
            setTextInfo(widgetAdvanceSettings?.pie?.textInfo)
            setTextPosition(widgetAdvanceSettings?.pie?.textPosition)
            setValue(widgetAdvanceSettings?.pie?.value)
            setCategoryText(widgetAdvanceSettings?.pie?.categoryText)
            setCategoryTextSize(widgetAdvanceSettings?.pie?.categoryTextSize)

            if (widgetAdvanceSettings?.pie?.isTransparent !== undefined) {
                setIsTransparent(widgetAdvanceSettings?.pie?.isTransparent)
            }
        }

        getAvailableTags()
    }, [])

    useEffect(() => {
        setWidgetAdvanceSettings({
            ...widgetAdvanceSettings,
            pie: {
                layoutColor,
                backgroundColor,
                chartTitle,
                isDonut,
                textInfo,
                textPosition,
                value,
                donutSize,
                categoryText,
                isTransparent,
                selectedTags,
                tagList,
                legendOrientation,
                categoryTextSize,
                titleSize
            }
        })
    }, [layoutColor, backgroundColor, categoryText, titleSize, chartTitle, donutSize, isDonut, textInfo, textPosition, value, isTransparent, selectedTags, tagList, legendOrientation, categoryTextSize])

    const getAvailableTags = () => {
        axios.get(`${ApiEndpoint.Tag.GetAllTags}`, {
            headers: {
                'authorization': `bearer ${props.authentication?.token?.accessToken}`
            }
        }).then(resp => {
            let _tagList = resp.data
            for (let i = 0; i < _tagList.length; i++) {
                _tagList[i] = {
                    ..._tagList[i],
                    isSelected: (selectedTags.filter(x => x === _tagList[i].id).length > 0) ? true : false,
                    value: []
                }
            }
            setTagList(_tagList)
        }).catch(err => {
            console.log(err)
            showNotification(err.response?.data?.error ?? err.response?.data?.title, 'danger')
        })
    }

    const handleChange = (event) => {
        const { target: { value } } = event;
        setSelectedTags(value);
        
        let _tagList = [...tagList]
        let isSelectedCounter = 0

        for (let i=0; i<_tagList.length; i++) {
            if (value.includes(_tagList[i].id)) {
                _tagList[i].isSelected = true
                isSelectedCounter++
            }
            else {
                _tagList[i].isSelected = false
            }
        }

        setTagList(_tagList)
    };

    const onChangeTransparent = () => {
        setIsTransparent(!isTransparent)
    }

    return <div>
        <ListItem id={'config-list-item'} className={'config-list-item'}>
            <TitleIcon />
            <TextField className='config-menu-item-text'
                fullWidth
                label={'Title'}
                variant='standard'
                defaultValue={chartTitle}
                onChange={event => setChartTitle(event.target.value)} />
        </ListItem>

        <ListItem id={'config-list-item'} className={'config-list-item'}>
            <FormatSizeIcon />
            <TextField className='config-menu-item-text'
                fullWidth
                label={'Title Size'}
                variant='standard'
                type={'number'}
                InputProps={{ inputProps: { min: 1, max: 999, step: '1' }}}
                defaultValue={titleSize}
                onChange={event => setTitleSize(event.target.value)} />
        </ListItem>
        
        <br />
        <Divider variant='middle' />

        <ListSubheader style={{ color: '#00A0E1' }} disableSticky={true}>
            Data
        </ListSubheader>

        <ListItem id={'config-list-item'} className={'config-list-item'}>
            <LocalOfferIcon />
            <FormControl sx={{ m: 0 }} style={{ width: '410px' }}>
                <InputLabel id="demo-multiple-chip-label">Tags</InputLabel>
                <Select
                    multiple
                    value={selectedTags}
                    onChange={handleChange}
                    input={<Input label="Tag" />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', border: '0px' }}>
                        {selected.map((value) => (
                            <Chip key={value} label={tagList.find(x => x.id === value)?.description} />
                        ))}
                        </Box>
                    )}
                    // MenuProps={MenuProps}
                    className='config-menu-item-text'
                >
                    {/* {names.map((name) => (
                        <MenuItem key={name} value={name}>
                        <Checkbox checked={personName.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                        </MenuItem>
                    ))} */}
                    {
                        tagList.map((tag) => (
                            <MenuItem key={tag.id} value={tag.id} label={tag.description}>
                                <Checkbox checked={tag.isSelected} />
                                <ListItemText primary={tag.description} />
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        </ListItem>

        <br />
        <Divider variant='middle' />

        <ListSubheader style={{ color: '#00A0E1' }} disableSticky={true}>
            Legend
        </ListSubheader>

        <ListItem id={'config-list-item'} className={'config-list-item'}>
            <ButtonGroup variant="outlined" aria-label="outlined button group">
                <Button className={(legendOrientation === PIE_CONFIG.Legend[0]) ? 'is-active' : ''} onClick={() => setLegendOrientation(PIE_CONFIG.Legend[0])}>Vertical</Button>
                <Button className={(legendOrientation === PIE_CONFIG.Legend[1]) ? 'is-active' : ''} onClick={() => setLegendOrientation(PIE_CONFIG.Legend[1])}>Horizontal</Button>
                <Button className={(legendOrientation === PIE_CONFIG.Legend[2]) ? 'is-active' : ''} onClick={() => setLegendOrientation(PIE_CONFIG.Legend[2])}>None</Button>
            </ButtonGroup>
        </ListItem>

        <br />
        <Divider variant='middle' />

        <ListSubheader style={{ color: '#00A0E1' }} disableSticky={true}>
            Chart Type
        </ListSubheader>

        <ListItem id={'config-list-item'} className={'config-list-item'}>
            <DonutLargeIcon titleAccess='Donut Chart' />
            <FormControlLabel sx={{ color: '#005591', fontFamily: 'LindeDax, Arial, sans-serif', marginLeft: 0.5 }}

                control={
                    <Checkbox checked={isDonut} onChange={() => setIsDonut(!isDonut)} />
                }
                label="Donut Chart"
            />
        </ListItem>

        {
            (isDonut) ?
                <>
                    <ListItem id={'config-list-item'} className={'config-list-item'}>
                        <DonutSmallIcon />
                        <TextField className='config-menu-item-text'
                            fullWidth
                            label={'Donut Width (0~1)'}
                            variant='standard'
                            type={'number'}
                            InputProps={{ inputProps: { min: 0.1, max: 0.9, step: '.1' }}}
                            defaultValue={donutSize}
                            onChange={event => setDonutSize(event.target.value)} />
                    </ListItem>

                    <ListItem id={'config-list-item'} className={'config-list-item'}>
                        <TitleIcon />
                        <TextField className='config-menu-item-text'
                            fullWidth
                            label={'Category Text'}
                            variant='standard'
                            defaultValue={categoryText}
                            onChange={event => setCategoryText(event.target.value)} />
                    </ListItem>

                    <ListItem id={'config-list-item'} className={'config-list-item'}>
                        <FormatSizeIcon />
                        <TextField className='config-menu-item-text'
                            fullWidth
                            label={'Category Text Size'}
                            variant='standard'
                            type={'number'}
                            InputProps={{ inputProps: { min: 1, max: 999, step: '1' }}}
                            defaultValue={categoryTextSize}
                            onChange={event => setCategoryTextSize(event.target.value)} />
                    </ListItem>
                </>
            :
                null
        }

        <br />
        <Divider variant='middle' />

        <ListSubheader style={{ color: '#00A0E1' }} disableSticky={true}>
            Layout
        </ListSubheader>

        <ListItem id={'config-list-item'} className={'config-list-item'}>
            <PercentIcon titleAccess='Display Label' />
            <Autocomplete
                className='config-menu-item-text'
                fullWidth
                onChange={(event, newValue) => setTextInfo(TEXT_INFO.find(x => x.label == newValue).value)}
                options={TEXT_INFO.map((option) => option.label)}
                defaultValue={TEXT_INFO[0]}
                renderInput={(params) => <TextField {...params} variant="standard" />}
            />
        </ListItem>

        <ListItem id={'config-list-item'} className={'config-list-item'}>
            <FormatAlignCenterIcon titleAccess='Label Position' />
            <Autocomplete
                className='config-menu-item-text'
                fullWidth
                onChange={(event, newValue) => setTextPosition(TEXT_POSITION.find(x => x.label == newValue).value)}
                options={TEXT_POSITION.map((option) => option.label)}
                defaultValue={TEXT_POSITION[0]}
                renderInput={(params) => <TextField {...params} variant="standard" />}
            />
        </ListItem>


        <ListItem id={'config-list-item'} className={'config-list-item'}>

            <FormatColorTextIcon titleAccess='Layout Color' />
            <TextField id="standard-basic" className='config-menu-item-text' variant="standard"
                size="small"
                value={layoutColor}
                onClick={() => toggleLayoutColorPicker(true)}
                onChange={event => setLayoutColor(event.target.value)}
            />

            <div style={{
                width: '25px', height: '25px',
                backgroundColor: layoutColor
            }}
                onClick={() => toggleLayoutColorPicker(!isLayoutColorPickerOpen)} />
            <br />
        </ListItem>

        <ListItem id={'config-list-item'} className={'config-list-item'}>
            {isLayoutColorPickerOpen && <HexColorPicker
                style={{ width: 'inherit !important' }}
                height={300}
                color={layoutColor}
                onChange={color => {
                    setLayoutColor(color)
                }}
            />
            }
        </ListItem>

        

        <FormControlLabel sx={{ color: '#005591', fontFamily: 'LindeDax, Arial, sans-serif', marginLeft: 1 }}
            control={
                <Checkbox checked={isTransparent} onChange={onChangeTransparent} />
            }
            label="Transparent Background"
        />

        {
            (isTransparent) ? null :
                <>
                    <ListItem id={'config-list-item'} className={'config-list-item'}>
                        <FormatPaintIcon titleAccess='Background Color' />
                        <TextField id="standard-basic" className='config-menu-item-text' variant="standard"
                            size="small"
                            value={backgroundColor}
                            onClick={() => toggleBackgroundColorPicker(true)}
                            onChange={event => setBackgroundColor(event.target.value)}
                        />

                        <div style={{
                            width: '25px', height: '25px',
                            backgroundColor: backgroundColor
                        }}
                            onClick={() => toggleBackgroundColorPicker(!isBackgroundColorPickerOpen)} />
                        <br />
                    </ListItem>

                    <ListItem id={'config-list-item'} className={'config-list-item'}>
                        {isBackgroundColorPickerOpen && <HexColorPicker
                            style={{ width: 'inherit !important' }}
                            height={300}
                            color={backgroundColor}
                            onChange={color => {
                                setBackgroundColor(color)
                            }}
                        />
                        }
                    </ListItem>
                </>
        }
        
    </div>
}


export default PieConfiguration