import { UPDATE_ACCOUNT, UPDATE_EDGE_CENTRAL, UPDATE_ERROR, UPDATE_GRAPH, UPDATE_KUSTO, UPDATE_TOKEN } from "./constants";

const initialState = {
    account: null,
    error: null,
    idToken: null,
    token: {
        accessToken: null,
        kustoToken: null,
        edgeCentralToken: null,
        graphToken: null
    },
    expiresOn: null,
    isAuthenticated: false,
};

const authenticationReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case UPDATE_ACCOUNT:
            return Object.assign({}, state, {
                account: action.payload,
                idToken: action.payload.idToken,
                isAuthenticated: true
            });

        case UPDATE_ERROR:

            return Object.assign({}, state, {
                accessToken: null,
                expiresOn: null,
                error: action.payload,
                isAuthenticated: false
            });

        case UPDATE_TOKEN:
            return Object.assign({}, state, {
                account: action.payload.account,
                token: {
                    ...state.token,
                    accessToken: action.payload.accessToken
                },
                expiresOn: action.payload.expiresOn,
                isAuthenticated: true
            });

        case UPDATE_KUSTO:
            return Object.assign({}, state, {
                token: {
                    ...state.token,
                    kustoToken: action.payload.accessToken
                }
            });

        case UPDATE_GRAPH:
            return Object.assign({}, state, {

                token: {
                    ...state.token,
                    graphToken: action.payload.graphToken
                }
            });

        case UPDATE_EDGE_CENTRAL:
            return Object.assign({}, state, {
                token: {
                    ...state.token,
                    edgeCentralToken: action.payload.edgeCentralToken
                }
            });

        default:
            return state
    }
}


export default authenticationReducer;