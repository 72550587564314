import { useEffect, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import { IconButton, Input } from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ConfirmDialog from '../../components/confirmDialog';
import { AddTag, GetAllTags, UpdateTag, DeleteTag, ImportScvData } from '../../actions';
import * as XLSX from 'xlsx';
import ExportExcelFile from '../../components/exportExcelFile';
import { IsUnitColumnHasValidData, ValidateTagObject } from '../Validation'
import Alert from '@mui/material/Alert';

export default function TagManagement(){

  const [tags, setTags] = useState([])
  const [open, setOpen] = useState(false);
  const [tag, setTag] = useState({});
  const [tagName, setTagName] = useState();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [tagId, setTagId] = useState();
  const [importCsvOpen, setImportCsvOpen] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [errors, setErrors] = useState({});
  const [pageSize, setPageSize] = useState(5);
  const [isValidUnitColumn, setIsValidUnitColumn] = useState(true)

  const handleCloseDialog = () => {
    setErrors({})
    setOpen(false);
  };

  const excelHeaderData = () => {
    let headers = []
    for (let i = 0; i < 1; i++) {
      headers.push({Name: '', Description: '', Unit: '', Site: '', Region: '', Plant: '', MaxLimit: '', MinLimit: ''});
    }

    return headers;
  }

  const handleOpenDialog = () => {
    setOpen(true);
    setTag({});
    setTagName("Add Tag");
  }

  const handleSaveTag = async () => {
      
    var errors = ValidateTagObject(tag)
    setErrors(errors)

    for(const error in errors){
      return
    }

    if(tagName === "Add Tag"){
      await AddTag(tag);
    }
    else{
      await UpdateTag(tag, setTags);
    }
    setOpen(false)
    await GetAllTags(setTags);
  }

  const handleImportOpenDialog = () => {
    setImportCsvOpen(true);
    setIsValidUnitColumn(true);
  }

  const updateTagByProperty = (property, newValue) => {
    const copiedTag = tag;
    copiedTag[property] = newValue
    setTag(copiedTag)
  }

  const deleteTag = async () => {
    await DeleteTag(tagId, setTags)
  }

  const handleCloseImportDialog = () => {
    setImportCsvOpen(false)
  }

  const handleImportCsvFile = e => {
    //console.log(e.target.files[0])
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
      setCsvData(data);
    }
    reader.readAsBinaryString(file);
  }

  const handleSaveImportCsvFile = async () => {

    if(csvData.length == 0)
      return;

    if(!IsUnitColumnHasValidData(csvData)){
      setIsValidUnitColumn(false)
      return;
    }

    await ImportScvData(csvData)
    await GetAllTags(setTags)
    setImportCsvOpen(false)
  }

  const filterTagsByGivenValue = async (e) => {
    var filteredTags = []
    console.log(tags)
    filteredTags = tags.filter(tag => {
      return tag.name.toLowerCase().includes(e.toLowerCase()) || 
             tag.site.toLowerCase().includes(e.toLowerCase()) || 
             tag.region.toLowerCase().includes(e.toLowerCase()) 
    })

    console.log('filtered > ', filteredTags)
    if(e.length != 0) {
      setTags(filteredTags)
    }
    else {
      await GetAllTags(setTags)
    }
  }

  useEffect(async () => {
    await GetAllTags(setTags)
  }, []);

  const columns = [
    {
      field: "action",
      headerName: "Actions",
      width: 130,
      sortable: false,
      renderCell: (params) => {
        const onDeleteClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          setTagId(params.id)
          setConfirmOpen(true);
         };

        const onEditClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          setOpen(true);
          var selectedTag = tags.find(c => c.id === params.id)
          setTag(selectedTag)
          setTagName("Edit Tag")
        }
  
        return [
          <IconButton 
            key={1}
            aria-label='delete'
            color='error' 
            onClick={onDeleteClick}><DeleteIcon />
          </IconButton>,
          <IconButton 
            key={2}
            aria-label='edit'
            color='primary' 
            onClick={onEditClick}><EditIcon />
          </IconButton>
          ];
      }
    },
    { field: 'name', headerName: 'Name', width: 130 },
    { field: 'description', headerName: 'Description', width: 130 },
    { field: 'unit', headerName: 'Unit', width: 130 },
    { field: 'site', headerName: 'Site', width: 130 },
    { field: 'region', headerName: 'Region', width: 130 },
    { field: 'plant', headerName: 'Plant', width: 130 },
    { field: 'maxLimit', headerName: 'MaxLimit', width: 130 },
    { field: 'minLimit', headerName: 'MinLimit', width: 130 }
  ];

  const rows =  tags.map(tag => (
    { 
      id: tag.id, 
      name: tag.name,
      description: tag.description, 
      unit: tag.unit, 
      site: tag.site, 
      region: tag.region, 
      plant: tag.plant, 
      maxLimit:tag.maxLimit, 
      minLimit: tag.minLimit}
  ))
  

  return(
    <>
       <Button variant="contained" style={{ margin: '0.5rem' }} onClick={handleOpenDialog}>
          Add Tag
      </Button>
      <ExportExcelFile data={excelHeaderData} fileName="TagTemplate"/>
      <Button variant="contained" style={{ margin: '0.5rem' }} onClick={handleImportOpenDialog}>
          Upload Tags via CSV
      </Button>
      <TextField 
          style={{ margin: '0.5rem', width: '250px' }}
          id="search"
          placeholder='Search by Tag name, site or region'
          label="Search"
          size="small"
          variant="outlined"
          onChange={e => filterTagsByGivenValue(e.target.value)}
        />
      <div style={{ height: 400, width: '100%' }}>
        <DataGrid
          experimentalFeatures={{ newEditingApi: true }}
          rows={rows}
          columns={columns}
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      />

        <div>
          <Dialog open={open}>
            <DialogTitle>{tagName}</DialogTitle>
            <DialogContent>
            <TextField
              id="name"
              placeholder='Please fill up the name'
              fullWidth variant="standard"
              label="Name"
              defaultValue={tag.name}
              onChange={e => updateTagByProperty('name', e.target.value)}
              error={!!errors.name}
              helperText={errors.name}
            />
            <TextField
              id="description"
              placeholder='Please fill up the description'
              label="Description"
              fullWidth variant="standard"
              defaultValue={tag.description}
              onChange={e => updateTagByProperty('description', e.target.value)}
              error={!!errors.description}
              helperText={errors.description}
            />
            <TextField
              id="unit"
              placeholder='Please fill up the unit'
              label="Unit"
              fullWidth variant="standard"
              defaultValue={tag.unit}
              onChange={e => updateTagByProperty('unit', e.target.value)}
              error={!!errors.unit}
              helperText={errors.unit}
            />
             <TextField
              id="site"
              placeholder='Please fill up the site'
              label="Site"
              fullWidth variant="standard"
              defaultValue={tag.site}
              onChange={e => updateTagByProperty('site', e.target.value)}
              error={!!errors.site}
              helperText={errors.site}
            />
            <TextField
              id="region"
              placeholder='Please fill up the region'
              label="Region"
              fullWidth variant="standard"
              defaultValue={tag.region}
              onChange={e => updateTagByProperty('region', e.target.value)}
              error={!!errors.region}
              helperText={errors.region}
            />
            <TextField
              id="plant"
              placeholder='Please fill up the plant'
              label="Plant"
              fullWidth variant="standard"
              defaultValue={tag.plant}
              onChange={e => updateTagByProperty('plant', e.target.value)}
              error={!!errors.plant}
              helperText={errors.plant}
            />
             <TextField
              id="maxLimit"
              placeholder='Please fill up the max limit'
              label="Max Limit"
              fullWidth variant="standard"
              defaultValue={tag.maxLimit}
              onChange={e => updateTagByProperty('maxLimit', e.target.value)}
              error={!!errors.maxLimit}
              helperText={errors.maxLimit}
            />
            <TextField
              id="minLimit"
              placeholder='Please fill up the min limit'
              label="Min Limit"
              fullWidth variant="standard"
              defaultValue={tag.minLimit}
              onChange={e => updateTagByProperty('minLimit', e.target.value)}
              error={!!errors.minLimit}
              helperText={errors.minLimit}
            />
            <DialogActions>
              <Button onClick={handleCloseDialog}>Cancel</Button>
              <Button onClick={handleSaveTag}>Save</Button>
            </DialogActions>
            </DialogContent>
          </Dialog>
        </div>
        <div>
        <ConfirmDialog 
            title="Delete Tag"
            open={confirmOpen}
            setOpen={setConfirmOpen}
            onConfirm={deleteTag}
          >Are you sure you want to delete this tag?</ConfirmDialog>
        </div>
        <div>
          <Dialog open={importCsvOpen}>
            <DialogTitle>Import Csv file</DialogTitle>
            <DialogContent>
              <Input 
                type={"file"}
                onChange={handleImportCsvFile}>
              </Input>
              {isValidUnitColumn ? null: <Alert severity="error">File has invalid unit column.</Alert>}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseImportDialog}>Cancel</Button>
                <Button onClick={handleSaveImportCsvFile}>Import</Button>
              </DialogActions>
          </Dialog>
        </div>
    </div>
    </>
  )
}