import React, { useEffect, useState } from 'react';

import {
    List,
    ListItem,
    ListItemText,
    Button,
    Drawer,
    Divider,
    ListSubheader,
    IconButton
} from '@mui/material'

import {
    Save as SaveIcon,
    Cancel as CancelIcon,
    ExpandLess as ExpandLessIcon, 
    ExpandMore as ExpandMoreIcon,
    ArrowLeft,
    ArrowRight
} from '@mui/icons-material'

import WidgetGeneralConfig from './Configuration/widgetGeneralConfig'
import WidgetAdvanceConfig from './Configuration/widgetAdvanceConfig'


const WidgetConfigurationDrawer = ({ 
    id, title, 
    isConfigDrawerOpen, toggleConfigDrawer, 
    widgetGeneralSettings, setWidgetGeneralSettings,
    widgetAdvanceSettings, setWidgetAdvanceSettings, 
    resetSettings, ...props }) => {

    const [isGeneralOpen, toggleGeneralOpen] = useState(true);
    const [isWidgetAdvanceOpen, toggleWidgetAdvanceOpen] = useState(true);
    const [drawDirectionRight, toggleDrawerDirection] = useState(true)


    return (
        <div>
            <Drawer
                anchor={drawDirectionRight ? 'right' : 'left'}
                open={isConfigDrawerOpen}
                onClose={toggleConfigDrawer}>

                <List id='config-menu'
                    subheader={<ListSubheader className={'is-active'} disableSticky={true}>
                            Widget Settings  
                            <IconButton onClick={() => {toggleDrawerDirection(!drawDirectionRight)}}>
                            {(drawDirectionRight) ? <ArrowLeft /> : <ArrowRight />}
                        </IconButton>
                        </ListSubheader>}>

                    <ListItemText style={{ color: '#00A0E1', paddingTop: '0.5rem' }} className='config-menu-item-text'>
                        General Config

                        <IconButton id={'drawer-button'} onClick={() => { toggleGeneralOpen(!isGeneralOpen) }}>
                            {(isGeneralOpen) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>

                    </ListItemText>

                    <WidgetGeneralConfig
                        id={id}
                        title={title}
                        isConfigDrawerOpen={isConfigDrawerOpen}
                        toggleConfigDrawer={toggleConfigDrawer}
                        isGeneralOpen={isGeneralOpen}
                        widgetGeneralSettings={widgetGeneralSettings}
                        setWidgetGeneralSettings={setWidgetGeneralSettings}


                        {...props} />

                    <Divider />


                    <ListItemText style={{ color: '#00A0E1', paddingTop: '0.5rem' }} className='config-menu-item-text'>
                        Advance Config

                        <IconButton id={'drawer-button'} onClick={() => { toggleWidgetAdvanceOpen(!isWidgetAdvanceOpen) }}>
                            {(isWidgetAdvanceOpen) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>

                    </ListItemText>


                    <WidgetAdvanceConfig
                        id={id}
                        isConfigDrawerOpen={isWidgetAdvanceOpen}
                        toggleConfigDrawer={toggleConfigDrawer}
                        isWidgetAdvanceOpen={isWidgetAdvanceOpen}
                        widgetType={widgetGeneralSettings.type}
                        widgetAdvanceSettings={widgetAdvanceSettings}
                        setWidgetAdvanceSettings={setWidgetAdvanceSettings}


                        {...props} />

                    <Divider />
                </List>


                {/* Drawer Footer */}
                <List style={{ marginTop: `auto` }}>

                    <ListItem id={'config-list-item'} className={'config-list-item'}>
                        <Button id={'drawer-button'} fullWidth onClick={toggleConfigDrawer} >
                            <SaveIcon />
                            <ListItemText primary='Preview Widget' className='drawer-menu-item-text' />
                        </Button>

                        <Button id={'drawer-button'} fullWidth onClick={resetSettings} >
                            <CancelIcon />
                            <ListItemText primary='Cancel' className='drawer-menu-item-text' />
                        </Button>

                    </ListItem>
                </List>

            </Drawer>
        </div>
    );
};



export default WidgetConfigurationDrawer;
