import { useEffect, useState } from 'react';

import {
    Button,
    ButtonGroup
} from '@mui/material'

import {
    FormatColorText as FormatColorTextIcon,
} from '@mui/icons-material'

const ButtonWidget = ({ id, workspaceId, widgetRefreshData, showNotification, authentication, 
    buttonText, fontSize, layoutColor, backgroundColor, width, height, isTransparent, buttonVariant, targetLocation, href, isDisable, ...props }) => {

    // handle component on mounted
    useEffect(() => {
        
    }, []);

    useEffect(() => {
        console.debug(`Refreshing page ${id}`)
        if (true) {
            showNotification('Refresh success!', 'success')
        }
    }, [widgetRefreshData])   

    return <>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', backgroundColor: (isTransparent) ? 'transparent' : backgroundColor, width: width, height: height }}>
            <Button
                variant={buttonVariant}
                href={href}
                target={targetLocation}
                disabled={isDisable}
                style={{ backgroundColor: (isDisable) ? '#c2c2c2' : (buttonVariant === 'contained') ? layoutColor : 'inherit', width: `${width}px`, height: `${height}px`, margin: '12px', fontSize: `${fontSize}px` }}
            >
                {buttonText}
            </Button>
        </div>        
    </>
};

export default ButtonWidget;


