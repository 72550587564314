import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Chip
} from '@mui/material';

import {
    Save as SaveIcon
} from '@mui/icons-material'

import { LoadingButton } from "@mui/lab";

const ImportWidgetDialog = (
    {
        pageId,
        type,
        setRemoveConfirmation,
        open,
        setOpen,
        importedJson, setImportedJson,
        loadImportConfiguration,
        showNotification,
        ...props }) => {

    const [fileName, setFileName] = useState('')
    const [configStatus, setConfigStatus] = useState('Unknown')
    const [isConfigImporting, setConfigImporting] = useState(false)

    useEffect(() => {

        if (configStatus === 'Invalid') {

            setImportedJson({})
        }
    }, [configStatus])

    const handleDiscard = () => {
        setConfigImporting(false)
        setFileName('')
        setConfigStatus('Unknown')
        setImportedJson({})
        setOpen();
    };

    const confirmImport = () => {
        if (configStatus === 'Valid') {
            setConfigImporting(true)
            loadImportConfiguration(importedJson)
            setTimeout(function () {
                setOpen();
                setFileName('')
                setConfigStatus('Unknown')
                setImportedJson({})
            }, 2000);
        } else {

            showNotification('Unable to import invalid configuration json! ', 'danger')
        }
    }

    return (
        <Dialog
            open={open}
            onClose={handleDiscard}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {`Import widget Configuration`}
            </DialogTitle>

            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Button variant="contained"
                        component="label" style={{ margin: '0.5rem' }}>
                        Select Json Config

                        <input type="file" hidden onChange={e => {
                            const fileReader = new FileReader();
                            fileReader.readAsText(e.target.files[0], "UTF-8");
                            setFileName(e.target.files[0].name)

                            fileReader.onload = e => {
                                try {
                                    var data = JSON.parse(e.target.result)
                                    if (data?.layouts && data?.layoutItems && data?.pageConfig) {
                                        showNotification(`Json Configuration is valid `, 'success')

                                        // TODO : I need to reset the data into new GUID 

                                        var stringData = e.target.result
                                        for (var items in data.layoutItems) {
                                            var newId = uuidv4()
                                            var re = new RegExp(items, 'g');
                                            stringData = stringData.replace(re, newId);
                                        }
                                        setImportedJson(JSON.parse(stringData))
                                        setConfigStatus('Valid')
                                    } else {
                                        showNotification(`Invalid Json Config `, 'danger')
                                        setConfigStatus('Invalid')
                                    }
                                }
                                catch (err) {
                                    setConfigStatus('Invalid')
                                    showNotification(`Unable to recognise imported file as JSON : ${err.message} `, 'danger')
                                }
                            };
                        }} />
                    </Button>
                    <br />
                    {`Config File   : ${fileName}`}
                    <br />
                    {`Config Status : `} <Chip label={configStatus} color={configStatus === 'Valid' ? 'success' : configStatus === 'Invalid' ? 'error' : 'default'} />
                    <br />
                    {`Quick view : ${JSON.stringify(importedJson)}`}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDiscard}>Discard</Button>

                <LoadingButton
                    color="primary"
                    onClick={confirmImport}
                    loading={isConfigImporting}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                >
                    Load Config
                </LoadingButton>

            </DialogActions>
        </Dialog>
    )
}


export default ImportWidgetDialog;