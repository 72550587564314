import { UserAgentApplication } from "msal";
import { clientId, tenantId, loginScope } from "config"


export const msalApp = new UserAgentApplication({
    auth: {
        tenantId: tenantId,
        clientId: clientId,
        authority: "https://login.microsoftonline.com/" + tenantId,
        validateAuthority: true,
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
    },
});



export const loginRequest = {
    scopes: [loginScope, "openid", "profile"]
}

export const tokenRequest = {
    scopes: [loginScope, "offline_access"]
}


export const kustoRequest = {
    scopes: ["https://help.kusto.windows.net/user_impersonation"]
};

export const graphRequest = {
    scopes: ["openid", "profile", "User.Read", "Group.Read.All", "User.ReadBasic.All"],
}
