import { useEffect, useState } from "react";

import WidgetItemTitle from "./widgetItemTitle";
import WidgetItemContent from "./widgetItemContent";
import WidgetConfigurationDrawer from "./widgetConfigurationDrawer"
import RemoveWidgetDialog from "./widgetLayoutFunction/removeWidgetDialog";
import SaveWidgetDialog from "./widgetLayoutFunction/saveWidgetDialog";
import ResetWidgetDialog from "./widgetLayoutFunction/resetWidgetDialog";

import axios from 'axios'
import { ApiEndpoint } from "config";

const WidgetItemContainer = ({
    id,
    importing,
    importConfig,
    children,
    isEditMode,
    saveAllWidget,
    triggerAllWidgetSave,
    setRemoveConfirmation,
    getFromLS,
    saveToLS,
    updateLayoutItems,
    refreshConfigHook,
    refreshHook,
    ...props }) => {



    const [isConfigDrawerOpen, setConfigDrawer] = useState(false)

    const [widgetInitialGeneralSettings, setWidgetInitialGeneralSettings] = useState({})
    const [widgetGeneralSettings, setWidgetGeneralSettings] = useState({})

    const [widgetInitialAdvanceSettings, setWidgetInitialAdvanceSettings] = useState({})
    const [widgetAdvanceSettings, setWidgetAdvanceSettings] = useState({})

    const [widgetRefreshData, triggerWidgetRefreshData] = useState(false)
    const [settingsChanged, setSettingsChanged] = useState(false)

    const [isRemoveDialogOpen, toggleRemoveDialog] = useState(false)
    const [isSaveDialogOpen, toggleSaveDialog] = useState(false)
    const [isResetDialogOpen, toggleResetDialog] = useState(false)


    useEffect(() => {
        getWidgetConfiguration()
    }, [])


    useEffect(() => {
        getWidgetConfiguration()
    }, [refreshConfigHook])

    useEffect(() => {

        console.debug(`refreshHook changing ${refreshHook}`)
        // Remarks: We need to split the auto refresh with individual item refresh. Before auto refresh is meant for all components refresh 
        triggerWidgetRefreshData(!widgetRefreshData)
    }, [refreshHook])


    useEffect(() => {
        if (saveAllWidget && settingsChanged) {
            saveWidgetConfiguration()
        }
    }, [saveAllWidget])

    useEffect(() => {
        updateLayoutItems(id, widgetGeneralSettings, widgetAdvanceSettings)

        if (JSON.stringify(widgetGeneralSettings) !== JSON.stringify(widgetInitialGeneralSettings) ||
            JSON.stringify(widgetAdvanceSettings) !== JSON.stringify(widgetInitialAdvanceSettings)) {

            setSettingsChanged(true)
        }

    }, [widgetGeneralSettings, widgetAdvanceSettings])


    const getWidgetConfiguration = async () => {

        var data = {}

        await axios.get(`${ApiEndpoint.Widget.GetWidgetDetails}/${id}`, {
            headers: {
                authorization: `bearer ${props.authentication.token.accessToken}`
            }
        }).then(resp => {
            data = resp.data
        })


        // LOCAL STORAGE TESTING
        // var data = getFromLS(id)

        if (data) {
            console.debug(data)

            setWidgetGeneralSettings(data.general)
            setWidgetInitialGeneralSettings(data.general)

            // Due to jsonb type in the database, it will be return as string 
            setWidgetAdvanceSettings(JSON.parse(data.advance))
            setWidgetInitialAdvanceSettings(JSON.parse(data.advance))

        } else if (importConfig && Object.keys(importConfig).length > 0) {
            console.debug('from Import', importConfig)

            setWidgetGeneralSettings(importConfig.general)
            setWidgetInitialGeneralSettings(importConfig.general)

            setWidgetAdvanceSettings(importConfig.advance)
            setWidgetInitialAdvanceSettings(importConfig.advance)
        } else {
            console.debug('from new widget')
            // To handle new widget created 
            var newWidgetGeneral = {
                type: null,
                title: "New Widget",
                fontSize: 1.2,
                fontUnit: 'rem',
                fontColor: '#d5d5d5',
                titleBackground: '#121212'
            }

            setWidgetGeneralSettings(newWidgetGeneral)
            setWidgetInitialGeneralSettings(newWidgetGeneral)

            setWidgetAdvanceSettings({})
            setWidgetInitialAdvanceSettings({})

        }
    }

    const saveWidgetConfiguration = async () => {


        // Saving the widget configuration into the database,then refresh the initial settings :) 
        await axios
            .patch(`${ApiEndpoint.Widget.UpdateWidget}/${id}`, [
                {
                    "op": "replace",
                    "path": "/Type",
                    "value": widgetGeneralSettings.type
                },
                {
                    "op": "replace",
                    "path": "/Title",
                    "value": widgetGeneralSettings.title
                },
                {
                    "op": "replace",
                    "path": "/TitleColor",
                    "value": widgetGeneralSettings.titleColor
                },
                {
                    "op": "replace",
                    "path": "/BackgroundColor",
                    "value": widgetGeneralSettings.titleBackground
                },
                {
                    "op": "replace",
                    "path": "/TitleSize",
                    "value": widgetGeneralSettings.fontSize
                },
                {
                    "op": "replace",
                    "path": "/IsStatic",
                    "value": false
                },
                {
                    "op": "replace",
                    "path": "/IsWidgetHeaderShow",
                    "value": widgetGeneralSettings.showTitle   // Future need to implement a settings where we can hide or show the header
                },
                {
                    "op": "replace",
                    "path": "/Configuration",
                    "value": JSON.stringify(widgetAdvanceSettings)
                },
            ], {
                headers: {
                    'authorization': `bearer ${props.authentication.token.accessToken}`
                }
            }).then(resp => {
                toggleSaveDialog(false)
                props.showNotification(`Successfully save ${widgetGeneralSettings.title} into database`, 'success')

                setWidgetInitialGeneralSettings(widgetGeneralSettings)
                setWidgetInitialAdvanceSettings(widgetAdvanceSettings)
                setSettingsChanged(false)

            }).catch(err => {
                props.showNotification(err.message ?? err.response.data, 'danger')
            }).finally(() => {
                triggerAllWidgetSave(false)
            })




        // DONE: Save Widget Configuration into database 
        // saveToLS(id, {
        //     general: widgetGeneralSettings,
        //     advance: widgetAdvanceSettings
        // })


    }

    const toggleConfigDrawer = () => {
        // When the options drawer was open, immediately trigger it as the widget settings had changed
        setConfigDrawer(!isConfigDrawerOpen)
    }

    const resetSettings = () => {

        if (JSON.stringify(widgetAdvanceSettings) !== JSON.stringify(widgetInitialAdvanceSettings)) {
            setWidgetAdvanceSettings(widgetInitialAdvanceSettings)
        }

        if (JSON.stringify(widgetGeneralSettings) !== JSON.stringify(widgetInitialGeneralSettings)) {
            setWidgetGeneralSettings(widgetInitialGeneralSettings)
        }
        if (isConfigDrawerOpen) {
            toggleConfigDrawer()
        }

        setSettingsChanged(false)

    }



    const width = parseInt(props.style.width, 10);

    // TODO :  This 30 is meant for the height for the header, hence in future if we have hide title settings here we need to do if else enhancement 
    const height = parseInt(props.style.height, 10) - 30;

    // YOU MUST INCLUDE PROPS IN ORDER FOR USER ABLE TO DRAG THE FUCKING DIV !!!!!!
    return <div key={id} {...props} >

        {isRemoveDialogOpen ?
            <RemoveWidgetDialog
                id={id}
                type={widgetGeneralSettings?.type}
                setRemoveConfirmation={setRemoveConfirmation}
                open={isRemoveDialogOpen}
                setOpen={toggleRemoveDialog} {...props} /> : <></>
        }

        {isSaveDialogOpen ?
            <SaveWidgetDialog
                id={id}
                widgetGeneralSettings={widgetGeneralSettings}
                widgetAdvanceSettings={widgetAdvanceSettings}
                saveWidgetConfiguration={saveWidgetConfiguration}
                saveAllWidget={saveAllWidget}
                open={isSaveDialogOpen}
                setOpen={toggleSaveDialog} {...props} /> : <></>}

        {isResetDialogOpen ?
            <ResetWidgetDialog
                id={id}
                resetSettings={resetSettings}
                open={isResetDialogOpen}
                setOpen={toggleResetDialog}
            /> : <></>
        }

        <WidgetItemTitle
            id={id}
            isEditMode={isEditMode}
            widgetGeneralSettings={widgetGeneralSettings}
            settingsChanged={settingsChanged}
            isConfigDrawerOpen={isConfigDrawerOpen}
            toggleConfigDrawer={toggleConfigDrawer}
            toggleResetDialog={() => toggleResetDialog(!isResetDialogOpen)}
            toggleSaveDialog={() => toggleSaveDialog(!isSaveDialogOpen)}
            toggleRemoveDialog={() => toggleRemoveDialog(!isRemoveDialogOpen)}
            widgetRefreshData={widgetRefreshData}
            savingWidget={saveAllWidget}
            triggerWidgetRefreshData={triggerWidgetRefreshData} />

        <WidgetItemContent
            id={id} 
            type={widgetGeneralSettings?.type}
            width={width} 
            height={height}
            widgetAdvanceSettings={widgetAdvanceSettings}
            widgetRefreshData={widgetRefreshData} {...props} />

        <WidgetConfigurationDrawer id={id}
            title={widgetGeneralSettings?.title}
            isConfigDrawerOpen={isConfigDrawerOpen} toggleConfigDrawer={toggleConfigDrawer}
            widgetGeneralSettings={widgetGeneralSettings} 
            setWidgetGeneralSettings={setWidgetGeneralSettings}
            widgetAdvanceSettings={widgetAdvanceSettings} 
            setWidgetAdvanceSettings={setWidgetAdvanceSettings}
            resetSettings={resetSettings} {...props} />


        {/* 
            Children over here is meant for the handler, please do not and never DELETE it!
            Ming Hui had spent days to debug this small issue before !        
            It is fucking important ! 
        */}
        {children}
    </div>
};

export default WidgetItemContainer;

