import { useEffect, useState, useRef, createRef } from 'react';
import axios from 'axios'

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const Image = ({ id, workspaceId, widgetRefreshData, showNotification, authentication, 
    imageTransparent, imgData, imageBackgroundColor, width, height, ...props }) => {

    // handle component on mounted
    useEffect(() => {
        
    }, []);

    useEffect(() => {
        console.debug(`Refreshing page ${id}`)
        if (true) {
            showNotification('Refresh success!', 'success')
        }
    }, [widgetRefreshData])   

    return <>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', backgroundColor: (imageTransparent ? 'transparent' : imageBackgroundColor), width: '100%', height: props.height }}>
            <img src={imgData} style={{ position: 'relative', width: width, height: height }} />
        </div>        
    </>
};

export default Image;


