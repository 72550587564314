import React, { useState } from 'react';
import { Grid, Box, Button } from '@mui/material'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import LoginIcon from '@mui/icons-material/Login'

import packageJson from '../../../../package.json';


import { connect } from 'react-redux'
import {
    updateAccount,
    updateError,
    updateToken,
} from 'reducers/authentication/actions'

import AuthProvider from 'authentication/utils/authProvier'



const Footer = ({
    showNotification,
    ...props}) => {

    const [debugMode, setDebugMode] = useState(false);
    const handleLogin = () => {
        props.onSignIn().then(() => {
            if (props.account) {
                console.log(props.account)
                props.updateAccount(props.account);
            } else {
                if (props.error) {
                    props.updateError(props.error);
                } else {
                    props.updateError({ errorMessage: 'Sign-in failed. Please try again.' });
                }
            }
        });
    };

    const handleLogout = () => {
        console.log('Logout Redirecting...');
        props.onSignOut().then(() => {
            if (!props.account) {
                props.updateAccount(null);
            } else {
                if (props.error) {
                    props.updateError(props.error);
                } else {
                    props.updateError({ errorMessage: 'Sign-out failed. Please try again.' });
                }
            }
        });
    };

    const toogleDebug = () => {
        setDebugMode(!debugMode);
    }

    const debugStyle = debugMode ? { marginLeft: '2rem' } : { marginLeft: '2rem', display: 'none' }



    return (
        <footer>
            <Grid container justifyContent='space-between' alignItems='center' className='footer-container' style={{ paddingTop: '4px' }}>
                <Grid item>
                    <Grid container justifyContent='flex-start' alignItems='center'>
                        <Grid item style={{ marginLeft: '2rem' }}>
                            <p className='copyright'>&#169; Linde Engineering - {packageJson.version} ({packageJson.build})</p>
                        </Grid>
                        <Grid item style={{ marginLeft: '2rem' }}>
                            <a href='#/policy/legal-notice' className='footer-link'>Legal Notice</a>
                        </Grid>
                        <Grid item style={{ marginLeft: '2rem' }}>
                            <a href='#/policy/terms-conditions' className='footer-link'>Terms & Conditions</a>
                        </Grid>
                        <Grid item style={{ marginLeft: '2rem' }}>
                            <a href='#/policy/privacy-policy' className='footer-link'>Privacy Policy</a>
                        </Grid>
                        <Grid item style={debugStyle}>
                            <Button className='footer-link' onClick={() => { console.log(props) }}>Print Props</Button>
                        </Grid>
                        <Grid item style={debugStyle}>
                            <Button className='footer-link' onClick={() => { console.log(props.authentication.token.accessToken) }}>Print Access Token</Button>
                        </Grid>
                        <Grid item style={debugStyle}>
                            <Button className='footer-link' onClick={async () => { console.log(await props.acquireToken()) }}>Generate Access Token</Button>
                        </Grid>
                        <Grid item style={debugStyle}>
                            <Button className='footer-link' onClick={async () => { console.log(await props.acquireKusto()) }}>Generate Kusto Token</Button>
                        </Grid>
                        <Grid item style={debugStyle}>
                            <Button className='footer-link' onClick={() => { console.log(props.authentication.token.kustoToken) }}>Print Kusto Token</Button>
                        </Grid>
                        <Grid item style={debugStyle}>
                            <Button className='footer-link' onClick={() => { console.log(process.env) }}>Print Environment Variable</Button>
                        </Grid>
                        <Grid item style={debugStyle}>
                            <Button className='footer-link' onClick={() => { showNotification('testing') }}>show Notification</Button>
                        </Grid>

                    </Grid>
                </Grid>
                <Grid item>
                    <Box display='flex' alignItems='center'>


                        {props.authentication.account ?
                            (<>
                                <Box>
                                    <span className='username is-secondary' onDoubleClick={() => toogleDebug()} >
                                        Welcome {props.authentication.account?.name}
                                    </span>
                                </Box>
                                <Box px={1}>
                                    <span className='logout-button' onClick={() => handleLogout()}>
                                        <ExitToAppIcon style={{ marginTop: '3px' }} />
                                    </span>
                                </Box></>
                            )
                            : (<>
                                <Box>
                                    <span className='username is-secondary' onDoubleClick={() => toogleDebug()} >
                                        Welcome User
                                    </span>
                                </Box>
                                <Box px={1}>
                                    <span className='logout-button' onClick={() => handleLogin()}>
                                        <LoginIcon style={{ marginTop: '3px' }} />
                                    </span>
                                </Box></>)}

                    </Box>
                </Grid>
            </Grid>
        </footer>
    );
};


let mapStateToProps = (state) => state;

let mapDispatchToProps = dispatch => {
    return {
        updateAccount: (account) => {
            dispatch(updateAccount(account))
        },
        updateError: (error) => {
            dispatch(updateError(error))
        },
        updateToken: (token) => {
            dispatch(updateToken(token))
        },
        updateDeviceStatus: function (deviceName, devicestatus) {
            dispatch({
                type: "UPDATE_DEVICE_ALARM",
                device: deviceName,
                status: devicestatus
            });
        },
        showNotification: function (msg, intent) {
            dispatch({
                type: "SHOW_NOTIFICATION",
                message: msg,
                intent: intent
            });
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthProvider(Footer));
