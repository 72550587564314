import react, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
    AppBar,
    Button,
    Card,
    CardActions,
    CardContent,
    Dialog,
    Divider,
    List,
    ListItem,
    ListItemText,
    IconButton,
    Slide,
    Toolbar,
    Typography



} from '@mui/material';


import {
    Close as CloseIcon,
    Add as AddIcon,
    Delete as DeleteIcon,
    Edit as EditIcon
} from '@mui/icons-material'
import Board, { addColumn } from "@asseinfo/react-kanban";
import "@asseinfo/react-kanban/dist/styles.css";
import axios from 'axios'
import { ApiEndpoint } from 'config'
import { LoadingButton } from '@mui/lab';
import DeleteConfirmationDialog from './DeleteConfirmationDialog';
import EditConfirmationDialog from './EditConfirmationDialog';
import CreateConfirmationDialog from './CreateConfirmationDialog';

const Transition = react.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});



const NavigationDialog = ({ open, toogleConfigDialog, customMenuItems, setCustomMenuItems, authentication,
    showNotification,
    ...props }) => {


    const [isDeleteConfirmationOpen, toggleDeleteConfirmation] = useState(false)
    const [deleteConfig, setDeleteConfig] = useState({
        id: null,
        title: null,
        removeColumn: null,
        type: null
    })

    const [isEditDialogOpen, toggleEditDialog] = useState(false)
    const [editConfig, setEditConfig] = useState({
        id: null,
        title: null,
        description: null,
        renameColumn: null
    })

    const [isCreateDialogOpen, toggleCreateDialog] = useState(false)
    const [createConfig, setCreateConfig] = useState({
        id: null,
        parentId: null,
        title: null,
        description: null,
        createPage: null,
        type: null
    })


    const parentCreatedCallback = (board, parentItem) => {
        setCustomMenuItems(board.columns)
        showNotification(`Parent created successfully with ID: ${parentItem.id}`, 'success')
    }

    const childrenCreatedCallback = (board, parent, children) => {

        // This must called to update the drawer menu
        setCustomMenuItems(board.columns)
        showNotification(`Children created successfully under ${parent.title} with ID: ${children.id}`, 'success')
        // Create Children page  
    }

    const childrenRemovedCallback = (board, item) => {

        setCustomMenuItems(board.columns)
        showNotification(`removing cards/children`, 'success')
    }

    const parentRemoveCallback = (board, item) => {

        setCustomMenuItems(board.columns)
        showNotification(`removing parent/column`, 'success')
    }

    const renamingPageCallback = (board, item) => {

        setCustomMenuItems(board.columns)
        console.log(item)
        showNotification(`Page renamed success ${item.title}`, 'success')
    }


    const updateBoardState = (board, item, item2, item3) => {
        // this will use to switch the order among children 

        if (item2.fromColumnId) {
            console.debug(`this is children ${item2.fromColumnId}`)
            
            if(item2.fromColumnId !== item3.toColumnId){
                //Handling the movement between the different parent 
                axios
                .put(`${ApiEndpoint.Page.ChangeAmongDifferentParents}/${item.id}/${item3.toColumnId}/${item3.toPosition}`, null, {
                    headers: {
                        'Authorization': `bearer ${authentication?.token?.accessToken}`
                    }
                })
                    .then(resp => {
                        console.log(resp.data)
                        setCustomMenuItems(board.columns)
                        showNotification('Re-order successfully saved', 'success')
                    })
                    .catch(err => {
                        console.error(err.response?.data?.error ?? err.response?.data?.title)
                        showNotification('Failed to update the application order', 'danger')
                    })
                
            }else{
                // Handling the movement between the same parent 
                axios
                .put(`${ApiEndpoint.Page.ChangeOrder}/${item.id}/${item3.toColumnId}/${item3.toPosition}`, null, {
                    headers: {
                        'Authorization': `bearer ${authentication?.token?.accessToken}`
                    }
                })
                    .then(resp => {
                        console.log(resp.data)
                        setCustomMenuItems(board.columns)
                        showNotification('Re-order successfully saved', 'success')
                    })
                    .catch(err => {
                        console.error(err.response?.data?.error ?? err.response?.data?.title)
                        showNotification('Failed to update the application order', 'danger')
                    })
            }


        } else {
            console.debug(`this is parent`)

            axios
            .put(`${ApiEndpoint.Page.ChangeOrder}/${item.id}/${item3.toPosition}`, null, {
                headers: {
                    'Authorization': `bearer ${authentication?.token?.accessToken}`
                }
            })
                .then(resp => {
                    console.log(resp.data)
                    setCustomMenuItems(board.columns)
                    showNotification('Re-order successfully saved', 'success')
                })
                .catch(err => {
                    console.error(err.response?.data?.error ?? err.response?.data?.title)
                    showNotification('Failed to update the application order', 'danger')
                })
        }
    }



    const ColumnAdder = ({ addColumn }) => {
        return (
            <LoadingButton
                style={{ margin: '1rem' }}
                color='primary'
                variant="contained"
                loadingPosition='end'
                endIcon={<AddIcon />}
                onClick={() => {

                    setCreateConfig({
                        type: 'parent',
                        createPage: addColumn
                    })
                    toggleCreateDialog(!isCreateDialogOpen)
                    // createParentPage(addColumn)
                }} >
                Add Parent
            </LoadingButton>

        )
    }


    return (
        <Dialog
            fullScreen
            open={open}
            onClose={toogleConfigDialog}
            TransitionComponent={Transition}>
            <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={toogleConfigDialog}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Page Customization
                    </Typography>
                </Toolbar>
            </AppBar>



            <div style={{ padding: '20px', border: '4px solid indianred', borderRadius: '6px' }} >
                <Board
                    allowAddColumn
                    allowRenameColumn
                    allowRemoveColumn
                    renderColumnHeader={({ id, title, description }, { removeColumn, renameColumn, addCard }) => (
                        <div>
                            {`${title}`}
                            
                            <IconButton
                                aria-label='add'
                                color='info'
                                onClick={() => {
                                    setCreateConfig({
                                        id: null,
                                        parentId: id,
                                        title: null,
                                        description: null,
                                        type: 'children',
                                        createPage: addCard
                                    })
                                    toggleCreateDialog(!isCreateDialogOpen)
                                }} >
                                <AddIcon />
                            </IconButton>

                            <IconButton
                                aria-label='edit'
                                color='info'
                                onClick={() => {
                                    setEditConfig({
                                        id: id,
                                        title: title,
                                        description,
                                        renameColumn: renameColumn
                                    })
                                    
                                    toggleEditDialog(!isEditDialogOpen)
                                }}>
                                <EditIcon />
                            </IconButton>
                            
                            <IconButton
                                aria-label='delete'
                                color='error'
                                onClick={() => {
                                    setDeleteConfig({
                                        id: id,
                                        title: title,
                                        removeColumn: removeColumn,
                                        type: 'parent'
                                    })
                                    toggleDeleteConfirmation(!isDeleteConfirmationOpen)
                                }} >
                                <DeleteIcon />
                            </IconButton>
                            <br />
                            {`${description ? description : ''}`}

                        </div>
                    )}
                    renderColumnAdder={({ addColumn }) => <ColumnAdder addColumn={addColumn} />}
                    renderCard={({ id, title, description, ...others }, { removeCard, dragging }) => {
                        return (
                            <Card sx={{ minWidth: 275, maxWidth: 300 }}>
                                <CardContent>
                                    <Typography variant="h5" component="div">
                                        {title}
                                    </Typography>
                                    <Typography textOverflow={true} variant="body2" color="text.secondary">
                                        Remarks :  {description}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button onClick={e => {
                                        setDeleteConfig({
                                            id: id,
                                            title: title,
                                            removeColumn: removeCard,
                                            type: 'children'
                                        })
                                        toggleDeleteConfirmation(!isDeleteConfirmationOpen)
                                    }
                                    } size="small">Remove Page</Button>
                                </CardActions>
                            </Card>
                        )
                    }}

                    allowRemoveCard
                    allowRenameCard

                    onColumnNew={parentCreatedCallback}
                    onColumnRemove={parentRemoveCallback}
                    onColumnRename={renamingPageCallback}
                    onColumnDragEnd={updateBoardState}

                    onCardNew={childrenCreatedCallback}
                    onCardRemove={childrenRemovedCallback}
                    onCardDragEnd={updateBoardState}

                    initialBoard={{
                        columns: customMenuItems
                    }}
                />

            </div>


            <small style={{
                bottom: 0, padding: '20px', display: 'absolute',
                wordWrap: 'break-word'
            }}>
                <i>
                    Remarks: You are not allow to edit once the page had been created. Please remove the page and re-create it.
                    <br />
                    There is <b>NO UNDO </b> button for the saved configuration, make sure you know what are you doing.
                </i>
            </small>



            <CreateConfirmationDialog
                open={isCreateDialogOpen}
                setOpen={toggleCreateDialog}
                createConfig={createConfig}
                authentication={authentication}
                showNotification={showNotification}
            />


            <EditConfirmationDialog
                open={isEditDialogOpen}
                setOpen={toggleEditDialog}
                editConfig={editConfig}
                authentication={authentication}
                showNotification={showNotification}
            />

            <DeleteConfirmationDialog
                open={isDeleteConfirmationOpen}
                setOpen={toggleDeleteConfirmation} 
                deleteConfig={deleteConfig}
                authentication={authentication}
                showNotification={showNotification}
            />


        </Dialog>
    );
}



export default NavigationDialog;