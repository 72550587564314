import { useState, useEffect } from 'react';
import { Button, Dialog, DialogContent } from '@mui/material'
import { AccessTime, Search } from '@mui/icons-material'
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker';
import moment from 'moment-timezone';


const TimeSelector = props => {

    const { descendingYears, darkMode, disableButton, hideButton, updateDateTime } = props;

    const newDate = new Date();
    const [start, setStart] = useState(moment().subtract(1, 'days'));
    const [end, setEnd] = useState(moment());
    const [selectedStart, setSelectedStart] = useState(moment(new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 0,0,0,0)));
    const [selectedEnd, setSelectedEnd] = useState(moment(start).add(1, 'days').subtract(1, 'seconds'));

    useEffect(() => {
        updateDateTime(start, end);
    }, [start, end]);

    const [dialogOpened, setDialogOpened] = useState(false);

    const ranges = {
        'Last 2 Days': [moment().subtract(2, 'days'), moment()],
        'Last 7 Days': [moment().subtract(7, 'days'), moment()],
        'Last 30 Days': [moment().subtract(30, 'days'), moment()],
        'Last 90 Days': [moment().subtract(90, 'days'), moment()],
        'Last 6 Months': [moment().subtract(6, 'months'), moment()],
        'Last 1 Year': [moment().subtract(1, 'year'), moment()],
        'Yesterday': [moment(new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 0,0,0,0)).subtract(1, 'day'), moment(new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 23,59,59,999)).subtract(1, 'day')],
        'This Day Last Week': [moment(new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 0,0,0,0)).subtract(7, 'days'), moment(new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 23,59,59,999)).subtract(7, 'days')],
        'Previous Week': [moment(new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 0,0,0,0)).isoWeekday(-6), moment(new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 23,59,59,999)).isoWeekday(0)],
        'Previous Month': [moment(new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 0,0,0,0)).subtract(1, 'month').date(1), moment(new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 23,59,59,999)).date(0)],
        'Previous Year': [moment(new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 0,0,0,0)).subtract(1, 'year').month(0).date(1), moment(new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 23,59,59,999)).subtract(1, 'year').month(12).date(0)],
        'Today': [moment(new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 0,0,0,0)), moment(new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 23,59,59,999))],
        'This Week': [moment(new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 0,0,0,0)).isoWeekday(1), moment(new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 23,59,59,999)).isoWeekday(7)],
        'This Week So Far': [moment(new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 0,0,0,0)).isoWeekday(1), moment()],
        'This Month': [moment(new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 0,0,0,0)).date(1), moment(new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 23,59,59,999)).add(1, 'month').date(0)],
        'This Month So Far': [moment(new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 0,0,0,0)).date(1), moment()],
        'This Year So Far': [moment(new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 0,0,0,0)).month(0).date(1), moment()],
        'Last 15 Minutes': [moment().subtract(15, 'minutes'), moment()],
        'Last 30 Minutes': [moment().subtract(30, 'minutes'), moment()],
        'Last 1 Hour': [moment().subtract(1, 'hour'), moment()],
        'Last 3 Hours': [moment().subtract(3, 'hours'), moment()],
        'Last 6 Hours': [moment().subtract(6, 'hours'), moment()],
        'Last 12 Hours': [moment().subtract(12, 'hours'), moment()],
    };

    const local = {
        'format':'DD-MM-YYYY HH:mm',
        'sundayFirst' : false,
        'days': ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
        'months': [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',],
            'fromDate': 'From Date',
            'toDate': 'To Date',
            'close': 'Close',
            'apply': 'Apply',
            'cancel': 'Cancel'
        };
    
    const dateTimePickerStyling = {
        fromDot: {display: 'none'},
        toDot: {display: 'none'},
        fromDate: {color: 'white', backgroundColor: '#4CBCEA', borderRadius: '0px'},
        toDate: {color: 'white', backgroundColor: '#4CBCEA', borderRadius: '0px'},
        betweenDates: {color: '#005591', backgroundColor: '#F1F5F7'},
        hoverCell: {color: '#00A0E1'},
        customRangeButtons: {color: '#005591', backgroundColor: '#E3EBEF', borderRadius: '0px', fontWeight: '600'},
        customRangeSelected: {color: '#005591', backgroundColor: '#B3CCDE', borderRadius: '0px', fontWeight: '600'},
        standaloneLayout: {display:'flex', maxWidth:'fit-content'}
    };

    const applyCallback = (startDate, endDate) => {
        //console.log('applyCallBack(' + startDate.format('DD-MM-YYYY HH:mm') + ', ' + endDate.format('DD-MM-YYYY HH:mm') + ')');
        setSelectedStart(startDate);
        setSelectedEnd(endDate);
    };

    const rangeCallback = (index, value) => {
        // console.log('rangeCallback(' + index + ', + ' + value + ')');
    };


    const handleSubmit = () => {
        setStart(selectedStart);
        setEnd(selectedEnd);
        updateDateTime(selectedStart, selectedEnd);
        setDialogOpened(false);        
    };

    const buttonStyle = (hideButton) ? ({display: 'none'}) : ({textTransform: 'none', boxShadow: 'none'});

    return (
        <>
            <Button
                id='TimeSelectorButton'
                startIcon={<AccessTime />}
                style={buttonStyle}
                onClick={() => { setDialogOpened(true); }}
                disabled={disableButton}
            >
                <span style={{ paddingTop: '2px' }}>{start.format('DD.MM.YYYY HH:mm')} - {end.format('DD.MM.YYYY HH:mm')}</span>
            </Button>
            
            <div>
                <Dialog
                    open={dialogOpened}
                    BackdropProps={{
                        style: { backgroundColor: 'rgba(64,64,64,0.1)' }
                    }}
                    maxWidth='xl'
                    onClose={() => { setDialogOpened(false); }}
                >
                    <DialogContent>
                        <DateTimeRangeContainer 
                            ranges={ranges}
                            start={start}
                            end={end}
                            local={local}
                            applyCallback={applyCallback}
                            rangeCallback={rangeCallback}
                            style={dateTimePickerStyling}
                            centerMode
                            noMobileMode={(window.innerWidth < 1000) ? false : true}
                            descendingYears={(descendingYears) ? true : false}
                            years={[2010,new Date().getFullYear()]}
                            standalone
                            autoApply
                            darkMode={(darkMode) ? true : false}
                            close={'Close'}
                            apply={'Apply'}
                            cancel={'Cancel'}
                        />
                    </DialogContent>
                    <Button
                        variant='contained'
                        color='secondary'
                        id='LindeButton'
                        startIcon={<Search />}
                        style={{ textTransform: 'none', boxShadow: 'none', height: '50px' }}
                        onClick={() => handleSubmit() }
                    >
                        Start Search
                    </Button>
                </Dialog>
            </div>
        </>
    );
};

export default TimeSelector;