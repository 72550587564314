const Grafana = (
    {   id, 
        widgetRefreshData, 
        width, 
        height, 
        snapshotKey, ...props }) =>{
    
        return <>
            {Boolean(snapshotKey) &&
                <iframe src={"https://demo.grafana.iot.linde-le.com/dashboard/snapshot/"+snapshotKey+"?orgId=1&kiosk=tv"} width={width} height={height} ></iframe>
            }
        </>
    }

export default Grafana;