
import thunkMiddleware from 'redux-thunk';
import { combineReducers, createStore, applyMiddleware, compose } from 'redux';


import globalReducer from 'reducers/global';
import authenticationReducer from 'reducers/authentication';
import customizationReducer from 'reducers/customization';
import notificationReducer from 'reducers/notification';

const reduce = combineReducers({
    authentication: authenticationReducer,
    global: globalReducer,
    customization: customizationReducer,
    notification: notificationReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reduce, composeEnhancers(applyMiddleware(thunkMiddleware)));

export default store;
