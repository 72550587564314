import {
    SHOW_NOTIFICATION,
    CLOSE_NOTIFICATION
} from './constants';


export const closeNotification = () => {
    return {
        type: CLOSE_NOTIFICATION
    };
};

export const showNotification = (msg, intent) => {
    return {
        type: SHOW_NOTIFICATION,
        message: msg,
        intent: intent
    };
};
