import React, { useEffect } from 'react';
import axios from 'axios';

import {
    List,
    ListItem,
    ListItemText,
    Button,
    IconButton,
    Collapse,
    ListSubheader
} from '@mui/material'



import {
    Business as BusinessIcon,
    ExpandLess as ExpandLessIcon,
    ExpandMore as ExpandMoreIcon,
    Assessment as AssessmentIcon,
    Pageview as PageviewIcon
} from '@mui/icons-material';


import { ApiEndpoint } from 'config'


const WorkSpacesSection = ({ menuItems, setMenuItems, currentPage, navigateToPage,
    global,
    updateWorkspaces,
    showNotification,
    ...props }) => {

    //#region onload or onchange
    useEffect(() => {

        if (props.authentication.token.accessToken !== null) {

        }
    }, [props.authentication.token.accessToken])

    useEffect(() => {

        if (global.navigation) {
            updateDrawerList(global.navigation);
        }
    }, [global.navigation]);



    //#endregion



    const updateDrawerList = payload => {

        var nodeArray = [];
        var itemId = 0;

        for (var item in payload) {
            var workSpaceInfo = payload[item]
            var newWorkSpace = newNode(itemId++, workSpaceInfo['name'], [], workSpaceInfo['id'], null, null);

            for (var report in workSpaceInfo['reports']) {
                var ReportInfo = workSpaceInfo['reports'][report]

                var newReport = newNode(itemId++, ReportInfo.name, [],
                    workSpaceInfo['id'], ReportInfo['id'], null);


                for (var page in ReportInfo['pages']) {

                    var PageInfo = ReportInfo['pages'][page]

                    var newPage = newNode(itemId++, PageInfo['displayName'], [],
                        payload[item]['id'], payload[item]['reports'][report]['id'], PageInfo['name']);
                    newReport.childNode.push(newPage);
                }


                newReport.classColor = 'notification-badge notification-badge--green';
                newWorkSpace.childNode.push(newReport);

            }
            nodeArray.push(newWorkSpace);
        }
        setMenuItems(nodeArray);

    }



    const newNode = (id, name, childNode, workspaceId, reportId, pageId) => {
        return {
            id: id,
            name: name,
            workspaceId: workspaceId,
            reportId: reportId,
            pageId: pageId,
            open: false,
            childNode: childNode
        };
    };


    const onListItemExpandClick = (itemId) => {
        let tempMenuItems = [...menuItems];

        for (var siteId in tempMenuItems) {

            tempMenuItems[siteId].open = (tempMenuItems[siteId].id === itemId) ? !tempMenuItems[siteId].open : false;

            for (var deviceId in tempMenuItems[siteId].childNode) {

                tempMenuItems[siteId].childNode[deviceId].open = (tempMenuItems[siteId].childNode[deviceId].id === itemId) ? !tempMenuItems[siteId].childNode[deviceId].open : false;

                if (tempMenuItems[siteId].childNode[deviceId].id === itemId) {
                    tempMenuItems[siteId].open = true;
                }

                for (var sensorId in tempMenuItems[siteId].childNode[deviceId].childNode) {

                    tempMenuItems[siteId].childNode[deviceId].childNode[sensorId].open = (tempMenuItems[siteId].childNode[deviceId].childNode[sensorId].id === itemId) ? !tempMenuItems[siteId].childNode[deviceId].childNode[sensorId].open : false;

                    if (tempMenuItems[siteId].childNode[deviceId].childNode[sensorId].id === itemId) {
                        tempMenuItems[siteId].open = true;
                        tempMenuItems[siteId].childNode[deviceId].open = true;
                    }
                }
            }
        }


        setMenuItems(tempMenuItems);
    };


    // Sub-Menu Level 3 : Pages
    const Pages = (parentItems, workSpaceName, reportName) => {
        return (
            <List>
                {
                    parentItems.map((item) => {
                        return (
                            <React.Fragment key={`Sensor-${item.id}`}>
                                <ListItem id={'drawer-list-item'} className={currentPage === item.id ? 'is-active drawer-menu-item-level-3' : 'drawer-menu-item-level-3'}>
                                    <Button id={'drawer-button'} fullWidth onClick={() => {
                                        navigateToPage(`report/${item.workspaceId}/${item.reportId}/${item.pageId}`, item.name);
                                    }}>
                                        <PageviewIcon />
                                        <ListItemText primary={item.name} className='drawer-menu-item-text' style={{ textTransform: 'none' }} />
                                    </Button>
                                </ListItem>
                            </React.Fragment>
                        );
                    })
                }
            </List>
        );
    };

    // Sub-Menu Level 2 : Reports
    const Reports = (parentItems, workSpaceName) => {
        return (
            <List>
                {
                    parentItems.map((item) => {
                        return (
                            <React.Fragment key={`Device-${item.id}`}>
                                <ListItem id={'drawer-list-item'} className={currentPage === item.id ? 'is-active drawer-menu-item-level-2' : 'drawer-menu-item-level-2'}>
                                    <Button id={'drawer-button'} fullWidth onClick={() => {
                                        navigateToPage(`report/${item.workspaceId}/${item.reportId}`, item.name);
                                    }}>
                                        <AssessmentIcon />
                                        <ListItemText primary={item.name} className='drawer-menu-item-text' style={{ textTransform: 'none' }} />
                                    </Button>
                                    <span className={item.classColor}></span>
                                    <IconButton id={'drawer-button'} onClick={() => { onListItemExpandClick(item.id); }}>
                                        {(item.open) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </IconButton>
                                </ListItem>



                                <Collapse in={item.open} timeout="auto" unmountOnExit>
                                    {Pages(item.childNode, workSpaceName, item.name)}
                                </Collapse>
                            </React.Fragment>
                        );
                    })
                }
            </List>
        );
    };

    // Sub-Menu Level 1 : Workspaces
    const Workspaces = () => {
        return (
            <List>
                <ListSubheader disableSticky={true} style={{ color: '#00A0E1' }}>Workspaces</ListSubheader>
                {
                    menuItems.sort((a, b) => a.name < b.name ? - 1 : Number(a.name > b.name)).map((item) => {
                        return (
                            <React.Fragment key={`site-${item.name}`}>
                                <ListItem id={'drawer-list-item'} className=''>
                                    <Button id={'drawer-button'} fullWidth onClick={() => { onListItemExpandClick(item.id); }}>
                                        <BusinessIcon />
                                        <ListItemText primary={item.name} className='drawer-menu-item-text' />
                                    </Button>
                                    <span className={item.classColor}></span>
                                    <IconButton id={'drawer-button'} onClick={() => { onListItemExpandClick(item.id); }}>
                                        {(item.open) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </IconButton>
                                </ListItem>

                                <Collapse in={item.open} timeout="auto" unmountOnExit>
                                    {Reports(item.childNode, item.name)}
                                </Collapse>
                            </React.Fragment>
                        );
                    })
                }
            </List>
        );
    };


    return Workspaces();
};



export default WorkSpacesSection;
