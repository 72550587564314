import { useEffect, useState, useRef, createRef } from 'react';
import axios from 'axios'
import moment from 'moment';
import Plotly from 'plotly.js-dist-min';
import createPlotlyComponent from 'react-plotly.js/factory';

import { styled } from '@mui/material/styles';

import {
    Box,
    Button,
    Grid,
    Slider
} from '@mui/material'

import { ApiEndpoint, KUSTO_CLUSTER, KUSTO_DATABASE, KUSTO_TABLE } from 'config';
import Loader from 'components/loader';

const Plot = createPlotlyComponent(Plotly);

const Spectrogram = ({ id, workspaceId, widgetRefreshData, showNotification, authentication, 
    lineColor, backgroundColor, layoutColor, isTransparent, selectedTags, tagList, width, height, ...props }) => {

    const [isLoading, setIsLoading] = useState(false)
    const [revision, setRevision] = useState(0)

    const [value, setValue] = useState([0,1])
    const [chartType, setChartType] = useState(true)
    const [chartTitle, setChartTitle] = useState(`Spectra: ${value[0]} - ${value[1]} / ${moment().subtract(30, 'seconds').format('DD.MM.YYYY HH:mm:ss A')} - ${moment().format('DD.MM.YYYY HH:mm:ss A')}`)

    const [timestampList, setTimestampList] = useState([])

    // handle component on mounted
    useEffect(() => {
        
    }, []);

    useEffect(() => {
        console.log('selectedTags >>> ', selectedTags)
        console.log('selectedTags length >>> ', selectedTags?.length)
        setRevision(revision+1)

        if (selectedTags?.length > 0) {
            getAvailableTimestamps()
            getData()
        }
    }, [selectedTags])

    const [xData, setXData] = useState([])
    const [yData, setYData] = useState([])

    const getData = async () => {
        setIsLoading(true)
        let cnt = (value[1]-value[0] > 1) ? value[1]-value[0] : 2
        await axios.post(`${ApiEndpoint.Kusto.GetSpectra}`, null, {
            params: {
                TagName: tagList.find(x => x.id === selectedTags[0]).name, //selectedTagList[x].name
                SensorId: 0,
                Count: cnt
            },
            headers: {
                'authorization': `bearer ${authentication?.token?.kustoToken}`
            }
        }).then(resp => {
            console.log('[FFT] Data > ', resp.data)
            let data = resp.data
            
            let freqStart = data[0].freq_start
            let freqStep = data[0].freq_step
            let timeaxis = []
            let dataY = []

            for (let i=0; i<850; i++) {
                timeaxis.push((i * freqStep) + freqStart)
            }
            setXData(timeaxis)
            
            for (let i=0; i<data.length; i++) {
                let fftY = []
                for (let j=0; j<850; j++) {
                    // if (chartType) {
                    //     fftY.push(Math.sqrt(fftval[i]) * 707.106781186547524)
                    // }
                    // else {
                    //     fftY.push(Math.sqrt(fftval[i]) * 1000)
                    // }
                    fftY.push(Math.sqrt(data[i].values[j]))
                }
                dataY.push(fftY)
            }
            setYData(dataY)
            setRevision(revision+1)
            setIsLoading(false)
        }).catch(err => {
            console.log(err)
            showNotification(err.response?.data?.error ?? err.response?.data?.title, 'danger')
        })
    }

    const getAvailableTimestamps = async () => {
        await axios.get(`${ApiEndpoint.Kusto.GetTimestamps}`, {
            params: {
                TagName: tagList.find(x => x.id === selectedTags[0]).name,
                StartUTCTimestamp: moment(props?.global?.startDateTime).format('YYYY-MM-DDTHH:mm:ss')
            },
            headers: {
                'authorization': `bearer ${authentication?.token?.kustoToken}`
            }
        }).then(resp => {
            let data = resp.data
            setTimestampList(data)
            setChartTitle(`Spectra: ${value[0]} - ${value[1]} / ${moment(data[value[0]]).format('DD.MM.YYYY HH:mm:ss A')} - ${moment(data[value[1]]).format('DD.MM.YYYY HH:mm:ss A')}`)

        }).catch(err => {
            console.log(err)
            showNotification(err.response?.data?.error ?? err.response?.data?.title, 'danger')
        })
    }

//#region Handle Slider Changed

    // Update Chart Title when Slider Value Changed
    useEffect(() => {
        console.log(`Value change to > [${value[0]}, ${value[1]}]`)
        setChartTitle(`Spectra: ${value[0]} - ${value[1]} / ${moment(timestampList[value[0]]).format('DD.MM.YYYY HH:mm:ss A')} - ${moment(timestampList[value[1]]).format('DD.MM.YYYY HH:mm:ss A')}`)
    }, [value])

    const calibrateValue = (val) => {
        let newValue = [0,1]

        if (val[0] === val[1]) {
            if (val[0] === 0) {
                newValue = [0,1]
            }
            else if (val[1] === timestampList.length-1) {
                newValue = [timestampList.length-2, timestampList.length-1]
            }
            else {
                newValue = [val[0], val[1]+1]
            }
        }
        else {
            newValue = val
        }

        return newValue
    }

    useEffect(() => {
        getAvailableTimestamps()
        getData()
    }, [props?.global?.startDateTime, props?.global?.endDateTime])

    const handleSliderChange = (event, val) => {
        setValue(calibrateValue(val))
        getData()
    }

    const handleLeftButton = (event) => {
        if (value[0] > 0) {
            setValue([value[0]-1, value[1]-1])
            getData()
        }
    }

    const handleRightButton = (event) => {
        if (value[1] < 100) {
            setValue([value[0]+1, value[1]+1])
            getData()
        }
    }

//#endregion

//#region useEffect when props changed

    useEffect(() => {
        console.debug(`Refreshing page ${id}`)
        if (true) {
            showNotification('Refresh success!', 'success')
        }
    }, [widgetRefreshData])

    useEffect(() => {
        setRevision(revision+1)
    }, [layoutColor])

    useEffect(() => {
        setRevision(revision+1)
    }, [lineColor])

    useEffect(() => {
        setRevision(revision+1)
    }, [backgroundColor])

    useEffect(() => {
        setRevision(revision+1)
    }, [chartType])

//#endregion
    const PrettoSlider = styled(Slider)({
        color: layoutColor
    });

    return <>
        <div style={{ height: props.height, width: '100%', display: 'block' }}>

            <Box sx={{ flexGrow: 1 }} style={{ backgroundColor: (isTransparent) ? 'transparent' : backgroundColor }}>
                <br />
                <Grid container spacing={2}>

                    {
                        (width < 541 || height < 381) ?
                            <Grid item xs={12} style={{ width: width, height: (height-10), textAlign: 'center' }}>
                                <h2>Widget size is too small</h2>
                            </Grid>
                        :
                            <>
                                <Grid item xs={12} style={{ textAlign: 'left', marginLeft: '1%'}}>
                                    <table style={{ width: '100%' }}>
                                        <tr>
                                            <td style={{ width: '10%' }}>
                                                <div style={{ textAlign: 'left', whiteSpace: 'nowrap'}}>
                                                    <Button 
                                                        variant="contained"
                                                        onClick={() => setChartType(!chartType)}
                                                        style={{ backgroundColor: (chartType) ? 'lightgray' : layoutColor }}
                                                        disabled={(chartType) ? true : false}
                                                    >{'RMS'}</Button>
                                                    <Button 
                                                        variant="contained"
                                                        onClick={() => setChartType(!chartType)}
                                                        style={{ backgroundColor: (chartType) ? layoutColor : 'lightgray' }}
                                                        disabled={(chartType) ? false: true}
                                                    >{'Peak'}</Button>
                                                </div>
                                            </td>
                                            <td></td>
                                        </tr>
                                    </table>
                                </Grid>

                                <Grid item xs={12} style={{ margin: '0 1%' }}>
                                    <table style={{ width: '100%' }}>
                                        <tr>
                                            <td style={{ width: '5%', textAlign: 'center' }}>
                                                <Button 
                                                    variant="contained"
                                                    onClick={handleLeftButton}
                                                    size={'small'}
                                                    style={{ backgroundColor: layoutColor }}
                                                    disabled={(selectedTags?.length > 0) ? false : true}
                                                >{'<'}</Button>
                                            </td>
                                            <td style={{ width: '5%' }}></td>
                                            <td style={{ width: '80%', textAlign: 'center' }}>
                                                {/* <PrettoSlider
                                                    getAriaLabel={() => 'Temperature range'}
                                                    value={value}
                                                    onChange={handleSliderChange}
                                                    valueLabelDisplay="auto"
                                                    // getAriaValueText={valuetext}
                                                /> */}
                                                <PrettoSlider
                                                    defaultValue={[value[0],value[1]]}
                                                    max={timestampList.length-1}
                                                    onChangeCommitted={handleSliderChange}
                                                    valueLabelDisplay="auto"
                                                    disabled={(selectedTags?.length > 0) ? false : true}
                                                    // getAriaValueText={valuetext}
                                                />
                                            </td>
                                            <td style={{ width: '5%' }}></td>
                                            <td style={{ width: '5%', textAlign: 'center' }}>
                                                <Button 
                                                    variant="contained"
                                                    onClick={handleRightButton}
                                                    size={'small'}
                                                    style={{ backgroundColor: layoutColor }}
                                                    disabled={(selectedTags?.length > 0) ? false : true}
                                                >{'>'}</Button>
                                            </td>
                                        </tr>
                                    </table>
                                </Grid>

                                <Grid item xs={12} style={{ paddingTop: '0' }}>
                                    {
                                        (isLoading) ?
                                            <Loader />
                                        :
                                            <Plot useResizeHandler
                                                data={[
                                                    {
                                                        x: (selectedTags?.length > 0) ? xData : [], // xData
                                                        z: (selectedTags?.length > 0) ? yData : [], // scaleZData
                                                        type: 'heatmapgl',
                                                        colorscale: 'Jet',
                                                        hoverinfo: 'text',
                                                        // text: obj.scaleText, // scaleText
                                                        zmin: 0, 
                                                        zmax: 3.1819805153394642,
                                                        colorbar: {
                                                            title: {
                                                                text: `mm/s (${(chartType) ? 'RMS' : 'Peak'})`,
                                                                font: {
                                                                    color: layoutColor
                                                                }
                                                            },
                                                            titleside: 'right',
                                                            tickfont: {
                                                                color: layoutColor
                                                            }
                                                        }
                                                    }
                                                ]}
                                                layout={{
                                                    paper_bgcolor: 'transparent',
                                                    plot_bgcolor: 'transparent',
                                                    title: {
                                                        text: (selectedTags?.length > 0) ? chartTitle : '',
                                                        font: {
                                                            color: layoutColor,
                                                            family: 'LindeDax, Arial, sans-serif'
                                                        }                                    
                                                    },
                                                    autosize: true,
                                                    uirevision: revision,
                                                    datarevision: revision,
                                                    // uirevision: this.state.uirevision,
                                                    xaxis: { title: 'f in Hz', color: layoutColor },
                                                    yaxis: { title: 't', color: layoutColor, showticklabels: false},
                                                    width: width,
                                                    height: (height-130),
                                                    hoverlabel: {
                                                        bgcolor: '#B2E2F6',
                                                        bordercolor: '#005591',
                                                        font: {
                                                            color: '#005591'
                                                        }
                                                    }
                                                }}
                                            />
                                    }
                                </Grid>
                            </>
                    }

                </Grid>
            </Box>

            
        </div>
    </>
};

export default Spectrogram;


