import axios from 'axios'
import { ApiEndpoint } from "config"

import { updateWorkspaces, updateReports } from 'reducers/global/actions'
import { showNotification } from 'reducers/notification/actions'

import store from 'reducers'

export const GetPowerBiWorkspaces = async () => {

    const accessToken = store.getState().authentication?.token?.accessToken
    if (accessToken) {

        await axios
            .get(ApiEndpoint.PowerBi.GetWorkspaces, {
                headers: { 'Authorization': `bearer ${accessToken}` }
            })
            .then(res => {
                store.dispatch(updateWorkspaces(res.data.value))
            })
            .catch(function (error) {
                store.dispatch(showNotification(`Get Workspace Error : ${error.response.data.error ?? error.response.data ?? error.message}`, 'danger'))
            });
    }
}


export const GetPowerBiReports = async (workspaceIndex, workspaceId) => {

    const accessToken = store.getState().authentication?.token?.accessToken
    await axios
        .get(`${ApiEndpoint.PowerBi.GetPowerBiEndpoint}/${workspaceId}/reports`,
            {
                headers: { 'Authorization': `Bearer ${accessToken}` }
            }
        )
        .then(async res => {
            var Reports = []
            Reports = res.data.value;

            for (var rsItem in Reports) {
                let pages = await GetPowerBiReportPages(workspaceId, Reports[rsItem].id)
                Reports[rsItem]['pages'] = pages
            }
            store.dispatch(updateReports(workspaceIndex, Reports))
        })
        .catch(function (error) {
            store.dispatch(showNotification(error.message ?? error.response.data, 'danger'))
        });
}


const GetPowerBiReportPages = async (workspaceId, reportId) => {
    const accessToken = store.getState().authentication?.token?.accessToken
    var pages = []
    try {
        await axios
            .get('api/PowerBi/' + workspaceId + '/reports/' + reportId + '/pages',
                {
                    headers: { 'Authorization': `Bearer ${accessToken}` }
                }
            )
            .then(res => {
                pages = res.data.value;
            })
            .catch(function (error) {
                store.dispatch(showNotification(error.message ?? error.response.data, 'danger'))
            });
    } catch (err) {
        console.error(err)
    }
    return pages
}