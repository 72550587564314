import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

const ConfirmDialog = (props) => {
    const { title, children, open, setOpen, onConfirm} = props;
    return(
        <Dialog
        open={open}
        onClose={() => setOpen(false)}
        >

        <DialogTitle id="confirm-dialog">{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
            <Button 
                variant="contained"
                onClick={() => setOpen(false)}
            >No</Button>
            <Button 
                variant="contained"
                onClick={() => {
                    setOpen(false);
                    onConfirm();
                  }}
                  >
                Yes
            </Button>
        </DialogActions>
        </Dialog>
    )
}

export default ConfirmDialog;