import { useEffect, useState } from "react";
import axios from 'axios'
import moment from "moment";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useParams } from "react-router-dom";
import { useBeforeunload } from 'react-beforeunload'
import { Responsive, WidthProvider } from "react-grid-layout";

import './index.css'
import useMousetrap from './utils/Mousetrap'
import WidgetItemContainer from "./widgetItemContainer"
import PageConfigDialog from "./widgetLayoutFunction/pageConfigDialog";
import ImportWidgetDialog from "./widgetLayoutFunction/importWidgetDialog";
import SaveAllWidgetDialog from "./widgetLayoutFunction/saveAllWidgetDialog";
import { ApiEndpoint } from "config";
import { GetAllNavigation } from "actions";

const ResponsiveGridLayout = WidthProvider(Responsive);
const layoutBreakpoints = ['lg', 'md', 'sm', 'xs', 'xxs']

function getFromLS(key) {
    let ls = {};
    if (global.localStorage) {

        try {
            ls = JSON.parse(global.localStorage.getItem(key)) || {};
        } catch (e) {

        }
    }
    return ls[key];
}

function saveToLS(key, value) {
    if (global.localStorage) {
        global.localStorage.setItem(
            key,
            JSON.stringify({
                [key]: value
            })
        );
    }
}

const Dashboard = props => {

    let params = useParams();

    // This pageId will be used to getting the layouts and layoutItems
    let pageId = params?.pageId;

    const [isEditMode, setEditMode] = useState(false)
    const [isImportDialogOpen, toggleImportDialog] = useState(false)
    const [isSaveAllDialogOpen, toggleSaveAllDialog] = useState(false)
    const [isPageConfigDialogOpen, togglePageConfigDialog] = useState(false)
    const [pageConfig, setPageConfig] = useState({
        pageTitle: '',
        pageDesc: '',
        pageIcon: 'assessment',
        compactType: 'vertical',
        rowHeight: 10,
        blDateTimePicker: false,
        blPageScrolling: true,
        autoRefreshPeriod: 0
    })

    const [layouts, setLayouts] = useState({
        lg: [],
        md: [],
        sm: [],
        xs: [],
        xxs: []
    })
    const [layoutItems, setLayoutItems] = useState({})
    const [breakPoint, setBreakPoint] = useState('lg')

    const [removeConfirmation, setRemoveConfirmation] = useState('')
    const [saveAllWidget, triggerAllWidgetSave] = useState(false)

    const [importedJson, setImportedJson] = useState({})
    const [importing, setImporting] = useState(false)
    const [refreshHook, setRefreshHook] = useState(0)
    const [refreshConfigHook, setRefreshConfigHook] = useState(0)

    const [isCreatingNewWidget, setCreateNewWidget] = useState(false)
    const [isSaveLayoutLoading, setSaveLayoutLoading] = useState(false)

    useMousetrap("ctrl+shift+l", () => { 
            // any mousetrap combo, or array of combo
            // triggered on key combo
            changeMode()
    });

    useBeforeunload((event) => {
        if (isEditMode) {
            event.preventDefault();
            return 'Make sure you had save all your date!'
        }
    })

    useEffect(() => {

        // When the custom dashboard is loaded
        if(props.authentication.token.accessToken){
            
        getSettingsFromDatabase()
    }
    }, [pageId, props.authentication?.token?.accessToken])

    useEffect(() => {
        // When importing is true then we need to save one set of page configuration 
        if (importing) {
            savePageConfig()
        }
    }, [pageConfig])

    useEffect(() => {
        // Trigger when user confirm to remove the widget
        if (removeConfirmation) {
            console.debug(`removing ${removeConfirmation}`)
            removeWidgetFromDatabase()
        }
    }, [removeConfirmation])

    useEffect(() => {
        // Use to set up auto refresh interval
        if (pageConfig?.autoRefreshPeriod > 0) {
            console.debug(`Enabling auto refresh for page : ${pageId} . Every ${pageConfig?.autoRefreshPeriod} minutes `)
            const interval = setInterval(() => {
                setRefreshHook(time => time + 1)
                console.debug(`Refresh all widget now`)
            }, pageConfig?.autoRefreshPeriod * 1000 * 60)


            return () => {
                console.debug(`Disabling auto refresh for page : ${pageId}  `)
                clearInterval(interval)
            }

        } else {
            console.debug(`Auto refresh feature did not setup `)
        }
    }, [pageConfig?.autoRefreshPeriod])

    useEffect(() => {
        // Use to toggle datetime picker
        props.toggleDateTimePicker(pageConfig?.blDateTimePicker)
    }, [pageConfig?.blDateTimePicker])

    const getSettingsFromDatabase = async () => {
        // 1. Get the page config 
        // 2. Get the page widget 
        // 3. Get the page widget layout

        // Getting the page Configuration 
        await axios.get(`${ApiEndpoint.Page.GetPageDetails}/${pageId}`, {
            headers: {
                'authorization': `bearer ${props.authentication.token.accessToken}`
            }
        }).then(resp => {


            var respConfig = {
                pageTitle: resp.data.title,
                pageDesc: resp.data.description,
                pageIcon: resp.data.pageIcon,
                compactType: resp.data.compactionType !== 'none' ? resp.data.compactionType : null,
                rowHeight: resp.data.rowHeight,
                blDateTimePicker: resp.data.isDtPickerEnabled,
                blPageScrolling: resp.data.isScrollingEnabled,
                autoRefreshPeriod: resp.data.autoRefresh
            }
            setPageConfig(respConfig)
            console.debug(respConfig)

        })

        // Getting the page Widget
        await axios.get(`${ApiEndpoint.Widget.GetAllWidgets}/${pageId}`, {
            headers: {
                'authorization': `bearer ${props.authentication.token.accessToken}`
            }
        }).then(resp => {
            var widgetsData = resp.data;
            for (var widgetData in widgetsData) {
                widgetsData[widgetData].advance = JSON.parse(widgetsData[widgetData].advance)
            }
            console.debug(widgetsData)
            setLayoutItems(widgetsData)

        })

        // Getting the page widget layout 
        await axios.get(`${ApiEndpoint.Widget.GetAllWidgetLayouts}/${pageId}`, {
            headers: {
                'authorization': `bearer ${props.authentication.token.accessToken}`
            }
        }).then(resp => {
            var widgetLayouts = resp.data;
            setLayouts(widgetLayouts)
            console.debug(widgetLayouts)

        })

    }

    const saveLayoutToDatabase = async () => {
        console.log('saving layouts', layouts)

        setSaveLayoutLoading(true)
        axios.put(`${ApiEndpoint.Widget.SaveAllWidgetLayouts}/${pageId}`, layouts, {
            headers: {
                authorization: `bearer ${props.authentication.token.accessToken}`
            }
        }).then(resp => {
            props.showNotification(`Page ID : ${pageId} Layout successfully saved into database `)
        }).catch(err => {
            props.showNotification(`Error saving Page ID : ${pageId} Layout into database. Please refresh the browser.`, 'warning')

        }).finally(() => {

            setSaveLayoutLoading(false)

        })
        // console.log('saving items', layoutItems)
        // Local Saving purpose only
        // saveToLS(pageId, {
        //     items: layoutItems,
        //     layouts: layouts,
        //     pageConfig: pageConfig
        // })
    }

    const savePageConfig = async () => {


        axios.patch(`${ApiEndpoint.Page.UpdatePageBasic}/${pageId}`, [
            {
                "op": "replace",
                "path": "/Title",
                "value": pageConfig.pageTitle
            },
            {
                "op": "replace",
                "path": "/Description",
                "value": pageConfig.pageDesc
            },
            {
                "op": "replace",
                "path": "/PageIcon",
                "value": pageConfig.pageIcon
            },
            {
                "op": "replace",
                "path": "/CompactionType",
                "value": pageConfig.compactType
            },
            {
                "op": "replace",
                "path": "/RowHeight",
                "value": pageConfig.rowHeight
            },
            {
                "op": "replace",
                "path": "/IsDtPickerEnabled",
                "value": pageConfig.blDateTimePicker
            },
            {
                "op": "replace",
                "path": "/IsScrollingEnabled",
                "value": pageConfig.blPageScrolling
            },
            {
                "op": "replace",
                "path": "/AutoRefresh",
                "value": pageConfig.autoRefreshPeriod
            },

        ]
            , {
                headers: {
                    "Authorization": "bearer " + props?.authentication?.token?.accessToken
                }
            }).then(resp => {
                props.showNotification('Page Config Updated', 'success')
                GetAllNavigation()
                togglePageConfigDialog(false)
            }).catch(err => {
                console.debug(err.response)
                props.showNotification(err.response?.data?.error ?? err.response?.data?.title, 'danger')
            })
    }

    const addNewWidget = async () => {
        // Getting the page Configuration 

        setCreateNewWidget(true)

        var newId = ''
        await axios.post(`${ApiEndpoint.Widget.CreateWidget}`, {
            pageId: pageId,
            type: null,
            title: `New Widget`,
            titleColor: `white`,
            backgroundColor: `#121212`,
            titleSize: `1.2`,
            titleUnit: `rem`,
            isWidgetStatic: false,
            IsWidgetHeaderShow: true,
            configuration: '{}'
        }, {
            headers: {
                'authorization': `bearer ${props.authentication.token.accessToken}`
            }
        }).then(resp => {

            newId = resp.data.id
            var currentLayout = layouts

            for (var bp in layoutBreakpoints) {
                var lyObj = layoutBreakpoints[bp]
                if (currentLayout[lyObj]) {
                    currentLayout = {
                        ...currentLayout,
                        [lyObj]: [...currentLayout[lyObj],
                        {
                            w: 4,
                            h: 5,
                            x: 0,
                            y: 0,
                            i: newId
                        }]
                    }
                } else {
                    currentLayout = {
                        ...currentLayout,
                        [lyObj]: [{
                            w: 4,
                            h: 5,
                            x: 0,
                            y: 0,
                            i: newId
                        }]
                    }
                }
            }


            // We must set the available items first then only set its layout 
            setLayoutItems({
                ...layoutItems,
                [newId]: {},
            })
            setLayouts(currentLayout)

        }).finally(() => {
            setCreateNewWidget(false)
        })

        return newId
    }

    const updateLayoutItems = (id, general, advance) => {
        setLayoutItems({
            ...layoutItems,
            [id]: {
                general,
                advance
            }
        })
    }

    const removeWidgetFromDatabase = () => {
        var item = layoutItems
        delete item[removeConfirmation]
        setLayoutItems(item)

        var currentLayout = layouts
        for (var lyoObj in currentLayout) {

            if (layouts[lyoObj]) {

                currentLayout = {
                    ...currentLayout,
                    [lyoObj]: layouts[lyoObj].filter(item => item.i !== removeConfirmation)
                }
            }
        }

        setLayouts(currentLayout)
        setRemoveConfirmation('')
    }

    const saveAll = () => {
        triggerAllWidgetSave(true)
        saveLayoutToDatabase()
    }

    const changeMode = () => {
        setEditMode(!isEditMode)
    }

    const handleBreakPointChange = bp => {
        setBreakPoint(bp);
    };

    const handleItemChange = lo => {
        setLayouts({
            ...layouts,
            [breakPoint]: lo
        })
    };

    const loadImportConfiguration = async importConfig => {


        setImporting(true)

        // This action over there import the page config into the database ...
        setPageConfig(importConfig.pageConfig)

        var newImportLayoutItems = {}
        var importLayoutString = JSON.stringify(importConfig.layouts)

        // This action over here deleting all the existing widgets in the page
        Object.keys(layoutItems).map(async id => {
            await axios.delete(`${ApiEndpoint.Widget.DeleteWidget}/${id}`, {
                headers: {
                    authorization: `bearer ${props.authentication.token.accessToken}`
                }
            }).then(resp => {
                props.showNotification(`Successfully removed from database`, 'success')
            }).catch(err => {
                props.showNotification(err.message ?? err.response.data, 'danger')
            })
        })

        // Create layout items , Update item settings &  Prepare the layout string 
        for (var oldId in importConfig.layoutItems) {
            var newId = await addNewWidget()
            console.debug(`new id ${newId}`, `old id ${oldId}`)
            newImportLayoutItems[newId] = importConfig.layoutItems[oldId]
            importLayoutString = importLayoutString.replaceAll(oldId, newId);


            await axios
                .patch(`${ApiEndpoint.Widget.UpdateWidget}/${newId}`, [
                    {
                        "op": "replace",
                        "path": "/Type",
                        "value": newImportLayoutItems[newId].general.type
                    },
                    {
                        "op": "replace",
                        "path": "/Title",
                        "value": newImportLayoutItems[newId].general.title
                    },
                    {
                        "op": "replace",
                        "path": "/TitleColor",
                        "value": newImportLayoutItems[newId].general.titleColor
                    },
                    {
                        "op": "replace",
                        "path": "/BackgroundColor",
                        "value": newImportLayoutItems[newId].general.titleBackground
                    },
                    {
                        "op": "replace",
                        "path": "/TitleSize",
                        "value": newImportLayoutItems[newId].general.fontSize
                    },
                    {
                        "op": "replace",
                        "path": "/IsStatic",
                        "value": false
                    },
                    {
                        "op": "replace",
                        "path": "/IsWidgetHeaderShow",
                        "value": newImportLayoutItems[newId].general.showTitle
                    },
                    {
                        "op": "replace",
                        "path": "/Configuration",
                        "value": JSON.stringify(newImportLayoutItems[newId].advance)
                    },
                ], {
                    headers: {
                        'authorization': `bearer ${props.authentication.token.accessToken}`
                    }
                }).then(resp => {
                    props.showNotification(`Successfully import ${newImportLayoutItems[newId].general.title} into database`, 'success')
                }).catch(err => {
                    props.showNotification(err.message ?? err.response.data, 'danger')
                })

        }

        setSaveLayoutLoading(true)
        await axios.put(`${ApiEndpoint.Widget.SaveAllWidgetLayouts}/${pageId}`, JSON.parse(importLayoutString), {
            headers: {
                authorization: `bearer ${props.authentication.token.accessToken}`
            }
        }).then(resp => {
            props.showNotification(`Page ID : ${pageId} Layout successfully saved into database `)
        }).catch(err => {
            props.showNotification(`Error saving Page ID : ${pageId} Layout into database. Please refresh the browser.`, 'warning')

        }).finally(() => {

            setSaveLayoutLoading(false)

        })

        setLayoutItems(newImportLayoutItems)
        setLayouts(JSON.parse(importLayoutString))
        setImporting(false)
        setRefreshConfigHook(time => time + 1)

    }

    // This use to handle when user adding new widget
    const handleLayoutChange = (layout, lyts) => {
        if (lyts) {
            setLayouts({
                ...lyts
            })
        }
    }


    return (<div style={{ overflow: pageConfig?.blPageScrolling ? 'auto' : 'hidden', height: '105%' }} >

        {isImportDialogOpen &&
            <ImportWidgetDialog pageId={pageId} open={isImportDialogOpen} setOpen={toggleImportDialog}
                importedJson={importedJson} setImportedJson={setImportedJson} loadImportConfiguration={loadImportConfiguration}
                {...props} />}

        {isPageConfigDialogOpen &&
            <PageConfigDialog
                pageId={pageId}
                savePageConfig={savePageConfig}
                open={isPageConfigDialogOpen} setOpen={togglePageConfigDialog}
                pageConfig={pageConfig} setPageConfig={setPageConfig}
                {...props} />}


        {isSaveAllDialogOpen &&
            <SaveAllWidgetDialog pageId={pageId} open={isSaveAllDialogOpen}
                setOpen={toggleSaveAllDialog} saveAll={saveAll} {...props} />}


        {isEditMode && <LoadingButton loading={isCreatingNewWidget} onClick={addNewWidget} variant="contained" style={{ margin: '0.5rem' }} >
            Add new Widget
        </LoadingButton>}

        {isEditMode &&
            <Button variant="contained" style={{ margin: '0.5rem' }} onClick={() => togglePageConfigDialog(!isPageConfigDialogOpen)} >
                Page Configuration
            </Button>}

        {isEditMode &&
            <LoadingButton loading={isSaveLayoutLoading}
                variant="contained" style={{ margin: '0.5rem' }}
                onClick={() => toggleSaveAllDialog(!isSaveAllDialogOpen)} >
                Save All
            </LoadingButton>}

        {isEditMode &&
            <Button variant="contained" style={{ margin: '0.5rem' }} href={
                `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify({
                    layouts: layouts,
                    layoutItems: layoutItems,
                    pageConfig: pageConfig
                }))}`
            } download={`Export_${moment().format("DD-MM-YYYY")}.json`}  >
                Export as Json
            </Button>}

        {isEditMode &&
            <Button variant="contained" style={{ margin: '0.5rem' }} onClick={() => toggleImportDialog(!isImportDialogOpen)} >
                Import Configuration
            </Button>}

        {layouts && <ResponsiveGridLayout
            layouts={layouts}
            autoSize={true}
            compactType={pageConfig?.compactType}
            // compactType={'horizontal'}
            onBreakpointChange={handleBreakPointChange}
            onLayoutChange={handleLayoutChange}
            onResizeStop={handleItemChange}
            onDragStop={handleItemChange}
            isDraggable={isEditMode}
            isRearrangeable={isEditMode}
            isResizable={isEditMode}
            draggableHandle=".widget-item__title"
            breakpoints={{ lg: 1280, md: 992, sm: 767, xs: 480, xxs: 0 }}
            cols={{ lg: 24, md: 20, sm: 12, xs: 8, xxs: 4 }}
            rowHeight={pageConfig?.rowHeight}>

            {Object.keys(layoutItems).map(id => {
                return <WidgetItemContainer
                    key={id}
                    id={id}
                    importing={importing}
                    importConfig={layoutItems[id]}
                    isEditMode={isEditMode}
                    setRemoveConfirmation={setRemoveConfirmation}
                    saveAllWidget={saveAllWidget}
                    triggerAllWidgetSave={triggerAllWidgetSave}
                    getFromLS={getFromLS}
                    saveToLS={saveToLS}
                    updateLayoutItems={updateLayoutItems}
                    refreshHook={refreshHook}
                    refreshConfigHook={refreshConfigHook}
                    {...props} />
            })}
        </ResponsiveGridLayout>}

    </div>);
};

export default Dashboard;
