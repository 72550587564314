import { useEffect, useState } from 'react';

const Label = ({ id, workspaceId, widgetRefreshData, showNotification, authentication, 
    title, fontSize, layoutColor, backgroundColor, width, height, isTransparent, ...props }) => {

    // handle component on mounted
    useEffect(() => {
        
    }, []);

    useEffect(() => {
        console.debug(`Refreshing page ${id}`)
        if (true) {
            showNotification('Refresh success!', 'success')
        }
    }, [widgetRefreshData])   

    return <>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', backgroundColor: (isTransparent) ? 'transparent' : backgroundColor, width: width, height: height }}>
            <div style={{ color: layoutColor, fontSize: fontSize+'px' }}>{title}</div>
        </div>        
    </>
};

export default Label;


