import React, { useEffect, useState } from 'react';

import {
    List,
    ListItem,
    ListItemText,
    Button,
    IconButton,
    Drawer,
    Divider,
    ListSubheader
} from '@mui/material'


import HomeIcon from '@mui/icons-material/Home';
import DescriptionIcon from '@mui/icons-material/Description';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';


import ConfigDialog from './NavigationDialog/NavigationDialog'

import WorkSpacesSection from './WorkSpacesSection'
import CustomNavigationSection from './CustomNavigationSection'

import { GetAllNavigation, GetPowerBiWorkspaces, GetPowerBiReports } from 'actions'




const DrawerMenu = ({ global, authentication, ...props }) => {



    //#region Drawer Function 

    const [isConfigDialogOpen, setConfigDialogOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(-1);
    const [openDrawer, setOpenDrawer] = useState(false);

    const [menuItems, setMenuItems] = useState([]);



    const toogleConfigDialog = () => {
        setConfigDialogOpen(!isConfigDialogOpen);
    };

    //#endregion




    useEffect(() => {

        if (global?.customNavigation?.length === 0 && authentication?.token?.accessToken) {
            GetAllNavigation()
            GetPowerBiWorkspaces()
        }

    }, [authentication])


    useEffect(() => {

        if (global.navigation.length > 0) {
            var workspaces = global.navigation;
            for (var wsItem in workspaces) {
                // Checking to prevent unlimited loop
                if (!workspaces[wsItem].reports) {
                    GetPowerBiReports(wsItem, workspaces[wsItem].id)
                }
            }
        }
    }, [global.navigation])



    useEffect(() => {
        console.debug(global.customNavigation)
    }, [global.customNavigation])


    const navigateToPage = (location, pageId) => {
        setOpenDrawer(!openDrawer);
        setCurrentPage(pageId);
        window.open('#/' + location, '_self');
    };

    return (
        <>

            <ConfigDialog
                authentication={authentication}
                customMenuItems={global.customNavigation}
                setCustomMenuItems={props.updateNavigation}
                open={isConfigDialogOpen}
                toogleConfigDialog={toogleConfigDialog}
                {...props} />
            <IconButton
                aria-label='menu'
                color='inherit'
                edge='start'
                onClick={() => setOpenDrawer(true)}
                style={{ marginLeft: '1rem' }}
            >
                <MenuIcon className='drawer-menu-icon' />
            </IconButton>
            <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
                <List id='drawer-menu' subheader={<ListSubheader disableSticky={true} style={{ color: '#00A0E1' }}>General</ListSubheader>}>
                    <ListItem id={'drawer-list-item'} className={currentPage === -1 ? 'is-active' : ''}>
                        <Button id={'drawer-button'} fullWidth onClick={() => { navigateToPage('', -1); }}>
                            <HomeIcon />
                            <ListItemText primary='Home' className='drawer-menu-item-text' />
                        </Button>
                    </ListItem>

                    <ListItem id={'drawer-list-item'} className={currentPage === -2 ? 'is-active' : ''}>
                        <Button id={'drawer-button'} fullWidth onClick={() => {
                            navigateToPage('Manual', -2);
                        }}>
                            <DescriptionIcon />
                            <ListItemText primary='Manual' className='drawer-menu-item-text' />
                        </Button>
                    </ListItem>

                    <Divider />
                    <WorkSpacesSection
                        global={global}
                        menuItems={menuItems}
                        setMenuItems={setMenuItems}
                        currentPage={currentPage}
                        navigateToPage={navigateToPage}
                        authentication={authentication}
                        {...props} />

                    <Divider />
                    <CustomNavigationSection
                        navigateToPage={navigateToPage}
                        customMenuItems={global.customNavigation}
                        setCustomMenuItems={props.updateNavigation}
                        authentication={authentication}
                        {...props} />
                </List>



                {/* Drawer Footer */}
                <List id='drawer-menu' style={{ marginTop: `auto` }} subheader={<ListSubheader disableSticky={true} style={{ color: '#00A0E1' }}></ListSubheader>}>
                    <ListItem id={'drawer-list-item'}>
                        <Button id={'drawer-button'} fullWidth onClick={toogleConfigDialog}>
                            <SettingsIcon />
                            <ListItemText primary='Configure Navigation' className='drawer-menu-item-text' />
                        </Button>
                    </ListItem>
                </List>
                <List id='drawer-menu' subheader={<ListSubheader disableSticky={true} style={{ color: '#00A0E1' }}></ListSubheader>}>
                    <ListItem id={'drawer-list-item'}>
                        <Button id={'drawer-button'} fullWidth onClick={() => {
                                navigateToPage('tagManagement', -2);
                            }}>
                            <SettingsIcon />
                            <ListItemText primary='Tag Management' className='drawer-menu-item-text' />
                        </Button>
                    </ListItem>
                </List>
            </Drawer>
        </>
    );
};



export default DrawerMenu;
