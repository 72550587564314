import React, { useEffect, useState } from 'react';

import {
    List,
    ListItem,
    ListItemText,
    Button,
    Collapse,
    ListSubheader,
    Icon
} from '@mui/material'

import {
    Business as BusinessIcon,
    ExpandLess as ExpandLessIcon,
    ExpandMore as ExpandMoreIcon,
    Assessment as AssessmentIcon
} from '@mui/icons-material';

import { GetAllNavigation } from 'actions'

const CustomNavigationSection = ({navigateToPage, customMenuItems, global, setCustomMenuItems, ...props}) => {

    const [currentPage, setCurrentPage] = useState(-1);

    //#region onload or onchange

    useEffect(() => {
        if (props.authentication.token.accessToken !== null) {

        }

    }, [props.authentication.token.accessToken])

    //#endregion

    const onNavigationClickToRefresh = () => {
        GetAllNavigation()
    }

    const onCustomListItemExpandClick = (itemId) => {
        let tempMenuItems = [...customMenuItems];
        for (var siteId in tempMenuItems) {

            tempMenuItems[siteId].open = (tempMenuItems[siteId].id === itemId) ? !tempMenuItems[siteId].open : false;

            for (var deviceId in tempMenuItems[siteId].childNode) {

                tempMenuItems[siteId].childNode[deviceId].open = (tempMenuItems[siteId].childNode[deviceId].id === itemId) ? !tempMenuItems[siteId].childNode[deviceId].open : false;

                if (tempMenuItems[siteId].childNode[deviceId].id === itemId) {
                    tempMenuItems[siteId].open = true;
                }
            }
        }


        setCustomMenuItems(tempMenuItems);
    };


    // Sub-Menu Level 2 : customPages
    const customPages = (parentItems, workSpaceName) => {
        return (
            <List>
                {   parentItems && parentItems.map((item) => {
                        return (
                            <React.Fragment key={`Device-${item.id}`}>
                                <ListItem id={'drawer-list-item'} className={currentPage === item.id ? 'is-active drawer-menu-item-level-2' : 'drawer-menu-item-level-2'}>
                                    <Button id={'drawer-button'} fullWidth onClick={() => {
                                        navigateToPage(`custom/${item.id}`, item.name);
                                    }}>

                                        
                                        <Icon>
                                            {item.pageIcon}
                                            </Icon>
                                        <ListItemText primary={item.title} className='drawer-menu-item-text' style={{ textTransform: 'none' }} />
                                    </Button>
                                    <span className={item.classColor}></span>
                                </ListItem>
                            </React.Fragment>
                        );
                    })
                }
            </List>
        );
    };

    // Sub-Menu Level 1 : Navigation
    const customNavigation = () => {
        return (
            <List>
                <ListSubheader disableSticky={true} style={{ color: '#00A0E1' }}><div onClick={onNavigationClickToRefresh}>Navigation</div></ListSubheader>
                {
                    // Sort is not required as it follow the array index
                    // .sort((a, b) => a.name < b.name ? - 1 : Number(a.name > b.name))
                    customMenuItems.map((item) => {
                        return (
                            <React.Fragment key={item.id}>
                                <ListItem id={'drawer-list-item'} className=''>
                                    <Button id={'drawer-button'} fullWidth onClick={() => {
                                        onCustomListItemExpandClick(item.id);
                                    }}>
                                        <BusinessIcon />
                                        <ListItemText primary={item.title} className='drawer-menu-item-text' />

                                        {(item.open) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </Button>

                                </ListItem>

                                <Collapse in={item.open} timeout="auto" unmountOnExit>
                                    {customPages(item.cards, item.title)}
                                </Collapse>

                            </React.Fragment>
                        )

                    })
                }
            </List>
        );
    };

    return (
        <>
            {customNavigation()}
        </>
    );
};



export default CustomNavigationSection;
