export default [
    {
        label: "Line Chart",
        value: "line"
    },
    {
        label: "Button",
        value: "button"
    },
    {
      label: "Label",
      value: "label"
    },
    // {
    //     label: "Surface Chart",
    //     value: "surfaceChart"
    // },
    {
        label: "Image",
        value: "image"
    },
    {
        label: "Pie Chart",
        value: "pie"
    },
    {
        label: "Scatter",
        value: "scatter"
    },
    {
        label: "Profile Chart",
        value: "area"
    },
    {
        label: "Bar",
        value: "bar"
    },
    {
        label: "Power BI",
        value: "power-bi"
    },
    {
        label: "Kusto",
        value: "kusto"
    },
    {
        label: "FFT",
        value: "fft"
    },
    {
        label: "Spectrogram",
        value: "spectrogram"
    },
    {
        label: "Waterfall",
        value: "waterfall"
    },
    {
        label: "Scorecard",
        value: "scorecard"
    },
    {
        label: "Grafana",
        value: "grafana"
    },
    {
        label: "None",
        value: null
    }
];
