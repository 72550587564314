import { useState, useEffect } from 'react'
import { Sparklines, SparklinesLine  } from 'react-sparklines';

import axios from 'axios'
import moment from 'moment';
import { ApiEndpoint } from 'config';

const Scorecard = ({ id, widgetRefreshData, showNotification, authentication, 
    backgroundColor, fontSize, selectedTagValue, selectedReducerFunctionIndex,
    width, height, textSize, kustoToken, ...props }) => {
    
      const [data, setData] = useState([])
      
      useEffect(async () => {
        if(selectedTagValue == undefined)
            return
        await getData()
      }, [selectedTagValue, props?.global?.startDateTime, props?.global?.endDateTime])

      const getData = async () => {
        await axios.get(`${ApiEndpoint.Kusto.GetValuesBetweenTimestamps}`, {
            params: {
                TagName: selectedTagValue,
                StartUTCTimestamp: moment(props?.global?.startDateTime).format('YYYY-MM-DDTHH:mm:ss'),
                EndUTCTimestamp: moment(props?.global?.endDateTime).format('YYYY-MM-DDTHH:mm:ss'),
                Count: 1000
            },
          headers: {
              'authorization': `bearer ${kustoToken}`
          }
      }).then(resp => {
          let data = resp.data
          let scorecardData = []

          for (let i=0; i<data.length; i++) {
              scorecardData.push(
                data[i].Value
              )
          }

            setData(scorecardData);
          
        }).catch(err => {
            showNotification(err.response?.data?.error ?? err.response?.data?.title, 'danger')
        })
      }

        const sparklines = () => (
            <Sparklines data={data} limit={data.length} width={width} height={height} margin={15}>
                <SparklinesLine color="blue" />
            </Sparklines>
        )

        const setReducerFunctionValue = (value) => {
            switch(value){
                case 1:
                    const sum = data.reduce((partialSum, a) => partialSum + a, 0);
                    var meanValue = sum / data.length;
                    return meanValue
                case 2:
                    return data[data.length - 1]
                case 3:
                    return data[0]
                case 4:
                    return Math.min(...data)
                case 5:
                    return Math.max(...data)
                default:
                    break;
            }
        }

        return <>
            <div style={{
                width: width, 
                height: '25px',
                padding: '10px',
                display: 'flex',
                justifyContent: 'center', 
                alignItems: 'center',
                backgroundColor: backgroundColor,
                fontSize: `${textSize}px`
            }}>
             {setReducerFunctionValue(selectedReducerFunctionIndex)}</div>
             { data.length > 0 ? sparklines() : null}
        </>
    }

export default Scorecard;