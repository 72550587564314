import axios from 'axios'
import { ApiEndpoint } from "config"
import { showNotification } from 'reducers/notification/actions'
import store from 'reducers'

export const AddTag = async (tag) => {
  const accessToken = store.getState().authentication?.token?.accessToken
  if (accessToken) {
    await axios.post(`${ApiEndpoint.Tag.AddTag}`, tag, {
      headers: {
        authorization: `bearer ${accessToken}`
      }
    }).then(resp => {
      store.dispatch(showNotification('Tag Saved Successfully', 'success'))
    }).catch(err => {
      store.dispatch(showNotification(`Error saving Tag Id: ${tag.id} into database. Please refresh the browser.`, 'warning'))
    })
  }
}

export const GetAllTags = async (setTags) => {
  const accessToken = store.getState().authentication?.token?.accessToken
  if (accessToken) {
    await axios.get(`${ApiEndpoint.Tag.GetAllTags}`, {
      headers: {
        'authorization': `bearer ${accessToken}`
      }
    }).then(resp => {
      setTags(resp.data)
    }).catch(err => {
      store.dispatch(showNotification(err.message ?? err.response.data, 'danger'))
    })
  }
}

export const UpdateTag = async (tag, setTags) => {
  const accessToken = store.getState().authentication?.token?.accessToken
  if (accessToken) {
    await axios.put(`${ApiEndpoint.Tag.UpdateTag}`, tag, {
      headers: {
        'authorization': `bearer ${accessToken}`
      }
    }).then(resp => {
      store.dispatch(showNotification('Tag Updated Successfully', 'success'))
    }).catch(err => {
      store.dispatch(showNotification(err.message ?? err.response.data, 'danger'))
    })
  }
}

export const DeleteTag = async (id, setTags) => {
  const accessToken = store.getState().authentication?.token?.accessToken
  if (accessToken) {
    await axios.delete(`${ApiEndpoint.Tag.DeleteTag}/${id}`, {
      headers: {
        'authorization': `bearer ${accessToken}`
      }
    }).then(resp => {
      store.dispatch(showNotification('Tag Deleted Successfully', 'success'))
    }).catch(err => {
      store.dispatch(showNotification(err.message ?? err.response.data, 'danger'))
    }).finally(() => {
      GetAllTags(setTags)
    })
  }
}

export const ImportScvData = async (data) => {
  data.shift()
  const tags = [];
  data.forEach(element => {
    const tag = {}
    tag.name = element[0]
    tag.description = element[1]
    tag.unit = element[2]
    tag.site = element[3]
    tag.region = element[4]
    tag.plant = element[5]
    tag.maxLimit = element[6]
    tag.minLimit = element[7]
    tags.push(tag)
  });

  const accessToken = store.getState().authentication?.token?.accessToken
  if (accessToken) {
    await axios.post(`${ApiEndpoint.Tag.ImportScvData}`, tags, {
      headers: {
        authorization: `bearer ${accessToken}`
      }
    }).then(resp => {
      store.dispatch(showNotification('Csv data saved Successfully', 'success'))
    }).catch(err => {
      console.log(err);
      store.dispatch(showNotification(`Error saving csv data into database. Please refresh the browser.`, 'warning'))
    })
  }
}