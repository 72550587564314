import {
    SET_TITLE,
    SET_BREAKPOINT,
    SET_DATA,
    SET_LAYOUT,
    SET_TYPE
} from './constants'


export const setType = ({ key, value, item }) => dispatch => {
    console.log(SET_TYPE)
    dispatch({
        type: SET_TYPE,
        payload: { key, value, item }
    });
};

export const setTitle = ({ key, value, item }) => dispatch => {
    console.log(SET_TITLE)
    dispatch({
        type: SET_TITLE,
        payload: { key, value, item }
    });
};

export const setData = ({ key, value, item }) => dispatch => {
    console.log(SET_DATA)
    dispatch({
        type: SET_DATA,
        payload: { key, value, item }
    });
};

export const setLayout = (layout, root) => (dispatch, getState) => {
    console.log(SET_LAYOUT)
    dispatch({
        type: SET_LAYOUT,
        payload: { layout, root, breakpoint: getState().breakpoint }
    });
};

export const setBreakPoint = breakpoint => dispatch => {
    dispatch({
        type: SET_BREAKPOINT,
        payload: breakpoint
    });
};
