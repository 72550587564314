import React, { useEffect, useState } from "react";
import axios from 'axios'
import Plotly from 'plotly.js-dist-min';
import createPlotlyComponent from 'react-plotly.js/factory';
import { ApiEndpoint } from 'config';
import { KUSTO_CLUSTER, KUSTO_DATABASE, KUSTO_TABLE } from 'config';
import {
    AreaChart,
    XAxis,
    YAxis,
    Area,
    ResponsiveContainer,
    Tooltip,
    CartesianGrid,
    Legend
} from 'recharts';
import { rawdata } from './rawdata';
import { Card, CardContent } from "@mui/material";

const Plot = createPlotlyComponent(Plotly);

const AreaGraph = ({ id, pageName, widgetRefreshData, showNotification, authentication, width, height,
    config, layoutColor, backgroundColor, kustoToken, isTransparent,
    legendOrientation, tagList, selectedTags, ...props }) => {

    const [revision, setRevision] = useState(0)

    const [tempProfileBlocks, setTempProfileBlocks] = useState([]);
    const [realTempProfileBlocks, setRealTempProfileBlocks] = useState([]);

    useEffect(() => {
        if (selectedTags && selectedTags.length > 0) {
            getData()
        }
        else {
            setTempProfileBlocks([])
            setRealTempProfileBlocks([])
        }
    }, [selectedTags])

    const getData = async () => {
        const profileJson = rawdata;
        const dataArr = [];

        for (let blockName in profileJson) {
            const blockKeys = Object.keys(profileJson[blockName].Data);
            const tempData = [];
            for (let blockKey of blockKeys) {
                const data = profileJson[blockName].Data[blockKey];
                tempData.push({
                    ...data.limits,
                    SensorLocation: data.SensorLocation,
                    TagName: data.TagName.substring(data.TagName.indexOf(":") + 1),
                    datavalue: data.data_value,
                    xUnit: profileJson[blockName]["Xunit"],
                    yUnit: profileJson[blockName]["Yunit"],
                });
            }
            dataArr.push(tempData);
        };

        // incoming data was not sorted, so we need to
        // sort the incoming data based on its SensorLocation
        for (let a of dataArr) {
            a.sort((p, n) => p.SensorLocation - n.SensorLocation);
        }
        prepareTempProfileData(dataArr);
        // setIsError({ show: false, msg: {} });
    }

    const prepareTempProfileData = dataArr => {
        const dd = dataArr.map(data => {
            const ta2Min = data.reduce((min, d) => (d.tall < min ? d.tall : min), data[0].tall);
            const ta2Max = data.reduce((max, d) => (d.tahh > max ? d.tahh : max), data[0].tahh);
            return data.map((d, i) => ({
                SensorLocation: d.SensorLocation,
                TagName: d.TagName,
                datavalue: [d.datavalue[0], d.datavalue[0]],
                normal_inner: [d.tnl, d.tnh],
                normal_outer: [d.tal, d.tah],
                ta: [d.tall, d.tahh],
                ta2: [ta2Min, ta2Max],
                xUnit: d.xUnit,
                yUnit: d.yUnit,
            }));
        });
        setTempProfileBlocks(dd);
    };

    useEffect(() => {
        if (tempProfileBlocks.length > 0) {
            let eTempProfileBlocks = tempProfileBlocks.map(tempProfileBlock => {
                return tempProfileBlock.map(d => {
                    return {
                        ...d,
                        TagName: `${d.TagName}`,
                    };
                });
            });
            setRealTempProfileBlocks(eTempProfileBlocks);
        };
    }, [tempProfileBlocks]);

    useEffect(() => {
        setRevision(revision + 1)
    }, [config])

    useEffect(() => {
        console.debug(`Refreshing page ${id}`)
        if (true) {
            setRevision(revision + 1)
            showNotification('Refresh success!', 'success')
        }
    }, [widgetRefreshData])

    /**
   * Generate x ticks for chart component
   */

    const xTicks = data => {
        if (!data || data.length < 1 || !("SensorLocation" in data[0])) return;
        const min = data[0].SensorLocation;
        const max = data[data.length - 1].SensorLocation;

        const range = max - min;
        const step = range / 10;
        let ticks = [];

        for (var i = 0; i <= 10; i++) {
            const tick = i * step + min;
            ticks.push(tick);
        }

        ticks = ticks.map((tick) => {
            if (step >= 1) {
                return tick.toFixed(0);
            } else if (step >= 0.1) {
                return tick.toFixed(1);
            } else if (step >= 0.01) {
                return tick.toFixed(2);
            } else if (step >= 0.001) {
                return tick.toFixed(3);
            } else if (step >= 0.0001) {
                return tick.toFixed(4);
            } else if (step >= 0.00001) {
                return tick.toFixed(5);
            } else if (step >= 0.000001) {
                return tick.toFixed(6);
            } else {
                return tick;
            }
        });
        ticks = ticks.filter((tick) => tick <= max);
        return ticks;
    };

    /**
   * Generate y ticks for chart component
   */

    const yTicks = (data) => {
        if (!data || data.length < 1 || !("ta2" in data[0])) return;
        const [min, max] = data[0].ta2;
        const range = max - min;
        const step = range / 10;
        let ticks = [];

        for (var i = 0; i <= 10; i++) {
            const tick = i * step + min;
            ticks.push(tick);
        }

        ticks = ticks.map((tick) => {
            if (step >= 1) {
                return tick.toFixed(0);
            } else if (step >= 0.1) {
                return tick.toFixed(1);
            } else if (step >= 0.01) {
                return tick.toFixed(2);
            } else if (step >= 0.001) {
                return tick.toFixed(3);
            } else if (step >= 0.0001) {
                return tick.toFixed(4);
            } else if (step >= 0.00001) {
                return tick.toFixed(5);
            } else if (step >= 0.000001) {
                return tick.toFixed(6);
            } else {
                return tick;
            }
        });
        ticks = ticks.filter((tick) => tick <= max);
        return ticks;
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active) {
            const { datavalue, normal_outer, ta, TagName } = payload[0].payload;
            return (
                <Card>
                    <CardContent>
                        <p>TagName: {TagName}</p>
                        <p>Length: {label ? label : ""}</p>
                        <p>Value: {datavalue && datavalue[0] ? datavalue[0] : ""}</p>
                        <p>TAHH: {ta && ta[1] ? ta[1] : ""}</p>
                        <p>TAH: {normal_outer && normal_outer[1] ? normal_outer[1] : ""}</p>
                        <p>TAL: {normal_outer && normal_outer[0] ? normal_outer[0] : ""}</p>
                        <p>TALL: {ta && ta[0] ? ta[0] : ""}</p>
                    </CardContent>
                </Card>
            );
        }

        return null;
    };

    return <div style={{ backgroundColor: (isTransparent) ? 'transparent' : backgroundColor }}>
        {realTempProfileBlocks.map((block, i) => (
            <React.Fragment key={`BLOCK-${i}`}>
                <ResponsiveContainer width={"100%"} height={height}>
                    <AreaChart data={block} margin={{ top: 5, right: 30, left: 20, bottom: 13 }} >
                        <defs>
                            <linearGradient id="colorGreen" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#00ff00" stopOpacity={1} />
                                <stop offset="95%" stopColor="#00ff00" stopOpacity={1} />
                            </linearGradient>
                            <linearGradient id="colorDarkGreen" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#037d50" stopOpacity={1} />
                                <stop offset="95%" stopColor="#037d50" stopOpacity={1} />
                            </linearGradient>
                            <linearGradient id="colorYellow" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#ffff00" stopOpacity={1} />
                                <stop offset="95%" stopColor="#ffff00" stopOpacity={1} />
                            </linearGradient>
                            <linearGradient id="colorRed" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#ff0000" stopOpacity={1} />
                                <stop offset="95%" stopColor="#ff0000" stopOpacity={1} />
                            </linearGradient>
                        </defs>
                        <XAxis
                            dataKey="SensorLocation"
                            type="number"
                            ticks={xTicks(block)}
                            domain={["dataMin", "dataMax"]}
                            label={{
                                value: `Length (${block[0].xUnit})`,
                                position: "insideBottom",
                                offset: -10
                            }}
                        />
                        <YAxis
                            domain={["dataMin", "dataMax"]}
                            ticks={yTicks(block)}
                            label={{
                                value: `Temperature (${block[0].yUnit})`,
                                angle: -90,
                                position: "left",
                                offset: -5
                            }}
                        />
                        <Legend verticalAlign="top" />
                        <Area name="TAHH/TALL" dataKey="ta2" stroke="url(#colorRed)" fill="url(#colorRed)" isAnimationActive={false} />
                        <Area name="TAH/TAL" dataKey="ta" stroke="url(#colorYellow)" fill="url(#colorYellow)" isAnimationActive={false} />
                        <Area name="Normal Operating Range" dataKey="normal_outer" stroke="url(#colorDarkGreen)" fill="url(#colorDarkGreen)" isAnimationActive={false} />
                        <Area name="Design Range" dataKey="normal_inner" stroke="url(#colorGreen)" fill="url(#colorGreen)" isAnimationActive={false} />
                        <Area name="Current State" dataKey="datavalue" dot={false} stroke="#000" fill="#000" isAnimationActive={false} strokeWidth="3" />
                        <CartesianGrid stroke="#ccc" />
                        <Tooltip content={<CustomTooltip />} />
                    </AreaChart>
                </ResponsiveContainer>
            </React.Fragment>
        ))}
    </div>
};

export default AreaGraph;


