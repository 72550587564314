
    
import moment from 'moment-timezone';
import {
    UPDATE_WORKSPACES,
    UPDATE_REPORTS,
    UPDATE_PAGES,
    UPDATE_NAVIGATION,
    SHOW_DATETIME_PICKER,
    UPDATE_DATETIME
} from './constants';

const initialState = {
    isDefault: true,
    navigation: [],
    customNavigation: [],
    isQuerying: true,
    showDateTimePicker: false,
    startDateTime: moment().subtract(30, 'days'),
    endDateTime: moment()
};

const globalReducer = (state = initialState, action) => {
    switch (action.type) {

        case UPDATE_WORKSPACES:
            return {
                ...state,
                navigation: action.payload
            };

        case UPDATE_NAVIGATION:
            return {
                ...state,
                customNavigation: action.payload
            };

        case UPDATE_REPORTS:
            return {
                ...state,
                navigation: [
                    ...state.navigation.slice(0, action.wsItem), // everything before current post
                    {
                        ...state.navigation[action.wsItem],
                        reports: action.payload
                    },
                    ...state.navigation.slice(action.wsItem + 1), // everything after current post
                ]
            };


        case SHOW_DATETIME_PICKER:
            return {
                ...state,
                showDateTimePicker: action.payload
            }

        case UPDATE_DATETIME:
            return {
                ...state,
                startDateTime: action.start,
                endDateTime: action.end
            }

        default:
            return {
                ...state
            };

    }

}

export default globalReducer;