export const IsUnitColumnHasValidData = (data) => {
    const dataToValidate = [...data];
    dataToValidate.shift()

    for(const item of dataToValidate)
    {
        if(!unitValues.includes(item[2]))
            return false
    }
    return true;
}

export const ValidateTagObject = (tag) => {

    const errors = {}

    if(tag.name == null || tag.name == ''){
        errors.name = "please enter valid name";
    }
    if(tag.description == null || tag.description == ''){
        errors.description = "please enter valid description";
    }
    if(tag.unit == null || tag.unit == '' || !unitValues.includes(tag.unit)){
        errors.unit = "please enter valid unit";
    }
    if(tag.site == null || tag.site == ''){
        errors.site = "please enter valid site";
    }
    if(tag.region == null || tag.region == ''){
        errors.region = "please enter valid region";
    }
    if(tag.plant == null || tag.plant == ''){
        errors.plant = "please enter valid plant";
    }
    if(tag.maxLimit == null || isNaN(tag.maxLimit) || tag.maxLimit == ''){
        errors.maxLimit = "please enter valid max limit";
    }
    if(tag.minLimit == null || isNaN(tag.minLimit) || tag.minLimit == ''){
        errors.minLimit = "please enter valid min limit";
    }

    return errors;
}



const unitValues = ["%",
"M", 
"FT",
"M2", 
"FT2", 
"M3", 
"FT3", 
"KG", 
"LB", 
"S", 
"MIN", 
"HR", 
"HZ", 
"RPM", 
"K", 
"R", 
"C", 
"CEL", 
"F", 
"MBAR",
"MBARA", 
"MBARG", 
"BAR", 
"BARA", 
"BARG", 
"PSI", 
"PSIA", 
"PSIG", 
"ATM", 
"ATMA", 
"ATMG", 
"MOL", 
"KMOL", 
"MMOL", 
"NM3", 
"KNM3",
"MNM3", 
"MOL/S", 
"NM3/HR", 
"SM3/HR", 
"SCM/HR", 
"SFT3/HR", 
"SCF60/HR", 
"SCF70/HR",     
"DNM3/HR", 
"DSM3/HR", 
"DSCM/HR",
"DSFT3/HR", 
"DSCF60/HR", 
"DSCF70/HR",   
"CNM3/HR", 
"CSM3/HR", 
"CSCM/HR", 
"CSFT3/HR", 
"CSCF60/HR", 
"CSCF70/HR",     
"KMOL/HR", 
"KNM3/HR", 
"KSM3/HR", 
"KSCM/HR", 
"KSFT3/HR", 
"KSCF60/HR", 
"KSCF70/HR",      
"M3/HR H2O", 
"M3/S", 
"M3/MIN", 
"M3/HR",     
"FT3/S", 
"FT3/MIN", 
"FT3/HR", 
"KFT3/HR", 
"KCF/HR", 
"W", 
"KW", 
"MW", 
"HP", 
"GCAL/HR", 
"MBTU/HR", 
"KW/K", 
"KW/C", 
"KW/CEL", 
"KW/F", 
"KW/R", 
"MW/K", 
"MW/C", 
"MW/CEL", 
"MW/F", 
"MW/R", 
"GCAL/HR.K", 
"GCAL/HR.C", 
"GCAL/HR.CEL", 
"MBTU/HR.F", 
"MBTU/HR.R", 
"MW.M/K", 
"GCAL.M/HR.C", 
"GCAL.M/HR.CEL", 
"MBTU.FT/HR.F", 
"KW/BAR", 
"KW/MBAR", 
"KW/PSI", 
"KW/PSIA", 
"KW/PSIG", 
"MW/BAR", 
"MW/MBAR", 
"MW/PSI", 
"MW/PSIA", 
"MW/PSIG", 
"KW.S/MOL", 
"KW.HR/NM3", 
"KW.HR/KSCF60", 
"KW.HR/KSCF70", 
"MW.S/MOL", 
"MW.HR/NM3", 
"MW.HR/KSCF60", 
"MW.HR/KSCF70", 
"1/K", 
"1/R", 
"1/(NM3/HR)", 
"1/(SM3/HR)", 
"1/(CSM3/HR)", 
"1/(KNM3/HR)", 
"1/(KSM3/HR)", 
"1/(KSCF60/HR)", 
"1/(KSCF70/HR)", 
"1/(NM3/HR)2", 
"1/(SM3/HR)2", 
"1/(CSM3/HR)2", 
"1/(KNM3/HR)2", 
"1/(KSM3/HR)2", 
"1/(KSCF60/HR)2", 
"1/(KSCF70/HR)2", 
"KW/(NM3/HR)", 
"KW/(SM3/HR)", 
"KW/(CSM3/HR)", 
"KW/(KNM3/HR)", 
"KW/(KSM3/HR)", 
"KW/(KSCF60/HR)", 
"KW/(KSCF70/HR)", 
"MW/(NM3/HR)", 
"MW/(SM3/HR)", 
"MW/(CSM3/HR)", 
"MW/(KNM3/HR)", 
"MW/(KSM3/HR)", 
"MW/(KSCF60/HR)", 
"MW/(KSCF70/HR)", 
"KW/(NM3/HR)2", 
"KW/(SM3/HR)2", 
"KW/(CSM3/HR)2", 
"KW/(KNM3/HR)2", 
"KW/(KSM3/HR)2", 
"KW/(KSCF60/HR)2", 
"KW/(KSCF70/HR)2", 
"MW/(NM3/HR)2", 
"MW/(SM3/HR)2", 
"MW/(CSM3/HR)2", 
"MW/(KNM3/HR)2", 
"MW/(KSM3/HR)2", 
"MW/(KSCF60/HR)2", 
"MW/(KSCF70/HR)2", 
"(NM3/HR)/K", 
"(SM3/HR)/K", 
"(CSM3/HR)/K", 
"(KNM3/HR)/K", 
"(KSM3/HR)/K", 
"(KSCF60/HR)/K", 
"(KSCF70/HR)/K", 
"(NM3/HR)/R", 
"(SM3/HR)/R", 
"(CSM3/HR)/R", 
"(KNM3/HR)/R", 
"(KSM3/HR)/R", 
"(KSCF60/HR)/R", 
"(KSCF70/HR)/R", 
]