import { useEffect, useState } from "react";
import axios from 'axios'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid'
import { ApiEndpoint } from "config";

export default ({
    id,
    selectedTags, setSelectedTags,
    open, toggleOpen,
    ...props }) => {


    const [availableTags, setAvailableTags] = useState([])

    useEffect(() => {
        GetTags();

        
    }, [])

    const handleClose = () => {
        toggleOpen(!open);
    };

    const onSelectionModelChange = (selectedId) => {

        var selectedData = availableTags.filter(obj => selectedId.includes(obj.id))
        setSelectedTags(selectedData)
    }

    const columns = [
        {
            field: 'id',
            headerName: 'Tag ID',
        },
        {
            field: 'name',
            headerName: 'Tag Name',
        },
        {
            field: 'description',
            headerName: 'Description',
        },
        {
            field: 'site',
            headerName: 'Site',
        },
        {
            field: 'region',
            headerName: 'Region',
        },
        {
            field: 'plant',
            headerName: 'Plant'
        },
    ];


    const GetTags = () => {
        if (props?.authentication?.token?.accessToken) {

            axios.get(ApiEndpoint.Tag.GetAllTags, {
                headers: {
                    'authorization': `bearer ${props?.authentication?.token?.accessToken}`
                }
            }).then(resp => {
                setAvailableTags(resp.data)
            })
        }
    }
    
    return (
        <Dialog fullWidth
            open={open}
            onClose={handleClose} >
            <DialogTitle id="alert-dialog-title">
                {`Select the tags to visualize`}
            </DialogTitle>

            <DialogContent>

                <DataGrid
                    autoPageSize
                    autoHeight
                    size='small'
                    rows={availableTags}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    checkboxSelection
                    density='compact'
                    onSelectionModelChange={onSelectionModelChange}
                    selectionModel={selectedTags.map(item => item.id)}
                    columnVisibilityModel={{
                        // Hide columns id (Tag Id), the other columns will remain visible
                        id: false,
                    }}
                />


            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

