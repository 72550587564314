import { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import Plotly from 'plotly.js-dist-min';
import createPlotlyComponent from 'react-plotly.js/factory';

import { kustoConfig, ApiEndpoint } from 'config';

const Plot = createPlotlyComponent(Plotly);

const SurfaceChart = ({ id, widgetRefreshData, config, width, height, showNotification, global, authentication, ...props }) => {

    const [revision, setRevision] = useState(0)

    const [data, setData] = useState([])
    const [layout, setLayout] = useState(null)

    const [yData, setYData] = useState([])
    const [z1Data, setZ1Data] = useState([])
    const [scaleText, setScaleText] = useState([])

    const startDateTime = global?.startDateTime.utc().format()
    const endDateTime = global?.endDateTime.utc().format()

    useEffect(() => {

        getData()

    }, [])

    useEffect(() => {

        setData([
            {
                text: scaleText,
                z: z1Data,
                type: 'surface',
                hoverinfo: 'text',
                colorscale: config?.colorScale,
                cmin: config?.colorScaleRange[0],
                cmax: config?.colorScaleRange[1],
                opacity: config?.chartOpacity,
                contours: config?.contoursEnabled && {
                    z: {
                        show: config?.showContoursAlways,   // Alway show all the  contours on top or bottom
                        usecolormap: config?.showColorMapOnContours,  // Show Color on contours
                        highlightcolor: config?.contourRingColor,  // Showing the ring color
                        project: { z: config?.projectContours } // Project the contours on top or bottom
                    }
                }
            },])

        setRevision(o => o + 1)
    }, [config, scaleText, z1Data])

    // handle component on mounted
    useEffect(() => {
        getData()
        // config?.selectedTags?.map(item => {
        //     var length = 10
        //     var Amplitude = getRandom(length, 3)
        //     z1.push(Amplitude)
        // })

        // setZ1Data(z1)

        // var timestamps = []
        // timestamps = getTimeStamp();


        //         var waterfallScaleText = z1.map((xi, i) => xi.map((yj, j) => {

        //             // xi is the array for the value on that timestamp 
        //             // i is the index for the set of data 

        //             // yj is the value/ Amplitude
        //             // j is the length/point for the value  

        //             return `
        // id: ${i} <br>
        // timestamp: ${moment(timestamps[i]).format(('DD.MMM.YYYY HH:mm:ss'))} <br>
        // frequency: ${j.toFixed(2)} Hz <br>
        // amplitude: ${yj.toFixed(4)} mm/s<br>
        //             `
        //         }))

        // Data samples 
        // z1 = [
        //     [8.83,8.89,8.81,8.87,8.9,8.87],
        //     [8.89,8.94,8.85,8.94,8.96,8.92],
        //     [8.84,8.9,8.82,8.92,8.93,8.91],
        //     [8.79,8.85,8.79,8.9,8.94,8.92],
        //     [8.79,8.88,8.81,8.9,8.95,8.92],
        //     [8.8,8.82,8.78,8.91,8.94,8.92],
        //     [8.75,8.78,8.77,8.91,8.95,8.92],
        //     [8.8,8.8,8.77,8.91,8.95,8.94],
        //     [8.74,8.81,8.76,8.93,8.98,8.99],
        //     [8.89,8.99,8.92,9.1,9.13,9.11],
        //     [8.97,8.97,8.91,9.09,9.11,9.11],
        //     [9.04,9.08,9.05,9.25,9.28,9.27],
        //     [9,9.01,9,9.2,9.23,9.2],
        //     [8.99,8.99,8.98,9.18,9.2,9.19],
        //     [8.93,8.97,8.97,9.18,9.2,9.18]
        // ];

        // setScaleText(waterfallScaleText)
        // setZ1Data(Z1Data)

        setRevision(o => o + 1)
    }, [config?.selectedTags, global?.startDateTime, global?.endDateTime, widgetRefreshData]);

    useEffect(() => {
        setRevision(revision + 1)
        showNotification('Refresh success!', 'success')
    }, [widgetRefreshData])

    useEffect(() => {

        var currentTickValues = []
        var currentTickText = []

        // console.log(config?.yAxisNTicks)
        // console.log(yData?.length)

        for (let i = 0; i < yData?.length / config?.yAxisNTicks; i++) {
            currentTickValues.push(i * config?.yAxisNTicks);
            currentTickText.push(moment(yData[i * config?.yAxisNTicks]).format('DD.MM.YYYY HH:mm:ss'));
        }


        setLayout({
            paper_bgcolor: 'transparent',
            plot_bgcolor: 'transparent',
            autosize: true,
            uirevision: revision,
            datarevision: revision,
            width: width,
            height: height,
            margin: {
                l: 0, // LEFT
                r: 0, // RIGHT
                b: 0, // Bottom
                t: 0, // TOP
                pad: 5 // Padding in PX 
            },
            scene: {
                aspectmode: 'manual',
                aspectratio: {
                    x: 1, y: 0.5, z: 0.5,
                },
                xaxis: {
                    // Range for the length .
                    title: config?.xAxisTitle,
                    //rise range to fit time stamps
                    range: config?.xAxisRange,
                    color: config?.xAxisColor,
                    nticks: config?.xAxisNTicks,
                },
                yaxis: {
                    ...layout?.scene?.yaxis,

                    ticktext: yData && yData.length > 0 ? currentTickText : [],
                    tickvals: yData && yData.length > 0 ? currentTickValues : [],
                    title: config?.yAxisTitle,
                    color: config?.yAxisColor,
                },
                zaxis: {
                    nticks: config?.zAxisNTicks,
                    title: config?.zAxisTitle,
                    color: config?.zAxisColor,
                },
                camera: { eye: { x: -1.25, y: 1, z: 0.2 } },
            },
        })

        setRevision(revision + 1)
    }, [width, height, config, yData])

    const getData = () => {
        if (config?.selectedTags.map(x => x.name)) {

            axios
                .post(`${ApiEndpoint.Kusto.GetSurfacePlotValue}`, null, {
                    params: {
                        "tagNames": config?.selectedTags[0].name,
                        "startUTCTimestamp": startDateTime,
                        "endUTCTimestamp": endDateTime
                    },
                    headers: {
                        "Authorization": "bearer " + authentication?.token?.kustoToken
                    }
                }).then(resp => {
                    console.log('[SURFACE] > ', resp.data)

                    // This two result should have the same length
                    // console.log(resp.data.UTCTimeStamps)
                    // console.log(resp.data.Z1Data)
                    // console.log(resp.data.TagOrder)


                    var utcTimestamps = resp.data.UTCTimeStamps
                    var Z1Data = resp.data.Z1Data
                    var TagOrder = resp.data.TagOrder

                    var waterfallScaleText = Z1Data.map((xi, i) => xi.map((yj, j) => {
                        return `
id: ${i} <br>
timestamp: ${moment(utcTimestamps[i]).format(('DD.MM.YYYY HH:mm:ss'))} <br>
frequency/tag: ${TagOrder[j]} <br>
amplitude: ${yj.toFixed(6)} mm/s<br>
        `}))

                    setYData(utcTimestamps)
                    setZ1Data(resp.data.Z1Data)
                    setScaleText(waterfallScaleText)

                }).catch(err => {
                    showNotification(err.response?.data?.error ?? err.response?.data?.title, 'danger')
                })
        }
    }

    return <>
        <div style={{
            height: props.height, width: '100%',
            display: 'block',
            backgroundColor: !config?.isTransparent ? config?.backgroundColor : 'transparent'
        }}>
            <Plot
                useResizeHandler
                // onRelayouting={console.log}
                data={data}
                layout={layout} />
        </div>
    </>
};

export default SurfaceChart;