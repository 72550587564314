

import Background from 'assets/images/linde-background.jpg';

export default ({ ...props }) => {
    return (<img style={{
        width: '100%',
        height: '82vh',
        margin: 'auto',
        objectFit: 'cover',
        objectPosition: 'bottom'
    }} src={Background} />)
}
