import { UPDATE_WORKSPACES, UPDATE_REPORTS, UPDATE_PAGES, UPDATE_NAVIGATION, 
    SHOW_DATETIME_PICKER, UPDATE_DATETIME } from './constants';

export const updateNavigation = payload => ({
    type: UPDATE_NAVIGATION, 
    payload
})

export const updateWorkspaces = (payload) => ({
    type: UPDATE_WORKSPACES,
    payload
})


export const updateReports = (wsItem, payload) => ({
    type: UPDATE_REPORTS,
    wsItem,
    payload
})

export const updatePages = (wsItem, rsItem, payload) => ({
    type: UPDATE_PAGES,
    wsItem,
    rsItem,
    payload
})

export const toggleDateTimePicker = payload => ({
    type: SHOW_DATETIME_PICKER,
    payload
})

export const updateDateTime = (start, end ) => ({
    type: UPDATE_DATETIME, 
    start, 
    end
})