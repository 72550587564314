import { useEffect, useState, useRef, createRef } from 'react';
import { service, factories, models } from "powerbi-client";
import 'powerbi-report-authoring'
import axios from 'axios'

const powerbi = new service.Service(factories.hpmFactory, factories.wpmpFactory, factories.routerFactory);

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}


const PowerBi = ({ id, workspaceId, reportId, pageName, widgetRefreshData, showNotification, authentication, ...props }) => {


    const [reportRef, setReportRef] = useState(createRef())
    const [reportContainer, setReportContainer] = useState(null)


    const [embedUrl, setEmbedUrl] = useState('');
    const [embedReportToken, setEmbedReportToken] = useState(null);
    const [report, setReport] = useState(null);



    const prevData = usePrevious({ workspaceId, reportId, pageName });

    // handle component on mounted
    useEffect(() => {

        if (reportRef !== null) {
            setReportContainer(reportRef['current'])
        }
        if (authentication?.token?.accessToken) {
            getReports();
        }
    }, []);

    useEffect(() => {
        // console.log(props.global.startDateTime)
        // console.log(props.global.endDateTime)
    }, [props])


    useEffect(() => {
        if (report && pageName) {
            report.setPage(pageName)
                .then(function (result) {
                    console.debug(result);
                })
                .catch(function (errors) {
                    console.error(errors);
                });
        }

    }, [pageName, report])


    useEffect(() => {

        console.debug('Report ID change', reportId)

        setEmbedReportToken(null)
        setEmbedUrl('')
        setReport(null)


        if (prevData?.reportId !== reportId || !report) {
            if (authentication?.token?.accessToken) {
                getReports();
            }
        }

    }, [reportId, authentication?.token?.accessToken])



    useEffect(() => {

        console.debug(`Token ${embedReportToken}`)
        console.debug(`Url ${embedUrl}`)

        if (embedReportToken && embedUrl) {
            // if (reportContainer && !report) {
            setReport(powerbi?.embed(reportContainer, {
                type: "report",
                pageView: 'fitToWidth',
                embedUrl: embedUrl,
                tokenType: models.TokenType.Embed,
                accessToken: embedReportToken,
                settings: {
                    filterPaneEnabled: true,
                    navContentPaneEnabled: true
                },
            }))
            // }
        }
    }, [embedReportToken, embedUrl, reportContainer])


    useEffect(() => {


        if (report) {
            console.debug(`Refreshing report ${id}`)
            console.debug(report)
            report.refresh().then(() => {
                showNotification('Refresh success!', 'success')
            }).catch(err => {
                console.log(err)
                showNotification(err?.detailedMessage, 'warning')
            })
        } else {
            getReports()
        }

    }, [widgetRefreshData])

    const getReports = () => {
        if (workspaceId && reportId) {
            axios
                .get('api/powerbi/embedconfig/' + workspaceId + '/' + reportId, {
                    headers: {
                        "Authorization": "bearer " + authentication?.token?.accessToken
                    }
                })
                .then(resp => {
                    setEmbedUrl(resp.data.embedUrl)
                    setEmbedReportToken(resp.data.token)
                })
        }
    }





    const loadReport = () => {
        if (report) {

            // Clear any other loaded handler events
            report.off("loaded");

            // Triggers when a content schema is successfully loaded
            report.on("loaded", function () {

                console.log("Report load successful");
            });

            // Report.off removes a given event handler if it exists.
            report.off("pageChanged");

            report.on("pageChanged", function (event) {
                console.log(event.detail)
                var page = event.detail.newPage;
                console.log(page.name + " - " + page.displayName);
            });

            // Clear any other rendered handler events
            report.off("rendered");

            // Triggers when a content is successfully embedded in UI
            report.on("rendered", function () {
                console.log("Report render successful");
            });

            // Clear any other error handler event
            report.off("error");

            // Below patch of code is for handling errors that occur during embedding
            report.on("error", function (event) {
                const errorMsg = event.detail;

                // Use errorMsg variable to log error in any destination of choice
                console.error(errorMsg);
            });
        }
    }



    return <>
        <div
            style={{ height: props.height, width: '100%', display: 'block' }}
            ref={reportRef} >
            {workspaceId ? (reportId ? 'Loading the report...' : `Please configure the settings`) : `Please configure the settings`}
        </div>
    </>
};

export default PowerBi;


