
import { useEffect, useState } from 'react';
import { HexColorPicker } from "react-colorful";

import {
    ListItem,
    TextField,
    Checkbox,
    FormControlLabel
} from '@mui/material'

import {
    Title as TitleIcon,
    FormatColorText as FormatColorTextIcon,
    FormatPaint as FormatPaintIcon,
    FormatSize as FormatSizeIcon
} from '@mui/icons-material'

const LabelConfiguration = ({ id, 
    isConfigDrawerOpen, toggleConfigDrawer, widgetAdvanceSettings,
    setWidgetAdvanceSettings,
    showNotification,
    ...props }) => {

        const [title, setTitle] = useState(widgetAdvanceSettings?.label?.title)
        const [fontSize, setFontSize] = useState(widgetAdvanceSettings?.label?.fontSize ? widgetAdvanceSettings?.label?.fontSize : 30)

        const [isTransparent, setIsTransparent] = useState(true)
    
        const [layoutColor, setLayoutColor] = useState('#005591')
        const [isLayoutColorPickerOpen, toggleLayoutColorPicker] = useState(false);
        
        const [backgroundColor, setBackgroundColor] = useState('#FFFFFF')
        const [isBackgroundColorPickerOpen, toggleBackgroundColorPicker] = useState(false);

        useEffect(() => {
            if (widgetAdvanceSettings?.label?.title) {
                setFontSize(widgetAdvanceSettings?.label?.fontSize)
                setLayoutColor(widgetAdvanceSettings?.label?.layoutColor)
                setBackgroundColor(widgetAdvanceSettings?.label?.backgroundColor)

                if (widgetAdvanceSettings?.label?.isTransparent !== undefined) {
                    setIsTransparent(widgetAdvanceSettings?.label?.isTransparent)
                }
            }
        }, [])

        useEffect(() => {
            setWidgetAdvanceSettings({
                ...widgetAdvanceSettings,
                label: {
                    title: title,
                    layoutColor: layoutColor,
                    backgroundColor: backgroundColor,
                    fontSize: fontSize,
                    isTransparent: isTransparent
                },
            })
        }, [title, layoutColor, backgroundColor, fontSize, isTransparent])

        const onChangeTransparent = () => {
            setIsTransparent(!isTransparent)
        }
    
        return <div>
            <ListItem id={'config-list-item'} className={'config-list-item'}>
                <TitleIcon />
                <TextField className='config-menu-item-text'
                    fullWidth
                    label={'Title'}
                    variant='standard'
                    defaultValue={title}
                    onChange={event => setTitle(event.target.value)} />
            </ListItem>

            <ListItem id={'config-list-item'} className={'config-list-item'}>
                <FormatSizeIcon />
                <TextField className='config-menu-item-text'
                    fullWidth
                    label={'Font Size'}
                    variant='standard'
                    defaultValue={fontSize}
                    onChange={event => setFontSize(event.target.value)} />
            </ListItem>

            <ListItem id={'config-list-item'} className={'config-list-item'}>
                <FormatColorTextIcon titleAccess='Layout Color' />
                <TextField id="standard-basic" className='config-menu-item-text' variant="standard"
                    size="small"
                    value={layoutColor}
                    onClick={() => toggleLayoutColorPicker(true)}
                    onChange={event => setLayoutColor(event.target.value)}
                />

                <div style={{
                    width: '25px', height: '25px',
                    backgroundColor: layoutColor
                }}
                    onClick={() => toggleLayoutColorPicker(!isLayoutColorPickerOpen)} />
                <br />
            </ListItem>

            <ListItem id={'config-list-item'} className={'config-list-item'}>
                {isLayoutColorPickerOpen && <HexColorPicker
                    style={{ width: 'inherit !important' }}
                    height={300}
                    color={layoutColor}
                    onChange={color => {
                        setLayoutColor(color)
                    }}
                />
                }
            </ListItem>

        
            <FormControlLabel sx={{ color: '#005591', fontFamily: 'LindeDax, Arial, sans-serif', marginLeft: 1 }}
                control={
                    <Checkbox checked={isTransparent} onChange={onChangeTransparent} />
                }
                label="Transparent Background"
            />

            {
                (isTransparent) ? null : 
                    <>
                        <ListItem id={'config-list-item'} className={'config-list-item'}>
                            <FormatPaintIcon titleAccess='Background Color' />
                            <TextField id="standard-basic" className='config-menu-item-text' variant="standard"
                                size="small"
                                value={backgroundColor}
                                onClick={() => toggleBackgroundColorPicker(true)}
                                onChange={event => setBackgroundColor(event.target.value)}
                            />

                            <div style={{
                                width: '25px', height: '25px',
                                backgroundColor: backgroundColor
                            }}
                                onClick={() => toggleBackgroundColorPicker(!isBackgroundColorPickerOpen)} />
                            <br />
                        </ListItem>

                        <ListItem id={'config-list-item'} className={'config-list-item'}>
                            {isBackgroundColorPickerOpen && <HexColorPicker
                                style={{ width: 'inherit !important' }}
                                height={300}
                                color={backgroundColor}
                                onChange={color => {
                                    setBackgroundColor(color)
                                }}
                            />
                            }
                        </ListItem>
                    </>
            }
        </div>
}


export default LabelConfiguration